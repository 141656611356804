import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

import detailChartsQuery from './detailCharts.graphql';
import { convertScore } from '../../../../common/helpers.js';
import { getSessionDataVariables } from '../../../../../../../reducers/reportFilters';
import DetailChart from './DetailChart';

const Component = props => {
    const [metrics, setMetrics] = useState([]);
    const [loading, setLoading] = useState(true);

    const { show } = props.settings;
    const { filters, convertDataStructure, benchmarkCounter } = props;

    const dispatch = useDispatch();
    const { productHandle } = useParams();
    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);
    const { productIds } = useSelector(state => state.app.reportFilters);

    const prodHandle = productHandle ? productHandle : productIds.find(prod => prod.value === filters.product).handle;

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables(prodHandle));

            try {
                await client
                    .query({
                        query: detailChartsQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +filters.group,
                            productHandle: prodHandle,
                        },
                    })
                    .then(response => {
                        const group = response.data.roles.class;

                        setMetrics([
                            {
                                id: 'componentCoverage',
                                totalAvg: convertScore(group.compCoverage.totalAvg),
                                settings: { ...props.settings.componentCoverage },
                                convertedData: [
                                    {
                                        id: 'componentCoverage',
                                        color: 'hsl(25, 70%, 50%)',
                                        // we want our data array to be sorted by session timepoint
                                        data: _sortBy([...convertDataStructure(group.compCoverage.sessions)], ['x']),
                                    },
                                ],
                                reachedBenchmark: benchmarkCounter(convertDataStructure(group.compCoverage.sessions)),
                            },
                            {
                                id: 'progAdherence',
                                totalAvg: convertScore(group.progAdherence.totalAvg),
                                settings: { ...props.settings.progAdherence },
                                convertedData: [
                                    {
                                        id: 'progAdherence',
                                        color: 'hsl(25, 70%, 50%)',
                                        data: _sortBy([...convertDataStructure(group.progAdherence.sessions)], ['x']),
                                    },
                                ],
                                reachedBenchmark: benchmarkCounter(convertDataStructure(group.progAdherence.sessions)),
                            },
                            {
                                id: 'contentSupplemented',
                                totalAvg: convertScore(group.contentSupplemented.totalAvg),
                                settings: { ...props.settings.contentSupplemented },
                                convertedData: [
                                    {
                                        id: 'contentSupplemented',
                                        color: 'hsl(25, 70%, 50%)',
                                        data: _sortBy(
                                            [...convertDataStructure(group.contentSupplemented.sessions)],
                                            ['x']
                                        ),
                                    },
                                ],
                                reachedBenchmark: benchmarkCounter(
                                    convertDataStructure(group.contentSupplemented.sessions)
                                ),
                            },
                            {
                                id: 'partResponsiveness',
                                totalAvg: convertScore(group.partResponsiveness.totalAvg),
                                settings: { ...props.settings.partResponsiveness },
                                convertedData: [
                                    {
                                        id: 'partResponsiveness',
                                        color: 'hsl(25, 70%, 50%)',
                                        data: _sortBy(
                                            [...convertDataStructure(group.partResponsiveness.sessions)],
                                            ['x']
                                        ),
                                    },
                                ],
                                reachedBenchmark: benchmarkCounter(
                                    convertDataStructure(group.partResponsiveness.sessions)
                                ),
                            },
                        ]);

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters, props.settings]);

    return loading ? (
        <Loader type="inline" />
    ) : (
        <>
            {show && (
                <div>
                    {metrics.map(metric => {
                        return (
                            <div key={metric.id}>
                                <DetailChart
                                    handle={metric.id}
                                    totalAvg={metric.totalAvg}
                                    settings={metric.settings}
                                    sessionDataVariables={sessionDataVariables}
                                    data={metric.convertedData}
                                    reachedBenchmark={metric.reachedBenchmark}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    convertDataStructure: PropTypes.func,
    benchmarkCounter: PropTypes.func,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: arrOfSessions => {
        if (arrOfSessions !== null) {
            return arrOfSessions.map(session => {
                return {
                    x: +session.value,
                    y:
                        session.sessionScore[0].value <= 1
                            ? convertScore(session.sessionScore[0].value)
                            : session.sessionScore[0].value,
                };
            });
        }

        return {};
    },
    benchmarkCounter: scores => {
        const aboveBenchmark = [];

        const justScores =
            scores && scores.length > 0
                ? scores.map(score => {
                      return _get(score, 'y');
                  })
                : [];

        justScores.map(el => +el >= 80 && aboveBenchmark.push(el));

        return aboveBenchmark.length;
    },
};

export default Component;
