import React from 'react';
import PropTypes from 'prop-types';
import { CccisdSelect } from 'cccisd-formik';

import { connect, useDispatch } from 'react-redux';
import { updateCurrentProduct } from 'js/reducers/reportFilters.js';

const ProductSelect = props => {
    const dispatch = useDispatch();

    return (
        <CccisdSelect
            field={props.field}
            form={props.form}
            options={props.productIds}
            label="Products"
            onChange={e => {
                props.form.handleChange(e);
                dispatch(updateCurrentProduct(+e.target.value));
            }}
        />
    );
};

ProductSelect.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    // from redux
    productIds: PropTypes.array,
};
const mapStateToProps = state => ({
    productIds: state.app.reportFilters.productIds,
});

export default connect(mapStateToProps, { updateCurrentProduct })(ProductSelect);
