import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import IconReplay from 'cccisd-icons/rotate-ccw3';
import Loader from 'cccisd-loader';
import { Page, Report, BrowserOnly, PrintOnly } from 'cccisd-laravel-report';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import { useParams } from 'cccisd-react-router';
import { Html } from 'cccisd-wysiwyg';

import Transcript from './Transcript';
import style from './style.css';
import certificateQuery from './certificateQuery.graphql';

const Boilerplate = window.cccisd.boilerplate;
const Cccisd = window.cccisd;
const Fortress = window.cccisd.fortress;

export const DATE_FORMAT = 'MMMM D, YYYY';
const DATE_FORMAT_UTC = 'YYYY-MM-DD HH:mm:ss';

const Certificate = ({
    actingPawnHash,
    actingPawnId,
    basePath,
    deploymentId,
    deploymentSettings,
    hide,
    language,
    latestFinalTestData,
    mediatorProps,
    parentDeploymentId,
    relatedDeploymentId,
    settings,
}) => {
    let defaultCertificateId = '–';
    const certData = _get(settings, `staticCertificateId.${Fortress.user.acting.data_type}`);
    if (certData) {
        defaultCertificateId = _get(Cccisd, certData.path) || '–';
    }

    // to prevent breaking changes, if settings doesn't contain `certificates`
    // key, convert from old to new
    const certificates = settings.certificates || [
        {
            backgroundId: settings.backgroundId,
            courseName: settings.courseName,
            freeText: settings.freeText,
        },
    ];

    const [backgroundUrls, setBackgroundUrls] = useState({});
    const [certificateId, setCertificateId] = useState(defaultCertificateId);
    const [formattedDate, setFormattedDate] = useState('');
    const [completedAt, setCompletedAt] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(true);
    const params = useParams();

    const defaultName = 'Full Name Here';

    /* /////////////////////////////////////////////////////////////////////////
    // LIFECYLE-RELATED ///////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    useEffect(() => {
        async function getBackgroundUrl(bgId, returnObj) {
            const baseUrl = Boilerplate.route('api.resources.file.index');
            const response = await axios.get(`${baseUrl}?id=${bgId}`);
            if (response.status === 200 && response.data.status === 'success') {
                returnObj[bgId] = response.data.data[0].published_url;
            }
        }

        async function getCertificateDateAndUser() {
            if (!actingPawnId || !language || !deploymentId) {
                const actingUser = Fortress.user.acting.user || {};
                const altName = actingUser.full_name || actingUser.username || defaultName;
                setName(altName);
                setFormattedDate(moment().format(DATE_FORMAT));
                return;
            }
            const result = await apollo.query({
                query: certificateQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentId,
                    language,
                    actingPawnHash,
                    actingPawnId,
                },
            });

            const ap = result.data.flows.assignmentProgress;
            if (ap.completedDate) {
                setFormattedDate(moment.utc(ap.completedDate).format(DATE_FORMAT));
                setCompletedAt(moment.utc(ap.completedAt).format(DATE_FORMAT_UTC));
            } else if (latestFinalTestData && latestFinalTestData.date) {
                setFormattedDate(moment.utc(latestFinalTestData.date).format(DATE_FORMAT));
                setCompletedAt(moment.utc(latestFinalTestData.date).format(DATE_FORMAT_UTC));
            } else {
                setFormattedDate(moment().format(DATE_FORMAT));
                setCompletedAt(moment().format(DATE_FORMAT_UTC));
            }
            const user = ap.pawn.user;
            setName(user.fullName || user.username || defaultName);
            if (!certData) {
                setCertificateId(ap.assignmentProgressId + 1000000);
            }
        }

        async function loadData() {
            if (!hide) {
                const loadingPromises = [];
                const startingBackgroundIds = certificates.filter(c => c.backgroundId).map(c => c.backgroundId);
                const localBackgroundUrls = {};
                if (Object.keys(backgroundUrls).length === 0 && startingBackgroundIds.length > 0) {
                    const backgroundIdsPromised = [];
                    startingBackgroundIds.forEach(bgId => {
                        if (!backgroundIdsPromised.includes(bgId)) {
                            backgroundIdsPromised.push(bgId);
                            loadingPromises.push(getBackgroundUrl(bgId, localBackgroundUrls));
                        }
                    });
                }
                loadingPromises.push(getCertificateDateAndUser());
                await Promise.all(loadingPromises);

                setBackgroundUrls(localBackgroundUrls);
            }

            setLoading(false);
        }

        loadData();
    }, [hide]);

    /* /////////////////////////////////////////////////////////////////////////
    // GENERAL METHODS /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    async function resetCourseProgress() {
        setLoading(true);
        const resp = await axios.get(
            Boilerplate.route('api.assignmentDeployment.repeated', {
                deploymentId: parentDeploymentId,
                pawnId: actingPawnId,
                hash: actingPawnHash,
            }),
            {
                params: {
                    relatedDeploymentId,
                    isResetCourse: true,
                    isPawnHash: true,
                },
            }
        );

        const subRelatedDeploymentId = _get(resp, 'data.data.deploymentId', '');
        if (_get(resp, 'data.status', '') !== 'success' || !subRelatedDeploymentId) {
            notification({ message: 'Error resetting progress.', type: 'danger', data: resp.data });
            return;
        }

        if (_get(mediatorProps, 'course.isCurriculum', false)) {
            const options = _get(mediatorProps, 'course.curriculumOptions', []) || [];
            const deploymentIdsToReset = options.map(opt => opt.deploymentId);

            await Promise.all(
                deploymentIdsToReset.map(depId =>
                    axios.get(
                        Boilerplate.route('api.assignmentDeployment.repeated', {
                            deploymentId: depId,
                            pawnId: actingPawnId,
                            hash: actingPawnHash,
                        }),
                        {
                            params: {
                                isResetCourse: true,
                                isPawnHash: true,
                                relatedDeploymentId: subRelatedDeploymentId,
                            },
                        }
                    )
                )
            );
        }

        let refreshLocation = basePath;
        if (params.currentDeploymentId) {
            refreshLocation = refreshLocation.slice(0, refreshLocation.lastIndexOf(params.currentDeploymentId) - 1);
        }

        // do full page refresh so that all state gets reset with new deployment info
        window.location = refreshLocation;
    }

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER-RELATED /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    function renderContent({ accreditations, certificate, showAccreditation, viewType = 'browser' }) {
        const containerStyle =
            viewType === 'browser' ? style.certificateContainerBrowser : style.certificateContainerPrint;
        return (
            <div className={containerStyle}>
                <div
                    className={style.certificate}
                    style={{ backgroundImage: `url(${backgroundUrls[certificate.backgroundId]})` }}
                >
                    <div className={style.passDate}>{formattedDate}</div>
                    <div className={style.certificateContent}>
                        <div className={style.name}>{name}</div>
                        <div className={style.courseName}>{certificate.courseName}</div>
                        {certificate.freeText && <Html content={certificate.freeText} />}
                        <div className={style.certificateId}>
                            <b>{certData && certData.label ? certData.label : 'Certificate ID:'}</b> {certificateId}
                        </div>
                    </div>
                    {showAccreditation && (
                        <div className={style.accreditationContainer}>
                            {accreditations.map((aText, i) => (
                                <div key={i} className={style.accreditation}>
                                    <Html content={aText} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function renderCompletionText() {
        return settings.completionText ? (
            <div className={style.completionText}>
                <Html content={settings.completionText} />
            </div>
        ) : null;
    }

    function renderRenewCertificate() {
        // if this deployment is not the child of a parent, it's likely
        // that the parent deployment needs `isRepeatable` toggled ON in settings
        if (!settings.allowRenew || !parentDeploymentId || !completedAt) {
            return null;
        }

        // TO-DO: if repeatInterval of parent deployment is edited, all
        // child deployments should also get the updated repeatInterval. this line checks child
        const repeatInterval = _get(deploymentSettings, 'repeatInterval', false);
        if (repeatInterval && completedAt) {
            const repeatIntervalParts = repeatInterval.split(' ').filter(item => item);
            if (repeatIntervalParts.length === 2) {
                const repeatIntervalNumber = repeatIntervalParts[0];
                const repeatIntervalUnits = repeatIntervalParts[1];
                const cutoff = moment()
                    .subtract(Number.parseInt(repeatIntervalNumber, 10), repeatIntervalUnits)
                    .format(DATE_FORMAT_UTC);

                if (cutoff < completedAt) {
                    return null;
                }
            }
        }

        return (
            <div className={style.allowRenewCertificate}>
                <Modal
                    trigger={
                        <button className="btn btn-default" type="button">
                            <IconReplay spaceRight />
                            Renew Certificate
                        </button>
                    }
                    render={({ closeModal }) => {
                        return (
                            <>
                                <Html content={settings.renewConfirmation} />
                                <div className={'text-right ' + style.renewButtons}>
                                    <button type="button" className="btn btn-default" onClick={closeModal}>
                                        No
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            resetCourseProgress();
                                            closeModal();
                                        }}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </>
                        );
                    }}
                    title="Renew Certificate"
                />
                <Transcript
                    actingPawnHash={actingPawnHash}
                    actingPawnId={actingPawnId}
                    mediatorProps={mediatorProps}
                    parentDeploymentId={parentDeploymentId}
                    relatedDeploymentId={relatedDeploymentId}
                />
            </div>
        );
    }

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    if (hide) {
        return <div>You must first complete all requirements before accessing the {settings.tabName}.</div>;
    }

    if (loading) {
        return <Loader loading />;
    }

    const downloadFilename = `Certificate_${certificateId}`
        .replace(/[ -]/g, '_')
        .replace(/[^A-Za-z0-9_]/g, '')
        .replace(/_$/, '');

    return (
        <>
            {renderCompletionText()}
            {renderRenewCertificate()}
            <Report downloadFilename={downloadFilename}>
                {certificates.map((certificate, index) => {
                    const accreditations = [
                        certificate.accreditation1,
                        certificate.accreditation2,
                        certificate.accreditation3,
                    ].filter(a => a);
                    const showAccreditation = accreditations.length > 0;
                    return (
                        <Page key={index}>
                            <BrowserOnly>
                                {renderContent({ accreditations, certificate, showAccreditation, viewType: 'browser' })}
                            </BrowserOnly>
                            <PrintOnly>
                                {renderContent({ accreditations, certificate, showAccreditation, viewType: 'print' })}
                            </PrintOnly>
                        </Page>
                    );
                })}
            </Report>
        </>
    );
};

Certificate.propTypes = {
    actingPawnHash: PropTypes.string,
    actingPawnId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    deploymentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hide: PropTypes.bool,
    language: PropTypes.string,
    latestFinalTestData: PropTypes.object,
    settings: PropTypes.object,
};

export default Certificate;
