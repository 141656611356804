import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import Loader from 'cccisd-loader';
import Header from '../../components/StatusHeader';
import InfoLabel from '../../components/InfoLabel';
import LackingPermissions from '../../components/LackingPermissions';
import NoProjectsMessage from '../../components/NoProjectsMessage';
import questPermissionsByArea from '../../helpers/questPermissionsByArea.js';
import useEnsureDeploymentIds from '../../hooks/useEnsureDeploymentIds';
import { getCurrentProject, getCurrentProjectAssignments } from '../../selectors/admin.js';
import StatusTable from './StatusTable';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const Status = ({
    assignmentId,
    assignmentIdWhitelist,
    customSwitchProject,
    deploymentIdWhitelist,
    deploymentIds,
    hideDeploymentDropdown,
    hideProjectDropdown,
    hideQuestDropdown,
    hideSystemId,
    isExported,
    labelForDeployment,
    labelForLesson = 'Lesson',
    labelForProject,
    labelForProjectPlural,
    labelForQuest,
    labelForSurvey,
    pawnIdWhitelist,
    project,
    projectAssignments,
    projectIdWhitelist,
    showByWhitelist,
    surveyHandleWhitelist,
    renderCertificate,
    setColumnDefinitions,
}) => {
    const [loading, setLoading] = useState(true);
    const [hasModules, setHasModules] = useState(false);
    const [modules, setModules] = useState([]);
    const [surveyButtonLabel, setSurveyButtonLabel] = useState(labelForSurvey || 'Survey');
    const [showBy, setShowBy] = useState(showByWhitelist.length > 0 ? showByWhitelist[0] : 'assignment');

    const currentAssignment = projectAssignments.find(item => item.assignmentId === assignmentId);

    useEnsureDeploymentIds();

    useEffect(() => {
        (async () => {
            if (!currentAssignment) {
                setLoading(false);
                return;
            }
            setLoading(true);
            await getColumns();
            setLoading(false);
        })();
    }, [currentAssignment]);

    const getColumns = () => {
        // Determine if assignment has modules
        let navigation = _get(currentAssignment, 'settings.navigation', '');
        let navigationSettings = _get(currentAssignment, 'settings.navigationSettings', {});
        if (navigation.startsWith('courseWrapper')) {
            navigation = _get(currentAssignment, 'settings.navigationSettings.course.navigation', '');
            navigationSettings = _get(navigationSettings, 'course.navigationSettings', {});
        }
        const _modules = (navigation.startsWith('module') && _get(navigationSettings, 'modules', [])) || [];

        setHasModules(_modules.length > 0);
        setModules(_modules);
        setSurveyButtonLabel(_modules.length > 0 ? labelForLesson || 'Lesson' : labelForSurvey || 'Survey');
        if (_modules.length <= 0 && showBy === 'module') {
            setShowBy('assignment');
        }

        return null;
    };

    const getButtons = () => {
        if (showByWhitelist.length <= 1) {
            return;
        }

        if (hasModules) {
            return (
                <div
                    className={
                        !hideDeploymentDropdown || !hideProjectDropdown || !hideQuestDropdown
                            ? style.showByContainer
                            : style.showByContainerNoHeader
                    }
                >
                    <InfoLabel label="Show by" />
                    <div className="btn-group">
                        {showByWhitelist.includes('assignment') && (
                            <button
                                type="button"
                                onClick={() => setShowBy('assignment')}
                                className={'btn ' + (showBy === 'assignment' ? 'disabled btn-primary' : 'btn-default')}
                            >
                                Course
                            </button>
                        )}
                        {showByWhitelist.includes('module') && (
                            <button
                                type="button"
                                onClick={() => setShowBy('module')}
                                className={'btn ' + (showBy === 'module' ? 'disabled btn-primary' : 'btn-default')}
                            >
                                Module
                            </button>
                        )}
                        {showByWhitelist.includes('survey') && (
                            <button
                                type="button"
                                onClick={() => setShowBy('survey')}
                                className={'btn ' + (showBy === 'survey' ? 'disabled btn-primary' : 'btn-default')}
                            >
                                {surveyButtonLabel}
                            </button>
                        )}
                    </div>
                </div>
            );
        }
        return (
            <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                <InfoLabel label="Show by" />
                <div className="btn-group">
                    {showByWhitelist.includes('assignment') && (
                        <button
                            type="button"
                            onClick={() => setShowBy('assignment')}
                            className={'btn ' + (showBy === 'assignment' ? 'disabled btn-primary' : 'btn-default')}
                        >
                            {labelForQuest || 'Quest'}
                        </button>
                    )}
                    {showByWhitelist.includes('survey') && (
                        <button
                            type="button"
                            onClick={() => setShowBy('survey')}
                            className={'btn ' + (showBy === 'survey' ? 'disabled btn-primary' : 'btn-default')}
                        >
                            {surveyButtonLabel}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    if (loading) {
        return <Loader loading />;
    }

    if (!Fortress.hasAnyAccess(questPermissionsByArea.status.permissions, (project && project.groupId) || 0)) {
        return (
            <LackingPermissions
                customSwitchProject={customSwitchProject}
                hideProjectDropdown={hideProjectDropdown}
                isExported={isExported}
                labelForProject={labelForProject}
                labelForProjectPlural={labelForProjectPlural}
                limitProjectsByPermissions={questPermissionsByArea.status.permissions}
                projectIdWhitelist={projectIdWhitelist}
            />
        );
    }

    return (
        <React.Fragment>
            {(!hideDeploymentDropdown || !hideProjectDropdown || !hideQuestDropdown) && (
                <Header
                    assignmentIdWhitelist={assignmentIdWhitelist}
                    customSwitchProject={customSwitchProject}
                    deploymentDropdownNotMulti
                    deploymentIdWhitelist={deploymentIdWhitelist}
                    isExported={isExported}
                    labelForDeployment={labelForDeployment}
                    labelForProject={labelForProject}
                    labelForProjectPlural={labelForProjectPlural}
                    labelForQuest={labelForQuest}
                    labelForSurvey={labelForSurvey}
                    limitProjectsByPermissions={questPermissionsByArea.status.permissions}
                    projectIdWhitelist={projectIdWhitelist}
                    showDeploymentDropdown={!hideDeploymentDropdown}
                    showProjectDropdown={!hideProjectDropdown}
                    showQuestCount={false}
                    showQuestDropdown={!hideQuestDropdown}
                />
            )}
            {!currentAssignment && <NoProjectsMessage isExported={isExported} />}
            {!!currentAssignment && deploymentIds.length > 0 && getButtons()}
            {!!currentAssignment && deploymentIds.length > 0 && (
                <StatusTable
                    hideSystemId={hideSystemId}
                    modules={modules}
                    pawnIdWhitelist={pawnIdWhitelist}
                    showBy={showBy}
                    surveyHandleWhitelist={surveyHandleWhitelist}
                    renderCertificate={renderCertificate}
                    setColumnDefinitions={setColumnDefinitions}
                />
            )}
            {!!currentAssignment && !deploymentIds.length && (
                <h3 className={style.info}>Please select a {labelForDeployment || 'Data Collection Wave'}</h3>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    assignmentId: state.assignment.admin.currentAssignmentId,
    deploymentIds: state.assignment.admin.currentDeploymentIds,
    projectAssignments: getCurrentProjectAssignments(state.assignment.admin),
    project: getCurrentProject(state.assignment.admin),
});

Status.defaultProps = {
    showByWhitelist: ['assignment', 'module', 'survey'],
};

Status.propTypes = {
    assignmentId: PropTypes.number,
    assignmentIdWhitelist: PropTypes.array,
    customSwitchProject: PropTypes.func,
    deploymentIdWhitelist: PropTypes.array,
    deploymentIds: PropTypes.array,
    hideDeploymentDropdown: PropTypes.bool,
    hideProjectDropdown: PropTypes.bool,
    hideQuestDropdown: PropTypes.bool,
    hideSystemId: PropTypes.bool,
    isExported: PropTypes.bool,
    labelForDeployment: PropTypes.string,
    labelForLesson: PropTypes.string,
    labelForProject: PropTypes.string,
    labelForProjectPlural: PropTypes.string,
    labelForQuest: PropTypes.string,
    labelForSurvey: PropTypes.string,
    pawnIdWhitelist: PropTypes.arrayOf(PropTypes.number),
    project: PropTypes.object,
    projectAssignments: PropTypes.array,
    projectIdWhitelist: PropTypes.array,
    showByWhitelist: PropTypes.arrayOf(PropTypes.string), // 'survey', 'module', or 'assignment'
    surveyHandleWhitelist: PropTypes.arrayOf(PropTypes.string),
    renderCertificate: PropTypes.func,
    setColumnDefinitions: PropTypes.func,
};

export default connect(mapStateToProps)(Status);
