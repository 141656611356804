const languageCodeConversion = {
    en: 'English',
    es: 'Spanish',
};

export default ({ name }) => {
    return {
        name,
        label: 'Language',
        sort: false,
        render: ({ value }) => languageCodeConversion[value] || value,
        filter: true,
        filterSettings: {
            type: 'selectbox',
            options: [
                { label: 'English', value: 'en' },
                { label: 'Spanish', value: 'es' },
            ],
        },
    };
};
