import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';
import _sortBy from 'lodash/sortBy';

import chartsQuery from './charts.graphql';
import { getSessionDataVariables } from '../../../../../../../reducers/reportFilters';
import MainChart from './MainChart';

const Component = props => {
    const [metrics, setMetrics] = useState([]);
    const [loading, setLoading] = useState(true);

    const { show } = props.settings;
    const { filters, convertDataStructure } = props;

    const dispatch = useDispatch();
    const { productHandle } = useParams();

    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);
    const { productIds } = useSelector(state => state.app.reportFilters);

    const prodHandle = productHandle ? productHandle : productIds.find(prod => prod.value === filters.product).handle;

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables(prodHandle));

            try {
                await client
                    .query({
                        query: chartsQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +filters.participant,
                            productHandle: prodHandle,
                        },
                    })
                    .then(response => {
                        const learnerData = response.data.roles.learner.assignmentProgressSummary;

                        setMetrics({
                            id: 'mainChart',
                            settings: { ...props.settings.mainChart },
                            convertedData: [
                                {
                                    id: 'mainChart',
                                    color: 'hsl(181, 70%, 50%)',
                                    data: _sortBy(convertDataStructure(learnerData), ['x']),
                                },
                            ],
                        });

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [filters, props.settings]);

    return loading ? (
        <Loader type="inline" />
    ) : (
        <>
            {show && (
                <MainChart
                    settings={metrics.settings}
                    data={metrics.convertedData}
                    sessionDataVariables={sessionDataVariables}
                />
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    convertDataStructure: PropTypes.func,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: sessionData => {
        if (sessionData.timepoint !== null) {
            return sessionData.timepoint.map(session => {
                return {
                    x: +session.value,
                    y: session.score[0].value,
                };
            });
        }
        return {};
    },
};

export default Component;
