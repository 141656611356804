import React from 'react';
import { Formik, Form, Field, CccisdInput, CccisdFieldWrapper } from 'cccisd-formik';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';
import _isEqual from 'lodash/isEqual';

import CompletedTrainingsFields from '../../CompletedTrainingsFields/index.js';
import RequiredFieldLabel from '../RequiredFieldLabel/index.js';
import { userCheck, getInitialValues } from '../manageFormHelpers.js';
import style from '../style.css';

import groupingUnitsQuery from './groupingUnits.graphql';

const GuAdminSupervisorForm = props => {
    const { onSubmit, closeModal, operation, initialValues, role } = props;

    const onHandleSubmit = async values => {
        values.gUnit = values.groupingUnit.length === 1 ? values.groupingUnit[0] : null;

        const training = { ...values };

        delete training.username;
        delete training.firstName;
        delete training.lastName;
        delete training.org;
        delete training.available;
        delete training.existingUser;

        await onSubmit(values, training);
        closeModal();
    };

    const roleToAdd = role;

    const validate = values => {
        const errors = {};

        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!values.username) {
            errors.username = 'Username is required.';
        }
        if (!values.firstName) {
            errors.firstName = 'First Name is required.';
        }
        if (!values.lastName) {
            errors.lastName = 'Last Name is required.';
        }
        if (values.groupingUnit.length < 1) {
            errors.groupingUnit = 'District is required.';
        }
        if (values.username && !regexEmail.test(values.username)) {
            errors.username = 'Please input a valid email.';
        }

        return errors;
    };

    return (
        <Formik
            onSubmit={e => onHandleSubmit(e)}
            validate={e => validate(e)}
            initialValues={getInitialValues(initialValues)}
        >
            {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                    {values.available ? <div className={style.usernameValid}>Username available.</div> : null}
                    {values.existingUser && operation === 'create' ? (
                        <div className="alert alert-info" role="alert" style={{ textAlign: 'center' }}>
                            User already exists - filling in details.
                        </div>
                    ) : null}
                    <Field
                        name="username"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Username (Email):</RequiredFieldLabel>}
                        validateOnBlur={userCheck(values, roleToAdd)}
                        disabled={values.existingUser || operation === 'edit'}
                    />
                    <Field
                        name="firstName"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>First Name:</RequiredFieldLabel>}
                    />
                    <Field
                        name="lastName"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Last Name:</RequiredFieldLabel>}
                    />
                    <Field name="idNumber" component={CccisdInput} label="ID Number:" />
                    <Field name="groupingUnit" enableReinitialize>
                        {({ field, form }) => {
                            return (
                                <CccisdFieldWrapper
                                    field={field}
                                    form={form}
                                    label={<RequiredFieldLabel isRequired>District:</RequiredFieldLabel>}
                                >
                                    <Table
                                        query={groupingUnitsQuery}
                                        graphqlVariables={{}}
                                        rowKey="group.groupId"
                                        perPage={10}
                                        columns={[
                                            {
                                                name: 'group.label',
                                                label: 'District',
                                                sort: true,
                                                filter: true,
                                            },
                                            {
                                                name: 'ancestorGroups.organization.group.label',
                                                label: 'Organization',
                                                sort: true,
                                                filter: true,
                                            },
                                        ]}
                                        orderBy="group.label"
                                        csvFilename="Districts.csv"
                                        showRowActions={false}
                                        maxSelectableRows={1}
                                        selectedRows={operation === 'edit' ? initialValues.groupingUnit : field.value}
                                        rowSelectedCallback={selectedRows => {
                                            if (!_isEqual(selectedRows, field.value)) {
                                                setFieldValue('groupingUnit', selectedRows);
                                            }
                                        }}
                                        rowActions={[
                                            {
                                                name: 'addToDistrict',
                                                title: 'Add to District',
                                                action: () => {},
                                            },
                                        ]}
                                    />
                                </CccisdFieldWrapper>
                            );
                        }}
                    </Field>
                    <hr />
                    <CompletedTrainingsFields labelText="Has this user ever completed the trainings for these interventions?" />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1em',
                        }}
                    >
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

GuAdminSupervisorForm.propTypes = {
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    operation: PropTypes.string,
    productId: PropTypes.number,
    productLabel: PropTypes.string,
    initialValues: PropTypes.object,
    role: PropTypes.string,
};

export default GuAdminSupervisorForm;
