import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import widgets from '../widgets/widgets.js';
import filterFields from '../../common/filterFields.js';
import { getActualSelectedFields } from '../../common/helpers.js';

export const initialValues = {
    filterFields: getActualSelectedFields(filterFields),
    otherFilterOptions: [],
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = () => {
    return null;
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({ filterFields, widgets })(Component);
