import React, { useEffect, useState } from 'react';

import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { Route, withRouter, useParams } from 'cccisd-react-router';
import PlatformMediator from 'cccisd-laravel-assignment/dist/components/navigations/courseWrapper/Mediator/CourseWrapperMediator';
import certQuery from './certificateQuery.graphql';
import CertificateRender from 'js/components/CertificateRender';
import style from './style.css';
const Fortress = window.cccisd.fortress;

const MediatorRoot = ownProps => {
    const { mediatorProps } = ownProps;
    const [editedProps, setEditedProps] = useState(null);
    const [assignmentSettings, setAssignmentSettings] = useState({});
    const [row, setRow] = useState({});
    const [showBanner, setShowBanner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [courseName, setCourseName] = useState(null);
    const { productHandle } = useParams();

    const key = {
        ssgrin_35: 'S.S.GRIN 3-5',
        ssgrin_k2: 'S.S.GRIN K-2',
        ssgrin_ec: 'S.S.GRIN EC',
    };

    const currentCourse = { label: key[productHandle] };

    const hasAlts = ['ssgrin_k2', 'ssgrin_35'].includes(productHandle);

    // product handle -> default courseName (e.g. S.S.Grin K-2) -> hasAlts(isSSgrin) && altsComplete || hasTraining -> S.S.Grin K-5
    // product handle -> default courseName (e.g. AEL 1) -> !hasAlts(isSSgrin) -> defaultCourseName (AEL 1)

    useEffect(() => {
        async function getCertData() {
            try {
                const res = await client.query({
                    query: certQuery,
                    fetchPolicy: 'network-only',
                    variables: {
                        productHandle: `${productHandle}_training`,
                        pawnId: Fortress.user.acting.id,
                    },
                });

                if (res && res.data) {
                    // Training / Shared Completion Cert
                    const assignmentProgressList = res.data.roles.anyRole.assignmentProgressList;
                    const isComplete = res.data.roles.anyRole.assignmentProgress.completed;

                    const toFlat = {
                        assignmentProgress: {
                            completed: isComplete,
                        },
                        fields: {
                            trained: res.data.roles.anyRole.fields.trained,
                        },
                        pawn: {
                            pawnId: Fortress.user.acting.id,
                        },
                        user: {
                            fullName: res.data.roles.anyRole.user.fullName,
                        },
                    };

                    const flattened = flattenObj(toFlat);

                    flattened.assignmentProgressList = assignmentProgressList;

                    flattened.ownedLicenseList = res.data.roles.anyRole.ownedLicenseList;

                    const training = JSON.parse(res.data.roles.anyRole.fields.trained);

                    const hasCurrentTraining = training && training[productHandle];

                    const hasSharedCompletion = hasAlts && isAltComplete(hasAlternateSurvey(), assignmentProgressList);

                    if (!currentCourse.label) {
                        setCourseName(
                            res.data.flows.deployment.assignment.settings.navigationSettings.certificate.courseName
                        );
                    }

                    setAssignmentSettings(res.data.flows.deployment.assignment);
                    setRow(flattened);
                    setShowBanner(!isComplete && (hasCurrentTraining || hasSharedCompletion));
                    // Course Cert DisplayName

                    const newProps = { ...mediatorProps };

                    if (hasAlts) {
                        const displayName = getDisplayName(
                            res.data.roles.anyRole.ownedLicenseList,
                            isComplete,
                            assignmentProgressList,
                            training
                        );

                        newProps.certificate.certificates[0].courseName = displayName;
                    }
                    setEditedProps(newProps);
                    setLoading(false);
                }
            } catch (e) {
                console.error(e);
            }
        }

        getCertData();
    }, []);

    const getDisplayName = (list, complete, progress, train) => {
        const productList = list.filter(item => item.licenseId && !item.isExpired).map(item => item.product.handle);

        const altCompleted = isAltComplete(hasAlternateSurvey(), progress);

        const owns35 = productList.includes('ssgrin_35');
        const ownsK2 = productList.includes('ssgrin_k2');
        const trainedK5 = train.ssgrin_k2 || train.ssgrin_35 || complete || altCompleted;

        if (currentCourse.label === 'S.S.GRIN K-2') {
            if (ownsK2) {
                if (trainedK5) {
                    if (owns35) {
                        return 'S.S.GRIN K-5';
                    }
                    return 'S.S.GRIN K-2';
                }
            }
        }
        if (currentCourse.label === 'S.S.GRIN 3-5') {
            if (owns35) {
                if (trainedK5) {
                    if (ownsK2) {
                        return 'S.S.GRIN K-5';
                    }
                    return 'S.S.GRIN 3-5';
                }
            }
        }
        return null;
    };

    const hasAlternateSurvey = () => {
        if (currentCourse.label === 'S.S.GRIN K-2') {
            return 'ssgrin_35_training';
        }
        if (currentCourse.label === 'S.S.GRIN 3-5') {
            return 'ssgrin_k2_training';
        }
        return null;
    };

    const isAltComplete = (alternateSurvey, progress) => {
        if (alternateSurvey) {
            const alt = progress.find(item => item.deployment.deploymentHandle === alternateSurvey);

            if (alt && alt.completed) {
                return true;
            }
            return false;
        }
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <Route
            path={`${ownProps.match.path}/:courseWrapperTab?`}
            render={props => (
                <>
                    {showBanner && (
                        <div className={`${style.trainedBox} alert-success`}>
                            <h3>Training for this course has already been completed!</h3>
                            <h5>Click {`"View Certificate"`} to see the completion certificate.</h5>
                            <CertificateRender
                                row={row}
                                currentSettings={assignmentSettings}
                                currentCourse={courseName ? { label: courseName } : currentCourse}
                                productHandle={productHandle}
                            />
                        </div>
                    )}
                    <PlatformMediator
                        {...ownProps}
                        {...props}
                        basePath={ownProps.match.url}
                        mediatorProps={editedProps}
                    />
                </>
            )}
        />
    );
};

const flattenObj = ob => {
    let result = {};

    /* eslint-disable */
    for (const i in ob) {
        if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {
                result[i + '.' + j] = temp[j];
            }
        } else {
            result[i] = ob[i];
        }
    }
    return result;
};
export default withRouter(MediatorRoot);
