import React from 'react';
import { Field, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    issueTopic1: {
        show: true,
    },
    issueTopic2: {
        show: true,
    },
    issueTopic3: {
        show: true,
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Issue Charts?" /> <hr />
            <Field name="issueTopic1.show" component={CccisdToggle} label="Show Issue Chart 1?" />
            <Field name="issueTopic2.show" component={CccisdToggle} label="Show Issue Chart 2?" />
            <Field name="issueTopic3.show" component={CccisdToggle} label="Show Issue Chart 3?" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
