import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';

const fakeData = [
    {
        id: 1,
        sessionDate: '2020-03-11',
        comment: 'First comment',
    },
    {
        id: 2,
        sessionDate: '2020-03-12',
        comment: 'Another comment',
    },
];

const Component = props => {
    const { title, description } = props.settings;

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        let realData = fakeData;
        if (!props.isPreview) {
            // get real data from grapqhl or ajax
        }

        setTimeout(() => {
            setData(realData);
            setLoading(false);
        }, 2000);
    }, []);

    return (
        <Loader loading={loading}>
            <h3>{title}</h3>
            <p>{description}</p>
            {data.map(item => (
                <li key={item.id}>
                    {item.sessionDate} {item.comment}
                </li>
            ))}
        </Loader>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
Component.defaultProps = {
    filters: {},
};

export default Component;
