import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, CccisdInput, CccisdSelect, CccisdFieldWrapper } from 'cccisd-formik';
import { client as apollo } from 'cccisd-apollo';
import PropTypes from 'prop-types';
import { MultiSelect } from 'react-multi-select-component';
import _isEqual from 'lodash/isEqual';
import Table from 'cccisd-graphql-table';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import { getProducts } from '../../../reducers/products.js';
import RequiredFieldLabel from '../RequiredFieldLabel';
import schoolsQuery from './schools.graphql';
import learnersQuery from './learners.graphql';
import learnersEditQuery from './learnersEdit.graphql';
import productsQuery from './products.graphql';

const fields = window.cccisd && window.cccisd.appDefs.pawn.fields;
const enumFields = fields.filter(field => field.data_type === 'enum');
const enumValues = {};
for (const field of enumFields) {
    enumValues[field.handle] = field.values.map(item => ({
        label: item.name,
        value: item.value,
    }));
}

const Fortress = window.cccisd && window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;
const pawnId = Fortress.user.acting.id;
const isUberAdmin = userRole === 'uberadmin';
const isInstructor = userRole === 'instructor';
const isSiteAdmin = userRole === 'siteAdmin';
const isSelectSchoolRole = userRole === 'orgAdmin' || userRole === 'guAdmin' || userRole === 'supervisor';
const isInstructorOrSiteAdmin = userRole === 'siteAdmin' || userRole === 'instructor';

const GroupForm = props => {
    const [products, setProducts] = useState([]);
    const [schools, setSchools] = useState([]);
    const [allInstructors, setAllInstructors] = useState([]);
    const [loading, setLoading] = useState(true);

    const { productHandle } = useParams();
    const dispatch = useDispatch();

    const { productLabel, onSubmit, initialValues, operation } = props;

    !isUberAdmin &&
        useEffect(() => {
            (async () => {
                dispatch(getProducts(productHandle));
            })();
        }, [dispatch]);

    const { productId } = useSelector(state => state.app.products);

    // If the user is an uberadmin, we need them to choose a product they're adding the group to, so first we need to build the "Product" field dropdown from available products
    useEffect(() => {
        const start = async () => {
            if (isUberAdmin) await getAllProducts();
            await getSchools();
        };
        start();
    }, []);

    async function getAllProducts() {
        const res = await apollo.query({
            query: productsQuery,
            variables: {},
            fetchPolicy: 'network-only',
        });

        const arr = res.data.products.productList;
        setProducts(arr);
    }

    async function getSchools() {
        const res = await apollo.query({
            query: schoolsQuery,
            variables: { productId },
            fetchPolicy: 'network-only',
        });

        const arr = res.data.groups.siteList;
        setSchools(arr);

        if (isSiteAdmin) {
            // siteAdmin schoolId is set, get instructors
            initialValues.school = arr[0].group.groupId;
            getInstructors(arr[0].group.groupId, arr);
        }

        operation === 'edit' && initialValues.groupLeaders.length > 0 && initialValues.school !== null
            ? getInstructors(initialValues.school, arr)
            : setLoading(false);
    }

    const onHandleSubmit = async values => {
        const justPawnIds = values.groupLeaders.map(gl => {
            return gl.value;
        });

        values.groupLeaderIds = JSON.stringify(justPawnIds);
        values.participantIds = JSON.stringify(values.learners);

        await onSubmit(values);
    };

    const validate = values => {
        const errors = {};

        if (!values.productId) {
            errors.productId = 'Product is required.';
        }
        if (!values.groupLabel) {
            errors.groupLabel = 'Group Name is required.';
        }
        if (!values.school) {
            errors.school = 'School is required.';
        }
        if (values.groupLeaders.length < 1) {
            errors.groupLeaders = 'At least one Group Leader is required.';
        }

        return errors;
    };

    const productOptions = items => {
        return items.map(item => {
            return {
                value: item.productId,
                label: item.label,
            };
        });
    };

    const schoolOptions = items => {
        return items.map(({ group: { groupId, label }, ancestorGroups }) => {
            return {
                value: groupId,
                label: `${label} (${ancestorGroups.groupingUnit.group.label})`,
            };
        });
    };

    const getInstructors = (schoolId, schoolList) => {
        let selectedSchool = schoolList.filter(school => school.group.groupId === +schoolId);

        if (selectedSchool.length === 1) {
            selectedSchool = selectedSchool[0];

            if (selectedSchool.childRoles.instructorList.length < 1) {
                return <div>No {productLabel} Group Leaders are currently active at this School.</div>;
            }

            const results = selectedSchool.childRoles.instructorList.map(inst => {
                return {
                    value: inst.pawn.pawnId,
                    label: inst.user.fullNameWithUsername,
                };
            });

            setAllInstructors(results);
            setLoading(false);
        }
    };

    const columns = [
        { name: 'pawn.pawnId', label: 'ID', hideInTable: true },
        { name: 'fields.participantId', label: 'ID Number', sort: true, filter: true },
        { name: 'fields.participantName', label: 'Name', sort: true, filter: true },
        {
            name: 'fields.gender',
            label: 'Gender',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                options: [...enumValues.gender],
            },
        },
        {
            name: 'fields.grade',
            label: 'Grade',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                options: [...enumValues.grade],
            },
        },
        { name: 'fields.teacherName', label: 'Teacher Name', sort: true, filter: true },
    ];

    // We don't need the group leaders to select a product, a school, or themselves as a group leader, so we set those initial values and hide the fields from their view
    const instructorInitialValues = {
        productId,
        school: schools.length === 1 ? schools[0].group.groupId : '',
        groupLeaders: schools.length === 1 ? [{ value: pawnId }] : [],
    };
    const siteAdminInitialValues = {
        productId,
        school: schools.length === 1 ? schools[0].group.groupId : '',
    };
    const selectSchoolInitialValues = {
        productId,
    };

    const getInitialValues = () => {
        if (isInstructor) return { ...initialValues, ...instructorInitialValues };
        if (isSiteAdmin) return { ...initialValues, ...siteAdminInitialValues };
        if (isSelectSchoolRole) return { ...initialValues, ...selectSchoolInitialValues };

        return initialValues;
    };

    return loading ? (
        <Loader loading />
    ) : (
        <Formik onSubmit={e => onHandleSubmit(e)} validate={e => validate(e)} initialValues={getInitialValues()}>
            {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                    {isUberAdmin && (
                        <Field
                            name="productId"
                            component={CccisdSelect}
                            label={<RequiredFieldLabel isRequired>Product:</RequiredFieldLabel>}
                            options={[
                                {
                                    value: 1000,
                                    label: '-- Please select a Product --',
                                },
                                ...productOptions(products),
                            ]}
                            onChange={event => {
                                const product = event.target.value;
                                setFieldValue('productId', +product);
                            }}
                        />
                    )}

                    <Field
                        name="groupLabel"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Group Name:</RequiredFieldLabel>}
                    />

                    {values.productId ? (
                        <>
                            {!isInstructorOrSiteAdmin && (
                                <Field
                                    name="school"
                                    component={CccisdSelect}
                                    label={<RequiredFieldLabel isRequired>School:</RequiredFieldLabel>}
                                    options={[
                                        {
                                            value: 0,
                                            label: '-- Please select a School --',
                                        },
                                        ...schoolOptions(schools),
                                    ]}
                                    onChange={event => {
                                        const school = event.target.value;
                                        getInstructors(+school, schools);
                                        setFieldValue('school', +school);
                                        setFieldValue('groupLeaders', []);
                                    }}
                                />
                            )}

                            {values.school ? (
                                <>
                                    {!isInstructor && (
                                        <Field name="groupLeaders" enableReinitialize>
                                            {({ field, form }) => {
                                                return (
                                                    <CccisdFieldWrapper
                                                        field={field}
                                                        form={form}
                                                        label={
                                                            <RequiredFieldLabel isRequired>
                                                                Group Leader(s):
                                                            </RequiredFieldLabel>
                                                        }
                                                    >
                                                        <MultiSelect
                                                            options={allInstructors}
                                                            hasSelectAll={false}
                                                            value={
                                                                form.values.groupLeaders
                                                                    ? form.values.groupLeaders
                                                                    : initialValues.groupLeaders
                                                            }
                                                            onChange={value =>
                                                                form.setFieldValue('groupLeaders', value)
                                                            }
                                                            labelledBy="-- Group Leader(s) --"
                                                        />
                                                    </CccisdFieldWrapper>
                                                );
                                            }}
                                        </Field>
                                    )}

                                    <Field name="learners" enableReinitialize>
                                        {({ field, form }) => {
                                            return (
                                                <CccisdFieldWrapper field={field} form={form} label="Participants:">
                                                    <Table
                                                        query={operation === 'edit' ? learnersEditQuery : learnersQuery}
                                                        graphqlVariables={{
                                                            productId: isUberAdmin ? values.productId : productId,
                                                            schoolId: +values.school,
                                                            classId: operation === 'edit' && props.row['pawn.pawnId'],
                                                        }}
                                                        rowKey="pawn.pawnId"
                                                        columns={columns}
                                                        orderBy="fields.participantId"
                                                        csvFilename={`${productLabel}_Participants.csv`}
                                                        selectedRows={
                                                            operation === 'edit' ? initialValues.learners : field.value
                                                        }
                                                        showRowActions={false}
                                                        rowSelectedCallback={selectedRows => {
                                                            if (!_isEqual(selectedRows, field.value)) {
                                                                setFieldValue('learners', selectedRows);
                                                            }
                                                        }}
                                                        rowActions={[
                                                            {
                                                                name: 'addToClass',
                                                                title: 'Add to Group',
                                                                action: () => {},
                                                            },
                                                        ]}
                                                    />
                                                </CccisdFieldWrapper>
                                            );
                                        }}
                                    </Field>
                                </>
                            ) : null}
                        </>
                    ) : null}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1em',
                        }}
                    >
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

GroupForm.propTypes = {
    onSubmit: PropTypes.func,
    operation: PropTypes.string,
    productLabel: PropTypes.string,
    initialValues: PropTypes.object,
    row: PropTypes.object,
};

export default GroupForm;
