import { handleActions, createAction } from 'redux-actions';
import { client } from 'cccisd-apollo';

import productQuery from '../graphql/productQuery.graphql';

export const initialState = {
    loading: true,
    productId: null,
    productLabel: '',
};

// Actions
const SET_LOADING = 'app/report/SET_LOADING';
const SET_PRODUCT_ID = 'app/report/SET_PRODUCT_ID';
const SET_PRODUCT_LABEL = 'app/report/SET_PRODUCT_LABEL';
const SET_PRODUCT_HANDLE = 'app/report/SET_PRODUCT_HANDLE';

// Action Creators
export const setLoading = createAction(SET_LOADING);
export const setProductId = createAction(SET_PRODUCT_ID);
export const setProductLabel = createAction(SET_PRODUCT_LABEL);
export const setProductHandle = createAction(SET_PRODUCT_HANDLE);

export const getProducts = productHandle => {
    return async dispatch => {
        try {
            const response = await client.query({
                query: productQuery,
                variables: {
                    productHandle,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });
            const { productId, label } = response.data.products.product;

            dispatch(setProductId(productId));
            dispatch(setProductLabel(label));
            dispatch(setProductHandle(productHandle));
            dispatch(setLoading(false));
        } catch (e) {
            console.error(e);
        }
    };
};

// Reducers
export default handleActions(
    {
        [SET_LOADING]: (state, action) => ({
            ...state,
            loading: action.payload,
        }),

        [SET_PRODUCT_ID]: (state, action) => ({
            ...state,
            productId: action.payload,
        }),

        [SET_PRODUCT_LABEL]: (state, action) => ({
            ...state,
            productLabel: action.payload,
        }),
        [SET_PRODUCT_HANDLE]: (state, action) => ({
            ...state,
            productHandle: action.payload,
        }),
    },
    initialState
);
