import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import { client as apollo } from 'cccisd-apollo';
import { useParams } from 'cccisd-react-router';
import { Link } from 'react-router-dom';
import { productTraining } from '../../../../vendor/reports/reportTemplates/common/helpers.js';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../../../reducers/products.js';

import IconLock from 'cccisd-icons/lock2';

import trainingQuery from './trainingQuery.graphql';
import k2or35TrainingQuery from './k2or35TrainingQuery.graphql';

const Fortress = window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const TrainingPrompt = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [pageContent, setPageContent] = useState({});

    const dispatch = useDispatch();

    const trainingStatus = useSelector(state => state.app.userAccess.trained);

    const { productHandle } = useParams();

    useEffect(() => {
        dispatch(getProducts(productHandle));
    }, [productHandle]);

    const { productLabel } = useSelector(state => state.app.products);

    useEffect(() => {
        if (
            children.props.pageTitle === 'Manage' ||
            children.props.match.path.includes('training') ||
            children.props.match.path.includes('courseProgress') ||
            trainingStatus === true
        ) {
            setPageContent(children);
            return setLoading(false);
        }

        if (!trainingStatus) {
            getUpdatedTrainingStatus();
        }

        async function getUpdatedTrainingStatus() {
            const res = await apollo.query({
                query:
                    productHandle === 'ssgrin_k2' || productHandle === 'ssgrin_35'
                        ? k2or35TrainingQuery
                        : trainingQuery,
                variables: {
                    productHandle,
                    pawnId,
                },
                fetchPolicy: 'network-only',
            });

            const passedTrainings = res.data.flows.assignmentProgressList.filter(
                training => training.devTags.PassTraining === '1'
            );

            const passed = passedTrainings.length > 0;

            const prevTrainingResponse = res.data.roles.anyRole.fields.trained;

            const prodObject = JSON.parse(prevTrainingResponse);

            const previouslyTrained = productTraining(prodObject, productHandle);
            const trained = previouslyTrained === true || passed === true;

            if (trained) {
                setPageContent(children);
                return setLoading(false);
            }

            setPageContent(
                <div className="jumbotron">
                    <h2>
                        <IconLock spaceRight />
                        Access Locked
                    </h2>
                    <p>
                        {`To receive full access to ${
                            productLabel ? productLabel : 'this product'
                        }, you must complete the training and pass the Final Test.`}
                    </p>
                    <p>
                        <Link to={`/product/${productHandle}/training`}>
                            <button type="button" className="btn btn-primary btn-lg">
                                Complete Your Training
                            </button>
                        </Link>
                    </p>
                </div>
            );
            setLoading(false);
        }
    }, []);

    return loading ? <Loader type="inline" /> : pageContent;
};

TrainingPrompt.propTypes = {
    children: PropTypes.object,
};

export default TrainingPrompt;
