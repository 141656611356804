import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import { OutcomesDataEntry } from 'cccisd-laravel-assignment';
import { useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import classQuery from './groupList.graphql';
import tableQuery from './tableQuery.graphql';
import questQuery from './questId.graphql';
import messageQuery from './messageQuery.graphql';
const Fortress = window.cccisd.fortress;

function OutcomesData() {
    const [classList, setClassList] = useState([]);
    const [questId, setQuestId] = useState(null);
    const [loading, setLoading] = useState(true);

    const { productId } = useSelector(state => state.app.products);
    const { productHandle } = useParams();

    useEffect(() => {
        async function start() {
            await getQuestProject();
            await initializeData();
        }
        start();
    }, []);

    async function getQuestProject() {
        const res = await apollo.query({
            query: questQuery,
            fetchPolicy: 'network-only',
            variables: { productHandle },
        });

        setQuestId(res.data.groups.questproject.group.groupId);
    }

    async function getClassList() {
        const pawnId = Fortress.user.acting.id;

        const res = await apollo.query({
            query: classQuery,
            fetchPolicy: 'network-only',
            variables: { productId, pawnId },
        });

        return res.data;
    }

    async function initializeData() {
        const data = await getClassList();
        const formattedList = formatList(data.roles.classList);

        setClassList(formattedList);

        setLoading(false);
    }

    function formatList(list) {
        return list.map(
            ({
                pawn: { pawnId, pawnHash },

                fields: { groupLabel },
            }) => {
                return {
                    groupId: pawnId,
                    label: groupLabel,
                    pawnId,
                    pawnHash,
                    flowProps: {},
                };
            }
        );
    }

    return (
        !loading && (
            <OutcomesDataEntry
                questprojectIds={[questId]}
                groups={classList}
                title="Participant Outcomes"
                description="Outcome assessments enable programs to explore the degree to which the services, content, and activities delivered to participants are having the desired impact. To explore this data further, go to the Reports tab to view corresponding reports."
                tableColumns={[
                    {
                        name: 'fields.participantId',
                        label: 'ID',
                        sort: true,
                        filter: true,
                    },
                    {
                        name: 'fields.participantName',
                        label: 'Name',
                        sort: true,
                        filter: true,
                    },
                ]}
                tableFields={['participantId', 'participantName']}
                modalTitleField="participantId"
                tableQuery={tableQuery}
                messageQuery={messageQuery}
            />
        )
    );
}

OutcomesData.propTypes = {
    product: PropTypes.object,
};

export default OutcomesData;
