import React from 'react';
import style from './style.css';
import _isNil from 'lodash/isNil';
import _round from 'lodash/round';

/*
 * Show the list of values in an array
 * 
 * "renderAs": {
        "type": "ListRender",
        "class": "html",
        "settings": {
            "values": [
                {
                    "valuePath": "sdqPre.devTags.SDQStudentTotalDifficultiesScore",
                    "prefix": "",
                    "suffix": "",
                    "round": true, // use Math.round to round value
                    "ifValue": { // if value is 0, display M; otherwise display value
                        "value": 0, 
                        "display": "M"
                    }
                },
                {
                    "valuePath": "sdqPost.devTags.SDQStudentTotalDifficultiesScore",
                    "prefix": "",
                    "suffix": "",
                    "round": true,
                    "ifValue": {
                        "value": 0,
                        "display": "M"
                    }
                }
            ],
            "scale": [  // min/max value to highlight in color
                {
                    "min": 20,
                    "max": 40,
                    "colorClass": "veryHigh"
                },
                {
                    "min": 18,
                    "max": 19,
                    "colorClass": "high"
                },
                {
                    "min": 15,
                    "max": 17,
                    "colorClass": "mid"
                },
                {
                    "min": 0,
                    "max": 13,
                    "colorClass": "average"
                }
            ]
        }
    }

    Output in the cell:
 *     Pre: 10/20/2018
 *     Post: 02/20/2019
 */
export default settings => {
    return ({ value, row }) => {
        return (
            <div key={row} className={style.container}>
                {settings.values.map((item, index) => {
                    var colorValue = '';

                    if (settings.scale !== 'undefined' && !_isNil(settings.scale)) {
                        for (var i = 0; i < settings.scale.length; i++) {
                            if (
                                row[item.valuePath] >= settings.scale[i].min &&
                                row[item.valuePath] <= settings.scale[i].max
                            ) {
                                colorValue = settings.scale[i].colorClass;
                                break;
                            }
                        }
                    }

                    let itemValue = row[item.valuePath] !== null ? row[item.valuePath] : null;

                    // manipulate value if needed
                    if (item.round) {
                        if (item.decimalPlaces) {
                            itemValue = _round(itemValue, item.decimalPlaces);
                        } else {
                            itemValue = Math.round(itemValue);
                        }
                    }

                    if (item.ifValue && itemValue === item.ifValue.value) {
                        itemValue = item.ifValue.display;
                    }

                    if (itemValue === null || itemValue === '') {
                        return null;
                    }

                    return (
                        row[item.valuePath] !== null && (
                            <p key={index} className={style[colorValue]}>
                                {item.prefix}
                                {itemValue}
                                {item.suffix}
                            </p>
                        )
                    );
                })}
            </div>
        );
    };
};
