import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from 'cccisd-nivo/line';
import _get from 'lodash/get';
import { calcAvg } from '../../../../../common/helpers';

const DetailChart = props => {
    const [average, setAverage] = useState([]);

    const { title, description } = props.settings;
    const {
        data,
        sessionDataVariables: { fidelityBenchmark, totalSessions, handle },
    } = props;

    useEffect(() => {
        data && data[0].data ? getScores(data[0].data) : null;
    }, []);

    const getScores = scores => {
        const justScores =
            scores && scores.length > 0
                ? scores.map(score => {
                      return _get(score, 'y');
                  })
                : [];

        const nonNulls =
            justScores.length > 0 ? justScores.filter(s => s !== null).map(s => +s) : [];

        const getAverage = calcAvg(nonNulls);

        setAverage(getAverage.toFixed(0));
    };

    const benchmarkCounter = scores => {
        const aboveBenchmark = [];

        const justScores =
            scores && scores.length > 0
                ? scores.map(score => {
                      return _get(score, 'y');
                  })
                : [];

        justScores.map(el => +el >= 80 && aboveBenchmark.push(el));

        return aboveBenchmark.length;
    };

    return (
        <div
            style={{
                padding: '1em',
                border: '3px solid #007298',
                borderRadius: '10px',
                marginTop: '1em',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ width: '50%', fontFamily: 'Cabin' }}>
                <h3 style={{ color: '#007298' }}>{title}</h3>
                <p>{description}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ textAlign: 'center' }}>
                        <span
                            style={{
                                fontSize: '2em',
                                fontWeight: 'bold',
                                color: average < fidelityBenchmark ? '#d03916' : '#3673b6',
                            }}
                        >
                            {average}%
                        </span>
                        <p>
                            <i>Average Across All Sessions</i>
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <span
                            style={{
                                fontSize: '2em',
                                fontWeight: 'bold',
                            }}
                        >
                            {benchmarkCounter(data[0].data)}
                        </span>{' '}
                        of{' '}
                        <span
                            style={{
                                fontSize: '2em',
                                fontWeight: 'bold',
                            }}
                        >
                            {totalSessions}
                        </span>{' '}
                        <p>
                            <i>Sessions at or Above Benchmark</i>
                        </p>
                    </div>
                </div>
            </div>

            <div style={{ height: '200px', width: '50%' }}>
                <ResponsiveLine
                    data={data}
                    legends={[]}
                    xFormat={d => `Session ${d}`}
                    theme={{ fontFamily: 'Cabin' }}
                    markers={[
                        {
                            axis: 'y',
                            value: '80',
                            lineStyle: {
                                stroke: 'green',
                                strokeWidth: 1,
                            },
                        },
                    ]}
                    yScale={{
                        type: 'linear',
                        min: '0',
                        max: '100',
                    }}
                    xScale={{
                        type: 'linear',
                        min: '1',
                        max: handle === 'ssgrin_ec' ? '12' : '10',
                    }}
                    margin={{
                        bottom: 50,
                        left: 75,
                        right: 10,
                        top: 20,
                    }}
                    axisLeft={{
                        legend: 'Fidelity',
                        legendPosition: 'middle',
                        tickPadding: 10,
                        legendOffset: -50,
                        tickValues: ['20', '40', '60', '80', '100'],
                    }}
                    axisBottom={{
                        legend: 'Sessions',
                        legendPosition: 'middle',
                        tickPadding: 10,
                        legendOffset: 45,
                    }}
                />
            </div>
        </div>
    );
};

DetailChart.propTypes = {
    title: PropTypes.string,
    sessionDataVariables: PropTypes.object,
    settings: PropTypes.object,
    data: PropTypes.array,
    reachedBenchmark: PropTypes.number,
};

export default DetailChart;
