import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import style from './style.css';

const LackingPermissions = ({
    customSwitchProject,
    hideProjectDropdown,
    isExported,
    labelForProject,
    labelForProjectPlural,
    limitProjectsByPermissions,
    projectIdWhitelist,
    regardlessOfProject,
}) => {
    const header = hideProjectDropdown ? null : (
        <Header
            customSwitchProject={customSwitchProject}
            isExported={isExported}
            labelForProject={labelForProject}
            labelForProjectPlural={labelForProjectPlural}
            limitProjectsByPermissions={limitProjectsByPermissions}
            projectIdWhitelist={projectIdWhitelist}
            showProjectDropdown
            showQuestCount={false}
        />
    );

    return (
        <React.Fragment>
            {header}
            <div className={style.info}>
                <h3>You lack permission to view this area{regardlessOfProject ? '' : ' in this project'}.</h3>
                <p>If you believe that you have received this message in error, please contact your administrator.</p>
            </div>
        </React.Fragment>
    );
};

LackingPermissions.propTypes = {
    customSwitchProject: PropTypes.func,
    hideProjectDropdown: PropTypes.bool,
    isExported: PropTypes.bool,
    labelForProject: PropTypes.string,
    labelForProjectPlural: PropTypes.string,
    limitProjectsByPermissions: PropTypes.array,
    projectIdWhitelist: PropTypes.array,
    regardlessOfProject: PropTypes.bool,
};

export default LackingPermissions;
