import React from 'react';

const CompletedDateRender = props => {
    const { row, currentCourse, productHandle, dataName } = props;

    const hasAlternateSurvey = () => {
        if (props.productHandle === 'ssgrin_35') {
            return 'ssgrin_k2_training';
        }
        if (props.productHandle === 'ssgrin_k2') {
            return 'ssgrin_35_training';
        }
        return null;
    };

    const isAltComplete = () => {
        const alt = row.assignmentProgressList.find(item => item.deployment.deploymentHandle === alternateSurvey);

        if (alt && alt.completed) {
            return true;
        }
        return false;
    };
    const train = JSON.parse(row['fields.trained']);
    const hasCurrentTraining = train[productHandle];
    const productList = row.ownedLicenseList.filter(item => item.licenseId).map(item => item.product.handle);
    const alternateSurvey = hasAlternateSurvey();
    const altCompleted = isAltComplete(alternateSurvey);
    const completed = row['assignmentProgress.completed'];

    const ownsCurrentProduct = productList.includes(productHandle);

    const getDisplayName = () => {
        const owns35 = productList.includes('ssgrin_35');
        const ownsK2 = productList.includes('ssgrin_k2');
        const trainedK5 = train.ssgrin_k2 || train.ssgrin_35 || completed || altCompleted;
        if (currentCourse.label === 'S.S.GRIN K-2') {
            if (ownsK2) {
                if (trainedK5) {
                    if (owns35) {
                        return 'S.S.GRIN K-5';
                    }
                    return 'S.S.GRIN K-2';
                }
            }
        }
        if (currentCourse.label === 'S.S.GRIN 3-5') {
            if (owns35) {
                if (trainedK5) {
                    if (ownsK2) {
                        return 'S.S.GRIN K-5';
                    }
                    return 'S.S.GRIN 3-5';
                }
            }
        }

        return currentCourse.label;
    };
    const displayValue = row[dataName];
    const displayName = getDisplayName();
    if (ownsCurrentProduct && (completed || altCompleted || hasCurrentTraining)) {
        return (
            <>
                {displayName} <br /> {displayValue ? displayValue : 'DATE: N/A'}
            </>
        );
    }

    return null;
};

export default CompletedDateRender;
