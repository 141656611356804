import React, { useRef } from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';

import SiteAdminGroupLeaderForm from '../../../../components/Manage/SiteAdminGroupLeaderForm';

import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const EditSiteAdminGroupLeaderForm = props => {
    const modal = useRef();

    // rows are coming from each role's tableMain.graphql
    const { row, loadData, settings } = props;

    const onSubmit = async (values, training) => {
        const userValues = {
            user: values.username,
            username: values.username,
            pawnHash: row['pawn.pawnHash'],
            first_name: values.firstName,
            last_name: values.lastName,
            idNumber: values.idNumber,
            group: values.school,
            parentPawns: values.supervisorArray,
        };

        const previouslyTrainedValues = training;

        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: row['pawn.pawnId'],
            }),
            {
                ...userValues,
                ...previouslyTrainedValues,
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        await loadData();
        notification(settings.role === 'siteAdmin' ? 'Site Admin edited' : 'Group Leader edited');
    };

    const trained = JSON.parse(row['fields.trained']) !== null ? JSON.parse(row['fields.trained']) : null;

    return (
        <Modal
            ref={modal}
            trigger={
                <Tooltip title="Edit">
                    <button type="button" className="btn btn-xs btn-success">
                        <IconPencil />
                    </button>
                </Tooltip>
            }
            title={`Edit ${row['user.fullName']}`}
        >
            <SiteAdminGroupLeaderForm
                operation="edit"
                onSubmit={onSubmit}
                initialValues={{
                    username: row['user.username'],
                    firstName: row['user.firstName'],
                    lastName: row['user.lastName'],
                    idNumber: row['fields.idNumber'],
                    site:
                        settings.role === 'siteAdmin'
                            ? [row['parentGroup.site.group.groupId']]
                            : [row['ancestorGroups.site.group.groupId']],
                    ...trained,
                    supervisor: settings.role === 'siteAdmin' ? null : row['parentRoles.supervisor.pawn.pawnId'],
                }}
                role={settings.role}
            />
        </Modal>
    );
};

EditSiteAdminGroupLeaderForm.propTypes = {
    loadData: PropTypes.func,
    row: PropTypes.object,
    settings: PropTypes.object,
};

export default EditSiteAdminGroupLeaderForm;
