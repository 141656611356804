import React, { useState } from 'react';
import { NavLink } from 'cccisd-react-router';
import _get from 'lodash/get';

const appUrl = _get(window, 'cccisd.env.url');
const wpUrl = _get(window, 'cccisd.env.wpUrl') || _get(window, 'cccisd.appDefs.app.wpUrl');
const isApp = appUrl ? window.location.href.startsWith(appUrl) : false;

const NavbarLink = ({ to, children, ...props }) => {
    if (to.startsWith(wpUrl) || (isApp && to.startsWith('/api/'))) {
        return (
            <a href={to} {...props}>
                {children}
            </a>
        );
    }

    return (
        <NavLink to={to} {...props}>
            {children}
        </NavLink>
    );
};

const DropdownLink = ({ label, to, children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <li
            className={`dropdown dropdown-toggle ${isMenuOpen && 'open'}`}
            onMouseEnter={() => setIsMenuOpen(true)}
            onMouseLeave={() => setIsMenuOpen(false)}
            aria-haspopup="true"
            aria-expanded="false"
        >
            <NavbarLink to={to}>{label}</NavbarLink>
            <ul className="dropdown-menu">{children}</ul>
        </li>
    );
};

export default DropdownLink;
