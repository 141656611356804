import { handleActions, createAction } from 'redux-actions';
import { client } from 'cccisd-apollo';

import productsListQuery from '../graphql/productListQuery.graphql';
import learnerQuery from '../graphql/learnerQuery.graphql';
import groupQuery from '../graphql/groupQuery.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;

export const initialState = {
    loading: true,
    learnerList: [],
    learner: null,
    groupList: [],
    group: null,
    sessionDataVariables: {},
    widgetsLoaded: [],
    productIds: [],
    currentProduct: null,
};

// Actions

const SET_LOADING = 'app/report/SET_LOADING';
const UNSET_LOADING = 'app/report/UNSET_LOADING';
const SET_LEARNER = 'app/report/SET_LEARNER';
const SET_GROUP = 'app/report/SET_GROUP';
const SET_CURRENT_PRODUCT = 'app/report/SET_CURRENT_PRODUCT';
const SET_LEARNER_LIST = 'app/report/SET_LEARNER_LIST';
const SET_GROUP_LIST = 'app/report/SET_GROUP_LIST';
const SET_PRODUCT_IDS = 'app/report/SET_PRODUCT_IDS';
const SET_SESSION_DATA_VARIABLES = 'app/report/SET_SESSION_DATA_VARIABLES';
const SET_NO_GROUPS = 'app/report/SET_NO_GROUPS';
const UNSET_NO_GROUPS = 'app/report/UNSET_NO_GROUPS';
const SET_NO_LEARNERS = 'app/report/SET_NO_LEARNERS';
const UNSET_NO_LEARNERS = 'app/report/UNSET_NO_LEARNERS';
const SET_WIDGETS_LOADED = 'app/report/SET_WIDGETS_LOADED';
const RESET_WIDGETS_LOADED = 'app/report/RESET_WIDGETS_LOADED';

// Action Creators
export const setLoading = createAction(SET_LOADING);
export const unsetLoading = createAction(UNSET_LOADING);
export const setLearner = createAction(SET_LEARNER);
export const setLearnerList = createAction(SET_LEARNER_LIST);
export const setGroup = createAction(SET_GROUP);
export const setNoGroups = createAction(SET_NO_GROUPS);
export const unsetNoGroups = createAction(UNSET_NO_GROUPS);
export const setNoLearners = createAction(SET_NO_LEARNERS);
export const unsetNoLearners = createAction(UNSET_NO_LEARNERS);
export const setCurrentProduct = createAction(SET_CURRENT_PRODUCT);
export const setGroupList = createAction(SET_GROUP_LIST);
export const setProductIds = createAction(SET_PRODUCT_IDS);
export const setSessionDataVariables = createAction(SET_SESSION_DATA_VARIABLES);
export const setWidgetsLoaded = createAction(SET_WIDGETS_LOADED);
export const resetWidgetsLoaded = createAction(RESET_WIDGETS_LOADED);

export const initialize = () => {
    return async (dispatch, getState) => {
        if (Fortress.isSuperUser()) {
            await dispatch(getProductIds());
        } else {
            await dispatch(getGroups());
        }
    };
};

export const getProductIds = () => {
    return async (dispatch, getState) => {
        try {
            const response = await client.query({
                query: productsListQuery,
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });

            const productList = response.data.products.productList.map(item => {
                return { value: item.productId, label: item.label, handle: item.handle };
            });

            await dispatch(setProductIds(productList));
            await dispatch(setCurrentProduct(productList[0].value));
            await dispatch(getGroups());
        } catch (e) {
            console.error(e);
        }
    };
};

const getLearners = () => {
    return async (dispatch, getState) => {
        try {
            const nState = getState();

            // reset noLearners before fetch
            if (nState.app.reportFilters.noLearners) {
                dispatch(unsetNoLearners());
            }

            const response = await client.query({
                query: learnerQuery,
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
                variables: {
                    groupId: nState.app.reportFilters.group,
                },
            });

            const learnerList = response.data.roles.learnerList.map(item => {
                return { value: item.pawn.pawnId, label: item.fields.participantId };
            });

            dispatch(setLearnerList(learnerList));
            if (learnerList.length === 0) {
                await dispatch(setNoLearners());
            } else {
                await dispatch(setLearner(learnerList[0].value));
            }
            await dispatch(unsetLoading());
        } catch (e) {
            console.error(e);
        }
    };
};

export const getGroups = () => {
    return async (dispatch, getState) => {
        try {
            const nState = getState();

            // reset noGroups before fetch
            if (nState.app.reportFilters.noGroups) {
                dispatch(unsetNoGroups());
            }

            const response = await client.query({
                query: groupQuery,
                variables: {
                    productId: nState.app.reportFilters.currentProduct || nState.app.products.productId,
                    filter: Fortress.isSuperUser()
                        ? {}
                        : {
                              eq: {
                                  field: 'parentRoles.instructor.pawn.pawnId',
                                  int: Fortress.user.acting.id,
                              },
                          },
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });

            const groupList = response.data.roles.classList.map(item => {
                return { value: item.pawn.pawnId, label: item.fields.groupLabel };
            });

            await dispatch(setGroupList(groupList));

            if (groupList.length === 0) {
                dispatch(setNoGroups());
            } else {
                await dispatch(setGroup(groupList[0].value));
                await dispatch(getLearners());
            }
        } catch (e) {
            console.error(e);
        }
    };
};

export const updateGroup = id => {
    return async (dispatch, getState) => {
        const list = getState().app.reportFilters.groupList;
        const group = list.find(item => item.value === id);
        await dispatch(setGroup(group.value));
        // await dispatch(setLoading());
        await dispatch(getLearners());
    };
};

export const updateCurrentProduct = id => {
    return async (dispatch, getState) => {
        const list = getState().app.reportFilters.productIds;
        const product = list.find(item => item.value === id);

        await dispatch(setCurrentProduct(product.value));
        // await dispatch(setLoading());
        await dispatch(getGroups());
    };
};

export const updateParticipant = id => {
    return async (dispatch, getState) => {
        const list = getState().app.reportFilters.learnerList;
        const participant = list.find(item => item.value === id);
        dispatch(setLearner(participant.value));
    };
};

export const getSessionDataVariables = productHandle => {
    return async dispatch => {
        const productsWithSessionData = [
            {
                handle: 'ssgrin_ec',
                totalSessions: 12,
                fidelityBenchmark: 80,
            },
            {
                handle: 'ssgrin_k2',
                totalSessions: 10,
                fidelityBenchmark: 80,
            },
            {
                handle: 'ssgrin_35',
                totalSessions: 10,
                fidelityBenchmark: 80,
            },
        ];

        const currentProd = productsWithSessionData.filter(prod => prod.handle === productHandle);

        Fortress.isSuperUser()
            ? dispatch(
                  setSessionDataVariables({
                      handle: 'ssgrin_k2',
                      totalSessions: 10,
                      fidelityBenchmark: 80,
                  })
              )
            : dispatch(setSessionDataVariables(currentProd[0]));
    };
};

// Reducers
export default handleActions(
    {
        [SET_LOADING]: (state, action) => ({
            ...state,
            loading: true,
        }),
        [UNSET_LOADING]: (state, action) => ({
            ...state,
            loading: false,
        }),
        [SET_LEARNER_LIST]: (state, action) => ({
            ...state,
            learnerList: action.payload,
        }),
        [SET_LEARNER]: (state, action) => ({
            ...state,
            learner: action.payload,
        }),

        [SET_GROUP_LIST]: (state, action) => ({
            ...state,
            groupList: action.payload,
        }),
        [SET_PRODUCT_IDS]: (state, action) => ({
            ...state,
            productIds: action.payload,
        }),
        [SET_GROUP]: (state, action) => ({
            ...state,
            group: action.payload,
        }),
        [SET_CURRENT_PRODUCT]: (state, action) => ({
            ...state,
            currentProduct: action.payload,
        }),

        [SET_SESSION_DATA_VARIABLES]: (state, action) => ({
            ...state,
            sessionDataVariables: action.payload,
        }),
        [SET_NO_GROUPS]: (state, action) => ({
            ...state,
            noGroups: true,
        }),
        [UNSET_NO_GROUPS]: (state, action) => ({
            ...state,
            noGroups: false,
        }),
        [SET_NO_LEARNERS]: (state, action) => ({
            ...state,
            noLearners: true,
        }),
        [UNSET_NO_LEARNERS]: (state, action) => ({
            ...state,
            noLearners: false,
        }),

        [SET_WIDGETS_LOADED]: (state, action) => {
            if (!state.widgetsLoaded.includes(action.payload)) {
                return { ...state, widgetsLoaded: [action.payload, ...state.widgetsLoaded] };
            }
            return state;
        },
        [RESET_WIDGETS_LOADED]: (state, action) => ({
            ...state,
            widgetsLoaded: [],
        }),
    },
    initialState
);
