import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'cccisd-react-router';
import widgets from '../../common/widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';
import filterFields from '../../common/filterFields.js';
import style from './style.css';
import query from './metrics.graphql';
import { getDates, getActualFields } from '../../common/helpers.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Checkmark from 'cccisd-icons/checkmark';

const questions = [
    { text: 'Is this student rejected or disliked?', devTag: 'SocialInteractionScreeningItem1' },
    { text: 'Is this student shy?', devTag: 'SocialInteractionScreeningItem2' },
    { text: 'Is this student bullied?', devTag: 'SocialInteractionScreeningItem3' },
    { text: 'Is this student aggressive?', devTag: 'SocialInteractionScreeningItem4' },
    {
        text: 'Is this student bossy towards other children on a regular basis?',
        devTag: 'SocialInteractionScreeningItem5',
    },
    { text: 'Is this student immature?', devTag: 'SocialInteractionScreeningItem6' },
];

const commentTag = 'SISComments';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);
    const { productHandle } = useParams();
    useEffect(() => {
        // run metrics query
        if (noProgress === true) {
            setNoProgress(false);
        }
        (async () => {
            try {
                const queryHandle = productHandle
                    ? productHandle
                    : props.productIds.find(item => item.value === props.filters.product).handle;
                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.filters.participant,
                            productHandle: queryHandle,
                            productId: props.productId || props.filters.product,
                        },
                    })
                    .then(response => {
                        const group = response.data.roles.learner.parentRoles.class;
                        const participant = response.data.roles.learner;

                        const devTags = participant.assignmentProgress.devTags;
                        delete devTags.__typename;

                        const allNull = Object.values(devTags).every(item => item === null);
                        const dateObj = getDates(group);

                        if (!dateObj || allNull) {
                            setNoProgress(true);
                        }

                        setMetrics(devTags);
                        setHeaderProps({
                            participant: participant.fields.participantId,
                            groupLabel: group.fields.groupLabel,
                            instructorList: group.parentRoles.instructorList,
                            dates: dateObj,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };
    const renderTableRows = () => {
        return (
            <>
                {questions.map((item, i) => {
                    const text = item.text;
                    const value = metrics[item.devTag];

                    if (+value === 1) {
                        return (
                            <tr key={i}>
                                <td>{text}</td>
                                <td>
                                    <Checkmark />
                                </td>
                                <td />
                                <td />
                            </tr>
                        );
                    }
                    if (+value === 0) {
                        return (
                            <tr key={i}>
                                <td>{text}</td>
                                <td />
                                <td>
                                    <Checkmark />
                                </td>
                                <td />
                            </tr>
                        );
                    }
                    if (value === null) {
                        return (
                            <tr key={i}>
                                <td>{text}</td>
                                <td />
                                <td />
                                <td />
                            </tr>
                        );
                    }
                    return (
                        <tr key={i}>
                            <td>{text}</td>
                            <td />
                            <td />
                            <td>
                                <Checkmark />
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (noProgress) {
        return <NoGroupRole type="data" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={style.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={style.reportBody}>
                        <h1>Social Interaction Survey Report</h1>
                        <p>
                            The Social Interaction Survey is a six-item teacher-reported behavioral
                            rating questionnaire used to help assess categories of social behaviors
                            considered important to the overall functioning of students. The survey
                            helps providers explore how individual participants develop
                            interactional patterns with other students, as well as how participants
                            are perceived by their peers. While this pre-screening tool is not
                            diagnostic, it can help teachers identify which students may require
                            additional behavioral support. The SIS Report displays teacher-reported
                            observations collected at the screening.
                        </p>
                        <h1>Screening &amp; Referral</h1>
                        <table className={style.reportTable}>
                            <thead>
                                <tr>
                                    <th rowSpan="2" className="leftAlign__sg5vr bottomVert__2HLtU">
                                        Question
                                    </th>
                                    <th className={style.answerHead}>Yes</th>
                                    <th className={style.answerHead}>No</th>
                                    <th className={style.answerHead}>Have not observed</th>
                                </tr>
                                <tr className={style.numHeadRow} />
                            </thead>
                            <tbody>{renderTableRows()}</tbody>
                        </table>
                        <h1>Comments</h1>
                        <div className={style.commentBox}>{metrics[commentTag]}</div>
                    </div>
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    learner: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noLearners: PropTypes.bool,
    currentProduct: PropTypes.number,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
