import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    componentCoverage: {
        show: true,
        title: 'Component Coverage',
        description: 'Covering session objectives.',
    },
    progAdherence: {
        show: true,
        title: 'Program Adherance',
        description: 'Following the program manual.',
    },
    contentSupplemented: {
        show: true,
        title: 'Content Supplemented',
        description: 'Not supplementing manual content.',
    },
    partResponsiveness: {
        show: true,
        title: 'Participant Responsiveness',
        description: 'Participants active in the session.',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show  Callouts?" />
            <hr />
            <Field name="componentCoverage.show" component={CccisdToggle} label="Show Callout 1?" />
            <Field name="componentCoverage.title" component={CccisdInput} label="Title" />
            <Field
                name="componentCoverage.description"
                component={CccisdInput}
                label="Description"
            />
            <hr />
            <Field name="progAdherence.show" component={CccisdToggle} label="Show Callout 2?" />
            <Field name="progAdherence.title" component={CccisdInput} label="Title" />
            <Field name="progAdherence.description" component={CccisdInput} label="Description" />
            <hr />
            <Field
                name="contentSupplemented.show"
                component={CccisdToggle}
                label="Show Callout 3?"
            />
            <Field name="contentSupplemented.title" component={CccisdInput} label="Title" />
            <Field
                name="contentSupplemented.description"
                component={CccisdInput}
                label="Description"
            />
            <hr />
            <Field
                name="partResponsiveness.show"
                component={CccisdToggle}
                label="Show Callout 4?"
            />
            <Field name="partResponsiveness.title" component={CccisdInput} label="Title" />
            <Field
                name="partResponsiveness.description"
                component={CccisdInput}
                label="Description"
            />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
