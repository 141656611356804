import React, { useRef } from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import GroupForm from '../../../../components/Manage/GroupForm';

import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const EditGroupForm = props => {
    const modal = useRef();

    // rows are coming from each role's class_tableMain.graphql
    const { row, loadData } = props;

    const onSubmit = async values => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: row['pawn.pawnId'],
            }),
            {
                pawnHash: row['pawn.pawnHash'],
                productId: values.productId,
                groupLabel: values.groupLabel,
                group: values.school,
                parentPawns: values.groupLeaderIds,
                childPawns: values.participantIds,
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        await loadData();
        notification('Group edited.');
        await modal.current.close();
    };

    const getPawnIds = list => {
        return list && list.length > 0
            ? list.map(l => {
                  return l.pawn.pawnId;
              })
            : [];
    };

    const getGroupLeaderOptions = list => {
        return list.length > 0
            ? list.map(l => {
                  return { value: l.pawn.pawnId, label: l.user.fullNameWithUsername };
              })
            : [];
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <Tooltip title="Edit">
                    <button type="button" className="btn btn-xs btn-success">
                        <IconPencil />
                    </button>
                </Tooltip>
            }
            title={`Edit ${row['fields.groupLabel']}`}
        >
            <GroupForm
                operation="edit"
                onSubmit={onSubmit}
                initialValues={{
                    productId: row['fields.productId'],
                    groupLabel: row['fields.groupLabel'],
                    school: row['ancestorGroups.site.group.groupId'],
                    groupLeaders: getGroupLeaderOptions(row['parentRoles.instructorList']),
                    learners: getPawnIds(row['descendantRoles.learnerList']),
                }}
                {...props}
            />
        </Modal>
    );
};

EditGroupForm.propTypes = {
    loadData: PropTypes.func,
    row: PropTypes.object,
};

export default EditGroupForm;
