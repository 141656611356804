import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, CccisdInput, CccisdSelect } from 'cccisd-formik';
import { client as apollo } from 'cccisd-apollo';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import RequiredFieldLabel from '../RequiredFieldLabel';
import schoolsQuery from './schools.graphql';
import instructorSchoolsQuery from './instructorSchools.graphql';
import productsQuery from './products.graphql';
import { getProducts } from '../../../reducers/products.js';

const fields = window.cccisd && window.cccisd.appDefs.pawn.fields;
const enumFields = fields.filter(field => field.data_type === 'enum');
const enumValues = {};
for (const field of enumFields) {
    enumValues[field.handle] = field.values.map(item => ({
        label: item.name,
        value: item.value,
    }));
}
const Fortress = window.cccisd && window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;
const isUberAdmin = userRole === 'uberadmin';
const isInstructor = userRole === 'instructor';
const pawnId = Fortress.user.acting.id;
const isSiteAdmin = userRole === 'siteAdmin';
const isSelectSchoolRole = userRole === 'orgAdmin' || userRole === 'guAdmin' || userRole === 'supervisor';
const isInstructorOrSiteAdmin = userRole === 'instructor' || userRole === 'siteAdmin';

const LearnerForm = props => {
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    const { productHandle } = useParams();
    const dispatch = useDispatch();

    const { onSubmit, closeModal, initialValues, operation } = props;

    !isUberAdmin &&
        useEffect(() => {
            (async () => {
                dispatch(getProducts(productHandle));
            })();
        }, [dispatch]);

    const { productId } = useSelector(state => state.app.products);

    isUberAdmin
        ? useEffect(() => {
              async function start() {
                  await getAllProducts();
                  if (operation === 'edit') {
                      await getSchools(initialValues.productId);
                  } else {
                      setLoading(false);
                  }
              }
              start();
          }, [])
        : useEffect(() => {
              async function start() {
                  await getSchools(productId);
              }
              start();
          }, []);

    async function getAllProducts() {
        const res = await apollo.query({
            query: productsQuery,
            variables: {},
            fetchPolicy: 'network-only',
        });

        const arr = res.data.products.productList;
        setProducts(arr);
    }

    async function getSchools(prodId) {
        const res = await apollo.query({
            query: isInstructor ? instructorSchoolsQuery : schoolsQuery,
            variables: isInstructor ? { prodId, pawnId } : { prodId },
            fetchPolicy: 'network-only',
        });

        const arrOfSchools = res.data.groups.siteList;

        if (arrOfSchools.length < 1) {
            return <Loader loading type="inline" />;
        }
        setSchools(arrOfSchools);
        setLoading(false);
    }

    const onHandleSubmit = async values => {
        const allClassesAnyProduct = values.otherProductsClasses
            ? [...values.otherProductsClasses, values.group]
            : values.group;

        values.allClassesAnyProduct = JSON.stringify(allClassesAnyProduct);
        await onSubmit(values);
        closeModal();
    };

    const productOptions = items => {
        return items.map(item => {
            return {
                value: item.productId,
                label: item.label,
            };
        });
    };

    const schoolOptions = items => {
        return items.map(({ group: { groupId, label }, ancestorGroups }) => {
            return {
                value: groupId,
                label: `${label} (${ancestorGroups.groupingUnit.group.label})`,
            };
        });
    };

    const initialGroup = schoolId => {
        let selectedSchool = schools.filter(school => school.group.groupId === +schoolId);

        if (selectedSchool.length === 1) {
            selectedSchool = selectedSchool[0];

            if (selectedSchool.childRoles.classList.length < 1) {
                return [];
            }

            const groups = selectedSchool.childRoles.classList;

            const allGroups = groups.map(grp => {
                return {
                    label: grp.fields.groupLabel,
                    value: grp.pawn.pawnId,
                };
            });

            const selectedGroup = groups.filter(grp => grp.pawn.pawnId === initialValues.group);

            const otherGroupsAtSchool = groups
                .filter(grp => grp.pawn.pawnId !== initialValues.group)
                .map(grp => {
                    return {
                        label: grp.fields.groupLabel,
                        value: grp.pawn.pawnId,
                    };
                });

            return selectedGroup.length === 1
                ? [
                      {
                          value: selectedGroup[0].pawn.pawnId,
                          label: selectedGroup[0].fields.groupLabel,
                      },
                      ...otherGroupsAtSchool,
                  ]
                : [...allGroups];
        }
        return [];
    };

    const getGroups = schoolId => {
        let selectedSchool = schools.filter(school => school.group.groupId === +schoolId);

        if (selectedSchool.length === 1) {
            selectedSchool = selectedSchool[0];

            if (selectedSchool.childRoles.classList.length < 1) {
                return [];
            }

            return selectedSchool.childRoles.classList.map(grp => {
                return {
                    key: grp.pawn.pawnId,
                    value: grp.pawn.pawnId,
                    label: `${grp.fields.groupLabel}`,
                };
            });
        }
        return <div>Please select a School first</div>;
    };

    const validate = values => {
        const errors = {};
        const regexPhone = /^[0-9]{10}$/;
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!values.productId) {
            errors.productId = 'Product is required.';
        }
        if (!values.participantId) {
            errors.participantId = 'ID Number is required.';
        }
        if (!values.school) {
            errors.school = 'School is required.';
        }
        if (values.teacherEmail && !regexEmail.test(values.teacherEmail)) {
            errors.teacherEmail = 'Please input a valid email.';
        }
        if (values.teacherPhone && !regexPhone.test(values.teacherPhone)) {
            errors.teacherPhone = 'Please input 10 digits.';
        }
        if (values.parentEmail && !regexEmail.test(values.parentEmail)) {
            errors.parentEmail = 'Please input a valid email.';
        }
        if (values.parentPhone && !regexPhone.test(values.parentPhone)) {
            errors.parentPhone = 'Please input 10 digits.';
        }
        if (!values.grade) {
            errors.grade = 'Grade is required.';
        }

        return errors;
    };

    // We don't need the group leaders to select a product, a school, or themselves as a group leader, so we set those initial values and hide the fields from their view
    const instructorSchool = {
        productId,
        school: schools.length === 1 ? schools[0].group.groupId : '',
    };

    const siteAdminSchool = {
        productId,
        school: schools.length === 1 ? schools[0].group.groupId : '',
    };

    const selectSchoolRoleSchool = {
        productId,
    };

    const instructorInitialValues = { ...initialValues, ...instructorSchool };
    const siteAdminInitialValues = { ...initialValues, ...siteAdminSchool };
    const selectSchoolRoleInitialValues = { ...initialValues, ...selectSchoolRoleSchool };

    const getInitialValues = () => {
        if (isInstructor) return instructorInitialValues;
        if (isSiteAdmin) return siteAdminInitialValues;
        if (isSelectSchoolRole) return selectSchoolRoleInitialValues;
        return initialValues;
    };

    return loading ? (
        <Loader loading />
    ) : (
        <Formik onSubmit={e => onHandleSubmit(e)} validate={e => validate(e)} initialValues={getInitialValues()}>
            {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                    {isUberAdmin && (
                        <Field
                            name="productId"
                            component={CccisdSelect}
                            label={<RequiredFieldLabel isRequired>Product:</RequiredFieldLabel>}
                            options={[
                                {
                                    value: 1000,
                                    label: '-- Please select a Product --',
                                },
                                ...productOptions(products),
                            ]}
                            onChange={event => {
                                const product = event.target.value;
                                getSchools(product);
                                setFieldValue('productId', +product);
                            }}
                        />
                    )}

                    <Field name="participantName" component={CccisdInput} label="Name:" />
                    <Field
                        name="participantId"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>ID Number:</RequiredFieldLabel>}
                    />
                    <Field
                        name="gender"
                        component={CccisdSelect}
                        label="Gender:"
                        options={[
                            {
                                value: '',
                                label: '-- Please select the gender with which the participant identifies --',
                            },
                            ...enumValues.gender,
                        ]}
                    />
                    <Field
                        name="grade"
                        component={CccisdSelect}
                        label={<RequiredFieldLabel isRequired>Grade:</RequiredFieldLabel>}
                        options={[
                            {
                                value: '',
                                label: "-- Please select the participant's grade level --",
                            },
                            ...enumValues.grade,
                        ]}
                    />
                    <Field name="teacherName" component={CccisdInput} label="Teacher Name or ID:" />
                    <Field name="teacherEmail" component={CccisdInput} label="Teacher Email:" />
                    <Field name="teacherPhone" component={CccisdInput} label="Teacher Phone:" />
                    <Field name="parentEmail" component={CccisdInput} label="Parent Email:" />
                    <Field name="parentPhone" component={CccisdInput} label="Parent Phone:" />

                    {/* Only show the school dropdown option if the user is higher up the hierarchy than a Group Leader. If a group leader, we know the school is the first one coming back from the query and set as this field's value ~line 48  */}
                    {!isInstructorOrSiteAdmin && values.productId ? (
                        <>
                            <Field
                                name="school"
                                component={CccisdSelect}
                                label={<RequiredFieldLabel isRequired>School:</RequiredFieldLabel>}
                                options={[
                                    {
                                        value: '',
                                        label: '-- Please select a School --',
                                    },
                                    ...schoolOptions(schools),
                                ]}
                                onChange={event => {
                                    const value = event.target.value;
                                    setFieldValue('school', +value);
                                }}
                            />
                        </>
                    ) : null}

                    {/* wait til a School is selected before rendering groups at that school, with the same productId */}
                    {values.school && (
                        <Field
                            name="group"
                            label="Group:"
                            component={CccisdSelect}
                            options={
                                operation === 'edit'
                                    ? [
                                          {
                                              value: 0,
                                              label: '-- No Group for Now --',
                                          },
                                          ...initialGroup(values.school),
                                      ]
                                    : [
                                          {
                                              value: 0,
                                              label: '-- No Group for Now --',
                                          },
                                          ...getGroups(values.school),
                                      ]
                            }
                            onChange={event => {
                                const value = event.target.value;
                                setFieldValue('group', +value);
                            }}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1em',
                        }}
                    >
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

LearnerForm.propTypes = {
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    operation: PropTypes.string,
    productLabel: PropTypes.string,
    initialValues: PropTypes.object,
};

export default LearnerForm;
