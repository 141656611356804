import reportFilters from './reducers/reportFilters';
import products from './reducers/products';
import userAccess from './reducers/userAccess';

export default {
    reportFilters: {
        reducer: reportFilters,
    },
    products: {
        reducer: products,
    },
    userAccess: {
        reducer: userAccess,
    },
};
