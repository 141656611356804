import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import _format from 'date-fns/format';
import { useParams } from 'cccisd-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionDataVariables } from '../../../../../reducers/reportFilters';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import filterFields from '../../common/groupFilterField.js';
import { getActualFields } from '../../common/helpers.js';
import reportStyle from '../../common/reportStyle.css';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';

import ecMetrics from './ecMetrics.graphql';
import k2and35metrics from './metrics.graphql';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const { comments, titleAndDescription } = props.widgets;

    const { productHandle } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables(productHandle));
        })();
    }, [dispatch]);

    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);
    const commentsQuery = useSelector(state => state.app.reportFilters.widgetsLoaded);

    const k2and35 = {
        query: k2and35metrics,
        columnNames: [
            {
                id: 'Participant ID',
            },
            {
                id: 'Sessions Attended',
            },
            {
                id: 'Homework Completed',
            },
            {
                id: 'Participation',
            },
            {
                id: 'Brought Workbook',
            },
        ],
    };

    const ec = {
        query: ecMetrics,
        columnNames: [
            {
                id: 'Participant ID',
            },
            {
                id: 'Sessions Attended',
            },
            {
                id: 'Participation',
            },
            {
                id: 'Behavior Problems',
            },
            {
                id: 'Needed Redirection',
            },
            {
                id: 'Demonstrated Skills',
            },
        ],
    };

    useEffect(() => {
        // run metrics query
        if (noProgress === true) {
            setNoProgress(false);
        }
        (async () => {
            try {
                const queryHandle = productHandle
                    ? productHandle
                    : props.productIds.find(item => item.value === props.filters.product).handle;

                await client
                    .query({
                        query: productHandle === 'ssgrin_ec' ? ec.query : k2and35.query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.group,
                            productHandle: queryHandle,
                        },
                    })
                    .then(response => {
                        const group = response.data.roles.class;

                        const participantList = group.childRoles.learnerList;

                        const sessionDataDates = group.sessionDataDates;
                        const startDate = sessionDataDates.earliestCompleted !== null;
                        const endDate = sessionDataDates.latestCompleted !== null;

                        const firstDate = startDate
                            ? JSON.parse(group.sessionDataDates.earliestCompleted).sessionDate
                            : null;

                        const lastDate = endDate
                            ? JSON.parse(group.sessionDataDates.latestCompleted).sessionDate
                            : null;

                        const dateObj = {
                            startDate: startDate ? _format(new Date(firstDate), 'MM/dd/yyyy') : '',
                            endDate: endDate ? _format(new Date(lastDate), 'MM/dd/yyyy') : '',
                            now: _format(Date.now(), 'MM/dd/yyyy'),
                        };

                        if (!startDate || participantList.length < 1) {
                            setNoProgress(true);
                        }

                        setMetrics(participantList);

                        setHeaderProps({
                            groupLabel: group.fields.groupLabel,
                            instructorList: group.parentRoles.instructorList,
                            dates: dateObj,
                            groupReport: true,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters, commentsQuery]);

    const renderTableRows = () => {
        return (
            <>
                {metrics.map(learner => {
                    return (
                        <tr key={learner.pawn.pawnId}>
                            <td className={reportStyle.numberBox}>{learner.fields.participantId}</td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.yesAttendance.frequency
                                        ? learner.yesAttendance.frequency[0].valueCount
                                        : 0}{' '}
                                    / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.yesHomework.frequency ? learner.yesHomework.frequency[0].valueCount : 0} /{' '}
                                    {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.yesParticipate.frequency
                                        ? learner.yesParticipate.frequency[0].valueCount
                                        : 0}{' '}
                                    / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.yesWorkbook.frequency ? learner.yesWorkbook.frequency[0].valueCount : 0} /{' '}
                                    {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    };

    const renderECTableRows = () => {
        return (
            <>
                {metrics.map(learner => {
                    return (
                        <tr key={learner.pawn.pawnId}>
                            <td className={reportStyle.numberBox}>{learner.fields.participantId}</td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.yesAttendance.frequency
                                        ? learner.yesAttendance.frequency[0].valueCount
                                        : 0}{' '}
                                    / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.yesParticipate.frequency
                                        ? learner.yesParticipate.frequency[0].valueCount
                                        : 0}{' '}
                                    / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.behavior.frequency ? learner.behavior.frequency[0].valueCount : 0} /{' '}
                                    {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.redirection.frequency ? learner.redirection.frequency[0].valueCount : 0} /{' '}
                                    {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {learner.skills.frequency ? learner.skills.frequency[0].valueCount : 0} /{' '}
                                    {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (noProgress) {
        return <NoGroupRole type="groupData" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    return (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={reportStyle.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={reportStyle.reportBody}>
                        {titleAndDescription}
                        <table className={reportStyle.reportTable}>
                            <thead>
                                <tr>
                                    {productHandle === 'ssgrin_ec'
                                        ? ec.columnNames.map(col => {
                                              return <th key={col.id}>{col.id}</th>;
                                          })
                                        : k2and35.columnNames.map(col => {
                                              return <th key={col.id}>{col.id}</th>;
                                          })}
                                </tr>
                            </thead>
                            <tbody>{productHandle === 'ssgrin_ec' ? renderECTableRows() : renderTableRows()}</tbody>
                        </table>
                    </div>
                </div>
                <hr />
                {comments}
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    widgetsLoaded: PropTypes.array,
    loading: PropTypes.bool,
    learner: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noLearners: PropTypes.bool,
    currentProduct: PropTypes.number,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
