import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'cccisd-react-router';
import { NotFound } from 'cccisd-laravel-boilerplate';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import { LicensePrompt } from 'cccisd-laravel-licensing';
import Loader from 'cccisd-loader';

import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../reducers/products';
import { getLicenseStatus, getTrainingStatus } from '../../reducers/userAccess';

import ProductHeader from './components/ProductHeader';
import Footer from '../../components/Footer';
import style from './style.css';

import TrainingPrompt from './components/TrainingPrompt';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const fullInterventions = ['ssgrin_ec', 'ssgrin_k2', 'ssgrin_35'];

export default function ProductLayout(props) {
    const [routesList, setRoutesList] = useState([]);

    const { productHandle } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            dispatch(getProducts(productHandle));
            dispatch(getLicenseStatus({ productHandle, pawnId }));
            dispatch(getTrainingStatus({ productHandle, pawnId }));
        })();
    }, [dispatch]);

    // See if its one of the Grins
    const isFullIntervention = fullInterventions.includes(productHandle);

    useEffect(() => {
        // filter out route for grin or non grin
        if (!isFullIntervention) {
            const partialInterventionRoutes = AppDefs.routes
                .filter(r => r.handle !== 'manageFull')
                .filter(r => r.handle !== 'dashboardEC');

            return setRoutesList(partialInterventionRoutes);
        }

        // ec needs it's own dashboard
        if (isFullIntervention && productHandle === 'ssgrin_ec') {
            const ecRoutes = AppDefs.routes
                .filter(r => r.handle !== 'managePartial')
                .filter(r => r.handle !== 'dashboard');

            return setRoutesList(ecRoutes);
        }

        // k2 and 35 grin can share the same dashboard
        const fullInterventionRoutes = AppDefs.routes
            .filter(r => r.handle !== 'managePartial')
            .filter(r => r.handle !== 'dashboardEC');

        setRoutesList(fullInterventionRoutes);
    }, [productHandle]);

    const { productId, productLabel } = useSelector(state => state.app.products);
    const { licensed, loading } = useSelector(state => state.app.userAccess);

    if (productHandle === ':productHandle') {
        return (
            <div className={`container ${style.body}`}>
                <Redirect to="/" />
            </div>
        );
    }

    if (loading) {
        return <Loader loading />;
    }

    if (productId && !licensed) {
        return <LicensePrompt productId={productId} />;
    }

    return (
        <div className={style.background}>
            <div className={style.wrapper}>
                <ProductHeader
                    className={props.className}
                    product={{
                        label: productLabel ? productLabel : null,
                    }}
                >
                    <SecondaryNav
                        navs={AppDefs.navs.filter(n => n.handle === productHandle)}
                        routes={routesList.map(r => {
                            // replace manageFull / managePartial with manage for navItem match
                            if (r.handle.includes('manage')) {
                                return {
                                    ...r,
                                    handle: 'manage',
                                    url: r.url.replace(':productHandle', productHandle),
                                };
                            }
                            // replace dashboard / dashboardEC with dashboard for navItem match
                            if (r.handle.includes('dashboard')) {
                                return {
                                    ...r,
                                    handle: 'dashboard',
                                    url: r.url.replace(':productHandle', productHandle),
                                };
                            }
                            return {
                                ...r,
                                url: r.url.replace(':productHandle', productHandle),
                            };
                        })}
                        render={({ className, navItems, components: { Item } }) => {
                            const NavItems = () => {
                                return (
                                    <>
                                        {navItems.map(item => {
                                            const source = item.render.params.activeRegex.source;

                                            const newRegEx = new RegExp(
                                                source.replace(':productHandle', productHandle)
                                            );
                                            return (
                                                <Item
                                                    key={item.handle}
                                                    item={item}
                                                    active={
                                                        newRegEx.test(props.children.props.location.pathname) &&
                                                        routesList.length > 0 &&
                                                        routesList.some(r => r.url === props.children.props.match.path)
                                                    }
                                                    {...props}
                                                />
                                            );
                                        })}
                                    </>
                                );
                            };
                            return (
                                <ul className={`nav navbar-nav container ${style.secondaryNav}`}>
                                    <NavItems />
                                </ul>
                            );
                        }}
                    />
                </ProductHeader>

                <div className={`container ${style.body}`}>
                    {loading ? (
                        <Loader loading />
                    ) : (
                        <>{licensed && licensed ? <TrainingPrompt>{props.children}</TrainingPrompt> : <NotFound />}</>
                    )}
                </div>

                <div className={style.footer}>
                    <Footer className={props.className} />
                </div>
            </div>
        </div>
    );
}
