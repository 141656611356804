import { reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import filterFields from '../../common/filterFields.js';
import { getActualSelectedFields } from '../../common/helpers.js';

export const initialValues = {
    filterFields: getActualSelectedFields(filterFields),
    otherFilterOptions: [],
    widgets: [],
};

const Component = () => {
    return null;
};

export default reportTemplateBuilder({ filterFields, widgets: [] })(Component);
