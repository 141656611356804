import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDeploymentIds, setSelectedDeploymentIds } from '../reducers/admin.js';
import { getCurrentAssignmentDeployments, getCurrentDeploymentIds } from '../selectors/admin.js';

export default function useEnsureDeploymentIds() {
    const dispatch = useDispatch();

    const deployments = useSelector(state => getCurrentAssignmentDeployments(state.assignment.admin));

    const deploymentIds = useSelector(state => getCurrentDeploymentIds(state.assignment.admin));

    useEffect(() => {
        function ensureDeployment() {
            if (!deploymentIds.length && deployments && deployments.length) {
                dispatch(setCurrentDeploymentIds([deployments[0].deploymentId]));
                dispatch(setSelectedDeploymentIds([deployments[0].deploymentId]));
            }
        }

        ensureDeployment();
    }, [deployments]);
}
