import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    issues: {
        show: true,
        title: 'Issues',
        description: "View the participant's progress toward targeted issues and behaviors.",
    },
    progress: {
        show: true,
        title: 'Progress Areas',
        description: "View the participant's development in targeted progress areas.",
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Main Charts?" /> <hr />
            <Field name="issues.show" component={CccisdToggle} label="Show Main Chart 1?" />
            <Field name="issues.title" component={CccisdInput} label="Title" />
            <Field name="issues.description" component={CccisdInput} label="Description" />
            <hr />
            <Field name="progress.show" component={CccisdToggle} label="Show Main Chart 2?" />
            <Field name="progress.title" component={CccisdInput} label="Title" />
            <Field name="progress.description" component={CccisdInput} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
