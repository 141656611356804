import React from 'react';
import { convertScore } from '../../vendor/reports/reportTemplates/common/helpers.js';

import IconSmile from 'cccisd-icons/smile';
import IconSad from 'cccisd-icons/wondering';

const ComponentAvg = settings => {
    return ({ row }) => {
        const scoreId = settings.scoreId;

        const renderScoreAndSmiley = score => {
            if (score === '0') {
                return null;
            }

            let icon = <IconSmile />;
            let color = '#3673b6';

            if (+score < 80) {
                icon = <IconSad />;
                color = '#d03916';
            }

            if (score === 0) {
                return (
                    <>
                        <span style={{ color }}>{icon} </span>0%
                    </>
                );
            }

            return score ? (
                <>
                    <span style={{ color }}>{icon} </span>
                    {`${typeof score === 'string' ? score : score.toFixed(0)}%`}
                </>
            ) : null;
        };

        const averageScore = () => {
            switch (scoreId) {
                case 'avgComponentCoverageScore':
                    return renderScoreAndSmiley(convertScore(row['assignmentProgressSummary.componentCoverageScore']));
                case 'avgProgramAdherenceScore':
                    return renderScoreAndSmiley(row['assignmentProgressSummary.avgProgramAdherenceScore']);
                case 'avgContentSupplementedScore':
                    return renderScoreAndSmiley(row['assignmentProgressSummary.avgContentSupplementedScore']);
                case 'participantResponsiveness':
                    return renderScoreAndSmiley(row['assignmentProgressSummary.avgParticipantResponsivenessScore']);

                default:
                    return null;
            }
        };

        return <div style={{ whiteSpace: 'nowrap' }}>{averageScore()}</div>;
    };
};
export default ComponentAvg;
