import React, { useRef, useEffect } from 'react';
import Modal from 'cccisd-modal';
import { useParams } from 'cccisd-react-router';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';

import GroupForm from '../../../../components/Manage/GroupForm';
import { getProducts } from '../../../../reducers/products';
import { useDispatch, useSelector } from 'react-redux';

import IconPlus from 'cccisd-icons/plus-circle2';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;
const isUberAdmin = userRole === 'uberadmin';

const AddGroupForm = props => {
    const { productHandle } = useParams();
    const modal = useRef();
    const dispatch = useDispatch();

    !isUberAdmin &&
        useEffect(() => {
            (async () => {
                dispatch(getProducts(productHandle));
            })();
        }, [dispatch]);

    const { productId, productLabel } = useSelector(state => state.app.products);

    const onSubmit = async values => {
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            role: 'class',
            productId: isUberAdmin ? values.productId : productId,
            groupLabel: values.groupLabel,
            group: values.school,
            parentPawns: values.groupLeaderIds,
            childPawns: values.participantIds,
        });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }

        await props.loadData();
        await modal.current.close();
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className="btn btn-primary">
                    <span>
                        <IconPlus spaceRight />
                        Add Group
                    </span>
                </button>
            }
            title="Add New Group"
        >
            <GroupForm
                operation="create"
                productId={productId}
                productLabel={productLabel}
                onSubmit={onSubmit}
                initialValues={{ learners: [], groupLeaders: [], school: null }}
            />
        </Modal>
    );
};

AddGroupForm.propTypes = {
    loadData: PropTypes.func,
};

export default AddGroupForm;
