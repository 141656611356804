import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';
import _get from 'lodash/get';

import detailChartsQuery from './detailCharts.graphql';
import { calcAvg, convertScore } from '../../../../common/helpers.js';
import { getSessionDataVariables } from '../../../../../../../reducers/reportFilters';
import DetailChart from './DetailChart';

const Fortress = window.cccisd && window.cccisd.fortress;
const instructorPawnId = Fortress.user.acting.id;

const Component = props => {
    const [metrics, setMetrics] = useState([]);
    const [loading, setLoading] = useState(true);

    const { show } = props.settings;
    const { getAllGroupsData } = props;

    const dispatch = useDispatch();
    const { productHandle } = useParams();
    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);
    const { productId } = useSelector(state => state.app.products);

    // const queryHandle = productHandle
    // ? productHandle
    // : props.productIds.find(item => item.value === props.filters.product).handle;

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables(productHandle));

            try {
                await client
                    .query({
                        query: detailChartsQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            instructorPawnId,
                            productId,
                        },
                    })
                    .then(response => {
                        const groupData = response.data.roles.classList;

                        // make an array the length of however many session data sessions there are
                        const sessDataArray = (length, startAt, devTag) => {
                            return [...Array(length).keys()].map(i => {
                                return {
                                    // give the chart's x axis the session number
                                    x: i + startAt,

                                    // the y axis is the average of that sessions score, for that devTag
                                    y: getAllGroupsData(groupData, `sess${i + startAt}`, devTag),
                                };
                            });
                        };

                        setMetrics([
                            {
                                id: 'componentCoverage',
                                settings: {
                                    ...props.settings.componentCoverage,
                                },
                                convertedData: [
                                    {
                                        id: 'componentCoverage',
                                        color: 'hsl(25, 70%, 50%)',
                                        data: sessDataArray(
                                            sessionDataVariables.totalSessions,
                                            1,
                                            'ComponentCoverageScore'
                                        ),
                                    },
                                ],
                            },
                            {
                                id: 'progAdherence',
                                settings: {
                                    ...props.settings.progAdherence,
                                },
                                convertedData: [
                                    {
                                        id: 'progAdherence',
                                        color: 'hsl(25, 70%, 50%)',
                                        data: sessDataArray(
                                            sessionDataVariables.totalSessions,
                                            1,
                                            'ProgramAdherenceScore'
                                        ),
                                    },
                                ],
                            },
                            {
                                id: 'contentSupplemented',
                                settings: {
                                    ...props.settings.contentSupplemented,
                                },
                                convertedData: [
                                    {
                                        id: 'contentSupplemented',
                                        color: 'hsl(25, 70%, 50%)',
                                        data: sessDataArray(
                                            sessionDataVariables.totalSessions,
                                            1,
                                            'ContentSupplementedScore'
                                        ),
                                    },
                                ],
                            },
                            {
                                id: 'partResponsiveness',
                                settings: {
                                    ...props.settings.partResponsiveness,
                                },
                                convertedData: [
                                    {
                                        id: 'partResponsiveness',
                                        color: 'hsl(25, 70%, 50%)',
                                        data: sessDataArray(
                                            sessionDataVariables.totalSessions,
                                            1,
                                            'ParticipantResponsivenessScore'
                                        ),
                                    },
                                ],
                            },
                        ]);

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.settings]);

    return loading ? (
        <Loader type="inline" />
    ) : (
        <>
            {show && (
                <div>
                    {metrics.map(metric => {
                        return (
                            <div key={metric.id}>
                                <DetailChart
                                    handle={metric.id}
                                    settings={metric.settings}
                                    sessionDataVariables={sessionDataVariables}
                                    data={metric.convertedData}
                                    reachedBenchmark={metric.reachedBenchmark}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    getAllGroupsData: PropTypes.func,
    benchmarkCounter: PropTypes.func,
};

Component.defaultProps = {
    filters: {},
    getAllGroupsData: (res, sessNumber, chart) => {
        const bySession = res && res.length > 0 ? res.map(el => _get(el, sessNumber)) : [];

        const byDevTag = bySession
            .map(session => _get(session.devTags, chart))
            .filter(score => score !== null)
            .map(score => +score);

        let scores = [];

        if (chart === 'ComponentCoverageScore') {
            scores = byDevTag.map(sc => Number(convertScore(sc)));

            return scores.length > 0 ? calcAvg(scores).toFixed(0) : null;
        }

        scores = byDevTag;

        return scores.length > 0 ? calcAvg(scores).toFixed(0) : null;
    },
};

export default Component;
