import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import calloutsQuery from './callouts.graphql';
import Callout from '../Callout';
import { convertScore } from '../../../../common/helpers.js';
import { getSessionDataVariables } from '../../../../../../../reducers/reportFilters';

const Component = props => {
    const [metrics, setMetrics] = useState({});
    const [loading, setLoading] = useState(true);

    const { show } = props.settings;
    const { filters } = props;

    const dispatch = useDispatch();
    const { productHandle } = useParams();
    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);
    const { productIds } = useSelector(state => state.app.reportFilters);

    const prodHandle = productHandle ? productHandle : productIds.find(prod => prod.value === filters.product).handle;

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables(prodHandle));

            try {
                await client
                    .query({
                        query: calloutsQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +filters.group,
                            productHandle: prodHandle,
                        },
                    })
                    .then(response => {
                        const group = response.data.roles.class;

                        const sessionSummary = group.summary;

                        setMetrics([
                            {
                                id: 'componentCoverage',
                                score: convertScore(sessionSummary.compCoverage),
                                settings: { ...props.settings.componentCoverage },
                            },
                            {
                                id: 'progAdherence',
                                score: convertScore(sessionSummary.progAdherence),
                                settings: { ...props.settings.progAdherence },
                            },
                            {
                                id: 'contentSupplemented',
                                score: convertScore(sessionSummary.contentSupplemented),
                                reachedBenchmark: '4',
                                settings: { ...props.settings.contentSupplemented },
                            },
                            {
                                id: 'partResponsiveness',
                                score: convertScore(sessionSummary.partResponsiveness),
                                settings: { ...props.settings.partResponsiveness },
                            },
                        ]);

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters, props.settings]);

    return loading && loading ? (
        <Loader type="inline" />
    ) : (
        <>
            {show && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '2em',
                        marginBottom: '2em',
                        padding: '1em',
                    }}
                >
                    {metrics.map(metric => {
                        return (
                            <div className="col-sm-3" key={metric.id}>
                                <Callout
                                    handle={metric.id}
                                    data={metric.score}
                                    settings={metric.settings}
                                    benchmark={sessionDataVariables.fidelityBenchmark}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
