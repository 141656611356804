import React from 'react';
import { useParams } from 'cccisd-react-router';
import { ResourceCenter } from 'cccisd-laravel-resources';

// Resource Center component for a specific product
// Not to be confused with the "Products" RC or the 3CISD RC
export default function ProductResourceCenter() {
    const { productHandle } = useParams();
    return <ResourceCenter handle={productHandle} />;
}
