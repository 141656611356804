import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    mainChart: {
        show: true,
        description:
            'Note: If a child is absent, a progress score for that week will not appear on the graph.',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Main Charts?" /> <hr />
            <Field name="mainChart.show" component={CccisdToggle} label="Show Main Chart 1?" />
            <Field name="mainChart.description" component={CccisdInput} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
