import React, { useEffect, useState } from 'react';
import axios from 'cccisd-axios';
import Modal from 'cccisd-modal';
import Certificate from '../Certificate';

const Boilerplate = window.cccisd.boilerplate;
const CertificateRender = props => {
    const { row, renderCertificate } = props;
    const unflattenedRow = row['assignmentProgress.completed'] && unflatten(row);

    const [backgroundUrls, setBackgroundUrls] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getBackgroundUrl(bgId, returnObj) {
            const baseUrl = Boilerplate.route('api.resources.file.index');
            const response = await axios.get(`${baseUrl}?id=${bgId}`);
            if (response.status === 200 && response.data.status === 'success') {
                returnObj[bgId] = response.data.data[0].published_url;
            }
        }

        async function loadData() {
            const certificates =
                unflattenedRow.assignmentProgress.assignment.settings.navigationSettings.certificate.certificates;
            const loadingPromises = [];
            const startingBackgroundIds = certificates.filter(c => c.backgroundId).map(c => c.backgroundId);
            const localBackgroundUrls = {};
            if (Object.keys(backgroundUrls).length === 0 && startingBackgroundIds.length > 0) {
                const backgroundIdsPromised = [];
                startingBackgroundIds.forEach(bgId => {
                    if (!backgroundIdsPromised.includes(bgId)) {
                        backgroundIdsPromised.push(bgId);
                        loadingPromises.push(getBackgroundUrl(bgId, localBackgroundUrls));
                    }
                });
            }

            await Promise.all(loadingPromises);

            setBackgroundUrls(localBackgroundUrls);

            setLoading(false);
        }
        if (unflattenedRow) {
            loadData();
        }
    }, []);

    const isAnon = props.deploymentSettings.respondentConfig === 'anonymous';

    const renderDefault = () => {
        const navigationSettings = isAnon
            ? unflattenedRow.assignment.settings.navigationSettings
            : unflattenedRow.assignmentProgress.assignment.settings.navigationSettings;

        return <Certificate {...props} mediatorProps={navigationSettings} settings={navigationSettings.certificate} />;
    };

    if (!loading && unflattenedRow) {
        return (
            <Modal
                trigger={
                    <button type="button" className="btn btn-primary">
                        View Certificate
                    </button>
                }
                title="Certificate"
            >
                {renderCertificate ? (
                    <>
                        {renderCertificate({
                            props,
                            renderDefault,
                            backgroundUrls,
                            unflattenedRow,
                        })}
                    </>
                ) : (
                    <>{renderDefault()}</>
                )}
            </Modal>
        );
    }
    return null;
};

const unflatten = data => {
    var result = {};
    try {
        /* eslint-disable */
        for (let i in data) {
            const keys = i.split('.');
            keys.reduce(function (r, e, j) {
                return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : []);
            }, result);
        }

        return result;
    } catch (e) {
        console.error(e);
    }
};

export default CertificateRender;

/* <Certificate
    {...props}
    deploymentId={flowList.length ? flowList[0].responseSet : ''}
    hide={
        !isCourseComplete ||
        !isTabMediatorComplete('finalTest') ||
        !isTabMediatorComplete('courseEval')
    }
    latestFinalTestData={latestFinalTestData}
    settings={certificate}
/> */

// Certificate Props:

// actingPawnHash -> from table row,
// actingPawnId -> from table row,
// basePath - used to reset course progress NOT SURE IF NEEDED,
// deploymentId - tableState variables.deploymentId,
// deploymentSettings -> tableProps currentDeployments[0].settings,
// hide,
// language tableProps -> language,
// latestFinalTestData - used to get dates,
// mediatorProps - used by reset and transcript,
// parentDeploymentId,
// relatedDeploymentId,
// settings -  backgroundId: settings.backgroundId,
// courseName: settings.courseName,
// freeText: settings.freeText,
