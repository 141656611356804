import React from 'react';
import ReportCenter from 'cccisd-report-center';
import { useParams } from 'cccisd-react-router';

const MarketplaceReportCenter = () => {
    const { productHandle } = useParams();

    return (
        <>
            {productHandle === 'ssgrin_ec' ? (
                <ReportCenter handle="ssgrin_ec_instructor_report_center" />
            ) : (
                <ReportCenter handle="ssgrin_k235_instructor_report_center" />
            )}
        </>
    );
};

export default MarketplaceReportCenter;
