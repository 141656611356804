import { handleActions, createAction } from 'redux-actions';
import { client } from 'cccisd-apollo';
import { productTraining } from '../vendor/reports/reportTemplates/common/helpers.js';

import licenseQuery from '../graphql/licenseQuery.graphql';
import trainingQuery from '../graphql/trainingQuery.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;

export const initialState = {
    loading: true,
    licensed: true,
    trained: false,
};

// Actions
const SET_LOADING = 'app/report/SET_LOADING';
const SET_LICENSED = 'app/report/SET_LICENSED';
const SET_TRAINED = 'app/report/SET_TRAINED';

// Action Creators
export const setLoading = createAction(SET_LOADING);
export const setLicensed = createAction(SET_LICENSED);
export const setTrained = createAction(SET_TRAINED);

export const getLicenseStatus = ({ productHandle, pawnId }) => {
    return async dispatch => {
        try {
            const response = await client.query({
                query: licenseQuery,
                variables: {
                    productHandle,
                    pawnId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });
            const { product } = response.data.products;

            const hasAccess =
                Fortress.isSuperUser() ||
                (product.ownedLicense && product.ownedLicense.licenseId
                    ? !product.ownedLicense.isExpired
                    : false);

            dispatch(setLicensed(hasAccess));
            dispatch(setLoading(false));
        } catch (e) {
            console.error(e);
        }
    };
};

export const getTrainingStatus = ({ productHandle, pawnId }) => {
    return async dispatch => {
        try {
            const response = await client.query({
                query: trainingQuery,
                variables: {
                    productHandle,
                    pawnId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            });
            const trainingResponse = response.data.flows.assignmentProgress;
            const passed = trainingResponse.devTags.PassTraining === '1';

            const prevTrainingResponse = response.data.roles.anyRole.fields.trained;

            const prodObject = JSON.parse(prevTrainingResponse);

            const previouslyTrained = productTraining(prodObject, productHandle);

            const trained = previouslyTrained === true || passed === true;

            dispatch(setTrained(trained));
            dispatch(setLoading(false));
        } catch (e) {
            console.error(e);
        }
    };
};

// Reducers
export default handleActions(
    {
        [SET_LOADING]: (state, action) => ({
            ...state,
            loading: action.payload,
        }),

        [SET_LICENSED]: (state, action) => ({
            ...state,
            licensed: action.payload,
        }),

        [SET_TRAINED]: (state, action) => ({
            ...state,
            trained: action.payload,
        }),
    },
    initialState
);
