import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import { OutcomesDataEntry } from 'cccisd-laravel-assignment';
import { useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import classQuery from './groupList.graphql';
import tableQuery from './tableQuery.graphql';
import questQuery from './questId.graphql';

const Fortress = window.cccisd.fortress;

function ProgressData() {
    const [classList, setClassList] = useState([]);
    const [questId, setQuestId] = useState(null);
    const [loading, setLoading] = useState(true);

    const { productId } = useSelector(state => state.app.products);
    const { productHandle } = useParams();

    useEffect(() => {
        async function start() {
            await getQuestProject();
            await formatClassList();
        }
        start();
    }, []);

    async function getQuestProject() {
        const res = await apollo.query({
            query: questQuery,
            fetchPolicy: 'network-only',
            variables: {
                productHandle,
            },
        });

        setQuestId(res.data.flows.deployment.assignment.groupId);
    }

    async function getClassList() {
        const pawnId = Fortress.user.acting.id;

        const res = await apollo.query({
            query: classQuery,
            fetchPolicy: 'network-only',
            variables: { productId, pawnId },
        });

        return res.data;
    }

    async function formatClassList() {
        const data = await getClassList();
        const formattedList = formatList(data.roles.classList);

        setClassList(formattedList);

        setLoading(false);
    }

    function formatList(list) {
        return list.map(({ pawn: { pawnId, pawnHash }, fields: { groupLabel } }) => {
            return {
                groupId: pawnId,
                label: groupLabel,
                pawnId,
                pawnHash,
                flowProps: {},
            };
        });
    }

    const addFormattingAndUserRows = (arr, flowVar) => {
        if (arr && arr.length > 0 && flowVar) {
            const initialValues = {
                [flowVar]: {
                    userRows: arr.map((el, index) => {
                        return { id: index, label: el };
                    }),
                },
            };

            return initialValues;
        }
        return [];
    };

    return (
        !loading && (
            <OutcomesDataEntry
                questprojectIds={[questId]}
                groups={classList}
                title="Progress Reporting"
                description="Enter progress data gathered during group sessions."
                tableColumns={[
                    {
                        name: 'fields.participantId',
                        label: 'ID',
                        sort: true,
                        filter: true,
                    },
                    {
                        name: 'fields.participantName',
                        label: 'Name',
                        sort: true,
                        filter: true,
                    },
                ]}
                tableFields={['participantId', 'participantName']}
                modalTitleField="participantId"
                tableQuery={tableQuery}
                formatDefaultValues={addFormattingAndUserRows}
            />
        )
    );
}

ProgressData.propTypes = {
    product: PropTypes.object,
};

export default ProgressData;
