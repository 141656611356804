import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import IconReport from 'cccisd-icons/stats-dots';

import style from './style.css';

const ReportHeader = props => {
    const [groupLeaders, setGroupLeaders] = useState([]);

    const { productLabel } = useSelector(state => state.app.products);

    const { participant, groupLabel, instructorList, dates, groupReport } = props;

    useEffect(() => {
        instructorList.length === 1
            ? setGroupLeaders(instructorList[0])
            : setGroupLeaders(instructorList.map(gl => gl.user.fullName).join(', '));
    }, []);

    return (
        <div className={style.header}>
            <div className={style.topBar}>
                <IconReport spaceRight />
                <strong>{productLabel}</strong>
            </div>
            <div className={style.background}>
                <div className={style.topRow}>
                    <div className={style.groupName}>
                        <h1>{groupLabel}</h1>
                    </div>
                    <div className={style.topRight}>
                        <div>
                            <span className={style.bold}>Dates of Service: </span>
                            <div>
                                {dates.startDate} to {dates.endDate}
                            </div>
                        </div>
                        <div>
                            <span className={style.bold}>Date Report Created: </span>
                            <div>{dates.now}</div>
                        </div>
                    </div>
                </div>
                <div className={style.bottomRow}>
                    {instructorList.length === 1 ? (
                        <div>
                            <div>
                                <span className={style.bold}>Group Leader: </span>
                                <span style={{ marginRight: '0.5em' }}>
                                    {instructorList[0].user.fullName}
                                </span>
                            </div>
                            {!groupReport && (
                                <div>
                                    <span className={style.bold}>Participant: </span>
                                    <span>{participant}</span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <div>
                                <span className={style.bold}>Group Leaders: </span>
                                {groupLeaders}
                            </div>
                            {!groupReport && (
                                <div>
                                    <span className={style.bold}>Participant: </span>
                                    <span>{participant}</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
ReportHeader.propTypes = {
    participant: PropTypes.string,
    groupLabel: PropTypes.string,
    instructorList: PropTypes.array,
    dates: PropTypes.object,
    groupReport: PropTypes.bool,
};
export default ReportHeader;
