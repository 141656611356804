import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from 'cccisd-nivo/line';

const MainChart = props => {
    const { description } = props.settings;
    const {
        data,
        sessionDataVariables: { handle },
    } = props;

    return (
        <div
            style={{
                padding: '1em',
                marginBottom: '1em',
            }}
        >
            <div style={{ height: '200px', width: '100%' }}>
                <ResponsiveLine
                    data={data}
                    colors={{ scheme: 'category10' }}
                    legends={[]}
                    xFormat={d => `Session ${d}`}
                    yFormat={d => `Progress ${d}`}
                    theme={{ fontFamily: 'Cabin' }}
                    yScale={{
                        type: 'linear',
                        min: '0',
                        max: '30',
                    }}
                    xScale={{
                        type: 'linear',
                        min: '1',
                        max: handle === 'ssgrin_ec' ? '12' : '10',
                    }}
                    margin={{
                        bottom: 50,
                        left: 60,
                        right: 20,
                        top: 20,
                    }}
                    axisLeft={{
                        legend: 'Progress',
                        legendPosition: 'middle',
                        tickPadding: 10,
                        legendOffset: -50,
                        tickValues: ['0', '5', '10', '15', '20', '25', '30'],
                    }}
                    axisBottom={{
                        legend: 'Sessions',
                        legendPosition: 'middle',
                        tickPadding: 10,
                        legendOffset: 44,
                    }}
                />
            </div>
            <p style={{ textAlign: 'center', marginTop: '2em' }}>{description}</p>
        </div>
    );
};

MainChart.propTypes = {
    sessionDataVariables: PropTypes.object,
    settings: PropTypes.object,
    data: PropTypes.array,
};

export default MainChart;
