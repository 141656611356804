import React from 'react';
import { Navbar, NavbarLink, NavbarContent, NavbarBrand } from 'cccisd-header';
import IconShoppingCart from 'cccisd-icons/cart';
import DropdownLink from './DropdownLink';
import logo from './header-logo.svg';
import style from './style.css';

const { wpUrl } = window.cccisd.appDefs.app;

function WordpressLinks() {
    return (
        <ul className={`nav navbar-nav ${style.wordpressLinks}`}>
            <NavbarLink to={`${wpUrl}/#ServicesPort`}>Services</NavbarLink>
            <DropdownLink external to={`${wpUrl}/research`} label="Research">
                <NavbarLink to={`${wpUrl}/grants`}>Funding</NavbarLink>
                <NavbarLink to={`${wpUrl}/article-publications`}>Publications</NavbarLink>
            </DropdownLink>
            <NavbarLink to="/products">Products</NavbarLink>
            <NavbarLink to={`${wpUrl}/case-studies`}>Case Studies</NavbarLink>
            <NavbarLink to={`${wpUrl}/news`}>News</NavbarLink>
            <DropdownLink to={`${wpUrl}/about`} label="About">
                <NavbarLink to={`${wpUrl}/staff`}>Staff</NavbarLink>
                <NavbarLink to={`${wpUrl}/FAQ`}>FAQ</NavbarLink>
                <NavbarLink to={`${wpUrl}/advisory-board`}>Advisory Board</NavbarLink>
                <NavbarLink to={`${wpUrl}/job-opportunities`}>Employment</NavbarLink>
            </DropdownLink>
            <NavbarLink to="/3c-resource-center">Resource Center</NavbarLink>
        </ul>
    );
}

function Header() {
    const isSurveyBuilder =
        window.location.pathname &&
        window.location.pathname.includes('/quest') &&
        window.location.pathname.includes('/design/flow');

    if (isSurveyBuilder) {
        return (
            <header>
                <Navbar className={`container ${style.navbar}`}>
                    <NavbarContent allowSwitchRoles={false} />
                </Navbar>
            </header>
        );
    }

    return (
        <>
            <div className={`container ${style.topBar}`} />
            <header className={`container ${style.header}`}>
                <nav className="navbar navbar-default">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#header-navbar-collapse"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <NavbarBrand
                            logoLinkTo={wpUrl}
                            logo={<img src={logo} className={style.headerLogo} alt="3C Institute Logo" />}
                        />
                    </div>

                    <div className="collapse navbar-collapse" id="header-navbar-collapse">
                        <NavbarContent showAlerts allowSwitchRoles>
                            <NavbarLink to={`${wpUrl}/cart`}>
                                <IconShoppingCart spaceRight /> Cart
                            </NavbarLink>
                        </NavbarContent>
                        <WordpressLinks />
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;
