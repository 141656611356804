import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import _format from 'date-fns/format';
import { useParams } from 'cccisd-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionDataVariables } from '../../../../../reducers/reportFilters';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import filterFields from '../../common/groupFilterField.js';
import { getActualFields } from '../../common/helpers.js';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';

import reportStyle from '../../common/reportStyle.css';
import query from './metrics.graphql';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const { comments, titleAndDescription } = props.widgets;

    const { productHandle } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables(productHandle));
        })();
    }, [dispatch]);

    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);
    const commentsQuery = useSelector(state => state.app.reportFilters.widgetsLoaded);

    useEffect(() => {
        // run metrics query
        if (noProgress === true) {
            setNoProgress(false);
        }
        (async () => {
            try {
                const queryHandle = productHandle
                    ? productHandle
                    : props.productIds.find(item => item.value === props.filters.product).handle;

                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.group,
                            productHandle: queryHandle,
                        },
                    })
                    .then(response => {
                        const group = response.data.roles.class;

                        const participantList = response.data.roles.class.childRoles.learnerList;

                        const sessionDataDates = response.data.roles.class.sessionDataDates;
                        const startDate = sessionDataDates.earliestCompleted !== null;
                        const endDate = sessionDataDates.latestCompleted !== null;

                        const firstDate = startDate
                            ? JSON.parse(group.sessionDataDates.earliestCompleted).sessionDate
                            : null;

                        const lastDate = endDate
                            ? JSON.parse(group.sessionDataDates.latestCompleted).sessionDate
                            : null;

                        const dateObj = {
                            startDate: startDate ? _format(new Date(firstDate), 'MM/dd/yyyy') : '',
                            endDate: endDate ? _format(new Date(lastDate), 'MM/dd/yyyy') : '',
                            now: _format(Date.now(), 'MM/dd/yyyy'),
                        };

                        if (!dateObj || participantList.length < 1) {
                            setNoProgress(true);
                        }

                        setMetrics(participantList);

                        setHeaderProps({
                            groupLabel: group.fields.groupLabel,
                            instructorList: group.parentRoles.instructorList,
                            dates: dateObj,
                            groupReport: true,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters, commentsQuery]);

    const renderTableRows = () => {
        return (
            <>
                {metrics.map(learner => {
                    const renderCells = metric => {
                        if (metric === null) {
                            return 0;
                        }
                        return metric[0].valueCount;
                    };

                    return (
                        <tr key={learner.pawn.pawnId}>
                            <td>{learner.fields.participantId}</td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {renderCells(learner.attendance.frequency)} / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {renderCells(learner.behavior.frequency)} / {sessionDataVariables.totalSessions}
                                </div>
                            </td>

                            <td>
                                <div className={reportStyle.numberBox}>
                                    {renderCells(learner.consult.frequency)} / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {renderCells(learner.referral.frequency)} / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {renderCells(learner.earnedTicket.frequency)} / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                            <td>
                                <div className={reportStyle.numberBox}>
                                    {renderCells(learner.lostTicket.frequency)} / {sessionDataVariables.totalSessions}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (noProgress) {
        return <NoGroupRole type="groupData" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    return (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={reportStyle.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={reportStyle.reportBody}>
                        {titleAndDescription}
                        <table className={reportStyle.reportTable}>
                            <thead>
                                <tr>
                                    <th>Participant ID</th>
                                    <th>Sessions Attended</th>
                                    <th>Behavior Problems</th>
                                    <th>Needs Consult</th>
                                    <th>Needs Referral</th>
                                    <th>Earned Ticket</th>
                                    <th>Lost Ticket</th>
                                </tr>
                            </thead>
                            <tbody>{renderTableRows()}</tbody>
                        </table>
                        <hr />
                        {comments}
                    </div>
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    widgetsLoaded: PropTypes.array,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
