import React from 'react';

const AttendanceRate = () => {
    return ({ row }) => {
        const sumAttendance = row['childRoles.learnerSummary.yesAttendance']
            ? row['childRoles.learnerSummary.yesAttendance']
            : 0;
        const totalAttendees = row['childRoles.learnerCount'] ? row['childRoles.learnerCount'] : 0;
        const timpointsCompleted = row['assignmentProgressSummary.timepointsCompleted'];

        const getCurrentSession = () => {
            if (timpointsCompleted !== null) {
                const sessions = timpointsCompleted.map(timepoint => {
                    return +timepoint.value;
                });

                const ascendingSessions = sessions.sort((a, b) => a - b);

                return ascendingSessions[ascendingSessions.length - 1];
            }
            return null;
        };

        const totalSessions = getCurrentSession();

        const calcAttendance = () => {
            if (totalSessions === null) {
                return null;
            }

            const attendancePerSession = sumAttendance / totalSessions;

            const avgAttendance = attendancePerSession / totalAttendees;

            const roundUp = avgAttendance > 0 ? avgAttendance / 0.01 : null;

            return roundUp > 0 ? `${roundUp.toFixed(0)}%` : null;
        };

        return <div>{calcAttendance()}</div>;
    };
};
export default AttendanceRate;
