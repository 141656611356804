import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from 'cccisd-nivo/line';

const DetailChart = props => {
    const {
        data,
        sessionDataVariables: { handle },
    } = props;

    return (
        <div
            style={{
                padding: '1em',
                border: '3px solid #007298',
                borderRadius: '10px',
                marginBottom: '2em',
            }}
        >
            <h3 style={{ color: data[0].id === 'No issue label saved' ? '#f37020' : '#007298' }}>{data[0].id}</h3>

            <div style={{ height: '200px', width: '100%' }}>
                <ResponsiveLine
                    data={data}
                    colors={d => d.color}
                    legends={[]}
                    xFormat={d => `Session ${d}`}
                    yFormat={d => `Severity ${d}`}
                    theme={{ fontFamily: 'Cabin' }}
                    markers={[
                        {
                            axis: 'y',
                            value: '8',
                            lineStyle: {
                                stroke: 'green',
                                strokeWidth: 1,
                            },
                        },
                        {
                            axis: 'y',
                            value: '4',
                            lineStyle: {
                                stroke: 'green',
                                strokeWidth: 1,
                            },
                        },
                    ]}
                    yScale={{
                        type: 'linear',
                        min: '0',
                        max: '10',
                    }}
                    xScale={{
                        type: 'linear',
                        min: '1',
                        max: handle === 'ssgrin_ec' ? '12' : '10',
                    }}
                    margin={{
                        bottom: 50,
                        left: 60,
                        right: 20,
                        top: 20,
                    }}
                    axisLeft={{
                        legend: 'Severity',
                        legendPosition: 'middle',
                        tickPadding: 10,
                        legendOffset: -50,
                        tickValues: ['0', '2', '4', '6', '8', '10'],
                    }}
                    axisBottom={{
                        legend: 'Sessions',
                        legendPosition: 'middle',
                        tickPadding: 10,
                        legendOffset: 45,
                    }}
                />
            </div>
        </div>
    );
};

DetailChart.propTypes = {
    title: PropTypes.string,
    totalAvg: PropTypes.string,
    sessionDataVariables: PropTypes.object,
    settings: PropTypes.object,
    data: PropTypes.array,
    reachedBenchmark: PropTypes.number,
};

export default DetailChart;
