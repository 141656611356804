import React, { useRef } from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';

import OrgAdminForm from '../../../../components/Manage/OrgAdminForm';

import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const EditOrgAdminForm = props => {
    const modal = useRef();

    // rows are coming from each role's orgAdmin_tableMain.graphql
    const { row, loadData } = props;

    const onSubmit = async (values, training) => {
        const userValues = {
            user: values.username,
            username: values.username,
            pawnHash: row['pawn.pawnHash'],
            first_name: values.firstName,
            last_name: values.lastName,
            group: values.org,
        };

        const previouslyTrainedValues = training;

        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: row['pawn.pawnId'],
            }),
            {
                ...userValues,
                ...previouslyTrainedValues,
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        await loadData();
        notification('Org Admin edited.');
    };

    const trained =
        JSON.parse(row['fields.trained']) !== null ? JSON.parse(row['fields.trained']) : null;

    return (
        <Modal
            ref={modal}
            trigger={
                <Tooltip title="Edit">
                    <button type="button" className="btn btn-xs btn-success">
                        <IconPencil />
                    </button>
                </Tooltip>
            }
            title={`Edit ${row['user.fullName']}`}
        >
            <OrgAdminForm
                operation="edit"
                onSubmit={onSubmit}
                initialValues={{
                    username: row['user.username'],
                    firstName: row['user.firstName'],
                    lastName: row['user.lastName'],
                    org: row['ancestorGroups.organization.group.groupId'],
                    ...trained,
                }}
            />
        </Modal>
    );
};

EditOrgAdminForm.propTypes = {
    loadData: PropTypes.func,
    row: PropTypes.object,
};

export default EditOrgAdminForm;
