import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import IconReport from 'cccisd-icons/stats-dots';

import style from './style.css';

const ReportHeader = props => {
    const { fullName, groupCount, participantCount, dates } = props;

    const { productLabel } = useSelector(state => state.app.products);

    return (
        <div className={style.header}>
            <div className={style.topBar}>
                <IconReport spaceRight />
                <strong>{productLabel}</strong>
            </div>

            <div className={style.topRow}>
                <h1>Fidelity Summary</h1>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div>
                            <strong>Group Leader Name:</strong> {fullName}
                        </div>
                        <div>
                            <strong>Number of Groups:</strong> {groupCount}
                        </div>
                        <div>
                            <strong>Number of Participants:</strong> {participantCount}
                        </div>
                    </div>
                    <div>
                        <div>
                            <strong>Dates of Service: </strong>
                            <div>
                                {dates.startDate} <strong>to</strong> {dates.endDate}
                            </div>
                        </div>
                        <div>
                            <strong>Date Report Created: </strong>
                            {dates.now}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
ReportHeader.propTypes = {
    fullName: PropTypes.string,
    groupCount: PropTypes.number,
    participantCount: PropTypes.number,
    dates: PropTypes.object,
    groupReport: PropTypes.bool,
};
export default ReportHeader;
