import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from 'cccisd-nivo/line';

const MainChart = props => {
    const { title, description } = props.settings;
    const {
        data,
        sessionDataVariables: { handle },
    } = props;

    return (
        <div
            style={{
                padding: '1em',
                marginBottom: '1em',
            }}
        >
            <div>
                <h3 style={{ color: '#007298' }}>{title}</h3>
                <p>{description}</p>
            </div>
            <div style={{ height: '200px', width: '100%' }}>
                <ResponsiveLine
                    data={data}
                    colors={{ scheme: 'category10' }}
                    legends={[
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: 50,
                            itemsSpacing: 30,
                            itemDirection: 'left-to-right',
                            itemWidth: data.length < 4 ? 160 : 115,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        },
                    ]}
                    xFormat={d => `Session ${d}`}
                    yFormat={d => `Severity ${d}`}
                    theme={{ fontFamily: 'Cabin' }}
                    markers={[
                        {
                            axis: 'y',
                            value: '8',
                            lineStyle: {
                                stroke: 'green',
                                strokeWidth: 1,
                            },
                        },
                        {
                            axis: 'y',
                            value: '4',
                            lineStyle: {
                                stroke: 'green',
                                strokeWidth: 1,
                            },
                        },
                    ]}
                    yScale={{
                        type: 'linear',
                        min: '0',
                        max: '10',
                    }}
                    xScale={{
                        type: 'linear',
                        min: '1',
                        max: handle === 'ssgrin_ec' ? '12' : '10',
                    }}
                    margin={{
                        bottom: 50,
                        left: 60,
                        right: 20,
                        top: 20,
                    }}
                    axisLeft={{
                        legend: 'Severity',
                        legendPosition: 'middle',
                        tickPadding: 10,
                        legendOffset: -50,
                        tickValues: ['0', '2', '4', '6', '8', '10'],
                    }}
                />
            </div>
        </div>
    );
};

MainChart.propTypes = {
    sessionDataVariables: PropTypes.object,
    settings: PropTypes.object,
    data: PropTypes.array,
};

export default MainChart;
