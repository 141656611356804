import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'cccisd-react-router';
import widgets from '../../common/widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';
import filterFields from '../../common/filterFields.js';
import style from './style.css';
import query from './metrics.graphql';
import { getDates, getActualFields } from '../../common/helpers.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);
    const [currentChart, setCurrentChart] = useState('cognitive');

    const { productHandle } = useParams();

    useEffect(() => {
        if (noProgress === true) {
            setNoProgress(false);
        }

        (async () => {
            try {
                const queryHandle = productHandle
                    ? productHandle
                    : props.productIds.find(item => item.value === props.filters.product).handle;

                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.filters.participant,
                            productHandle: queryHandle,
                            productId: props.productId || props.filters.product,
                        },
                    })
                    .then(response => {
                        const post = response.data.roles.learner.postProgress.devTags;
                        const pre = response.data.roles.learner.preProgress.devTags;
                        const followUp = response.data.roles.learner.followUpProgress.devTags;

                        const peerAcceptance = {
                            pre: pre.PICPeerAcceptanceChildScore,
                            post: post.PICPeerAcceptanceChildScore,
                            followUp: followUp.PICPeerAcceptanceChildScore,
                        };
                        const cognitive = {
                            pre: pre.PICCognitiveCompetenceChildScore,
                            post: post.PICCognitiveCompetenceChildScore,
                            followUp: followUp.PICCognitiveCompetenceChildScore,
                        };
                        const physical = {
                            pre: pre.PICPhysicalCompetenceChildScore,
                            post: post.PICPhysicalCompetenceChildScore,
                            followUp: followUp.PICPhysicalCompetenceChildScore,
                        };
                        const maternal = {
                            pre: pre.PICMaternalAcceptanceChildScore,
                            post: post.PICMaternalAcceptanceChildScore,
                            followUp: followUp.PICMaternalAcceptanceChildScore,
                        };
                        const group = response.data.roles.learner.parentRoles.class;
                        const participant = response.data.roles.learner;

                        const allNull =
                            Object.values(pre)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item) &&
                            Object.values(post)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item) &&
                            Object.values(followUp)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item);

                        const dateObj = getDates(group);

                        if (!dateObj || allNull) {
                            setNoProgress(true);
                        }

                        setMetrics({
                            peerAcceptance,
                            cognitive,
                            physical,
                            maternal,
                        });
                        setHeaderProps({
                            participant: participant.fields.participantId,
                            groupLabel: group.fields.groupLabel,
                            instructorList: group.parentRoles.instructorList,
                            dates: dateObj,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };
    const handleClick = chartType => {
        setCurrentChart(chartType);
    };
    const labels = {
        peerAcceptance: 'Peer Acceptance',
        cognitive: 'Cognitive Competence',
        physical: 'Physical Competence',
        maternal: 'Maternal Acceptance',
    };
    const renderPrintCharts = chart => {
        return <div className={style.additionalReportBox}>{renderBarChart(chart)}</div>;
    };
    const renderBarChart = chart => {
        const colors = {
            'Peer Acceptance': '#DC3913',
            'Cognitive Competence': '#DC3913',
            'Physical Competence': '#DC3913',
            'Maternal Acceptance': '#DC3913',
        };

        const getColor = bar => colors[bar.id];

        const chartData = ['pre', 'post', 'followUp'].map((item, i) => {
            if (metrics[chart][item] || metrics[chart][item] === 0) {
                if (i === 0) {
                    return {
                        timepoint: 'Pre',
                        [labels[chart]]: metrics[chart][item],
                    };
                }
                if (i === 1) {
                    return {
                        timepoint: 'Post',
                        [labels[chart]]: metrics[chart][item],
                    };
                }
                if (i === 2) {
                    return {
                        timepoint: 'Follow Up',
                        [labels[chart]]: metrics[chart][item],
                    };
                }
            }
            return null;
        });

        const getSettings = () => {
            const keys = {
                cognitive: ['Cognitive Competence'],
                peerAcceptance: ['Peer Acceptance'],
                physical: ['Physical Competence'],
                maternal: ['Maternal Acceptance'],
            };

            return {
                tickValues: [0, 1, 2, 3, 4, 5],
                keys: keys[chart],
                maxValue: 5,
            };
        };

        const chartSettings = getSettings();

        return (
            <>
                <h4 style={{ marginTop: '1em', marginBottom: '1em' }}>{chartSettings.keys[0]}</h4>
                <div style={{ width: '100%', height: '300px', paddingLeft: '4em', marginTop: '1em' }}>
                    <ResponsiveBar
                        data={chartData}
                        keys={chartSettings.keys}
                        indexBy="timepoint"
                        colors={getColor}
                        maxValue={chartSettings.maxValue}
                        axisLeft={{
                            legend: 'Score',
                            legendPosition: 'middle',
                            legendOffset: -40,
                            tickValues: chartSettings.tickValues,
                        }}
                        labelFormat={d => {
                            const isMax = +d === 5;

                            return (
                                <tspan style={isMax ? { fill: '#fff' } : {}} y={isMax ? 10 : -10}>
                                    {Math.round(+d * 100) / 100}
                                </tspan>
                            );
                        }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        markers={[
                            {
                                axis: 'y',
                                value: 3,
                                lineStyle: {
                                    stroke: '#3266CC',
                                },
                                textStyle: {
                                    fill: '#3266CC',
                                },
                            },
                        ]}
                    />
                </div>
            </>
        );
    };

    const remderChartButtons = () => {
        const buttons = [
            { label: 'Cognitive Competence', chartType: 'cognitive' },
            { label: 'Peer Acceptance', chartType: 'peerAcceptance' },
            { label: 'Physical Competence', chartType: 'physical' },
            { label: 'Maternal Acceptance', chartType: 'maternal' },
        ];
        const buttonElements = (
            <>
                {buttons.map((item, i) => {
                    const isCurrentChart = item.chartType === currentChart;
                    const topRow = i < 3;
                    return (
                        <button
                            type="button"
                            className={isCurrentChart ? style.chartButtonActive : style.chartButton}
                            style={topRow ? { marginBottom: '20px' } : {}}
                            onClick={() => {
                                handleClick(item.chartType);
                            }}
                        >
                            {item.label}
                        </button>
                    );
                })}
            </>
        );
        return buttonElements;
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (noProgress) {
        return <NoGroupRole type="data" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={style.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={style.reportBody}>
                        <div className={style.titleRow}>
                            <h1>Pictorial Scale Child Report</h1>
                        </div>
                        <p>
                            {`The Pictorial Scale of Perceived Competence and Social Acceptance for Young Children is a 24-item instrument administered to children to measure self-reported feelings of competence and social acceptance. The Child Pictorial Scale is made up of four subscales, two of which assess General Competence (Cognitive Competence, Physical Competence), and two of which assess Social Acceptance (Peer Acceptance, Maternal Acceptance). When the child-report is used in conjunction with the teacher-report version, providers can explore the level of congruence between the child's and teacher's perceptions of the child's competence and acceptance.`}
                        </p>

                        <p>
                            Mean scores range from 1 to 4 with higher scores reflecting a greater sense of adequacy and
                            competency.
                        </p>
                        {renderBarChart(currentChart)}
                        <BrowserOnly>
                            <div className={style.chartButtons} style={showPrintMode ? { visibility: 'hidden' } : {}}>
                                {remderChartButtons()}
                            </div>
                        </BrowserOnly>
                    </div>
                </div>
            </Page>
            <div style={{ display: showPrintMode ? 'block' : 'none' }}>
                <Page>
                    {['peerAcceptance', 'physical']
                        .filter(item => item !== currentChart)
                        .map(chart => {
                            return <>{renderPrintCharts(chart)}</>;
                        })}
                </Page>
                <Page>
                    {['maternal', 'cognitive']
                        .filter(item => item !== currentChart)
                        .map(chart => {
                            return <>{renderPrintCharts(chart)}</>;
                        })}
                </Page>
            </div>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    learner: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noLearners: PropTypes.bool,
    currentProduct: PropTypes.number,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
