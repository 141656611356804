import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import style from './style.css';

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: false,
    };

    render() {
        return (
            <div className={style.background}>
                <div className={style.wrapper}>
                    <Header className={this.props.className} />

                    <div className={`container ${style.body}`}>{this.props.children}</div>

                    <div className={style.footer}>
                        <Footer className={this.props.className} />
                    </div>
                </div>
            </div>
        );
    }
}
