import React, { useState } from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import { client } from 'cccisd-apollo';
import Modal from 'cccisd-modal';
import Loader from 'cccisd-loader';
import query from './transcript.graphql';
import relatedDeploymentsQuery from './transcriptRelated.graphql';
import style from './style.css';
import { DATE_FORMAT } from './index.js';

const Transcript = ({ actingPawnId, mediatorProps, parentDeploymentId, relatedDeploymentId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    async function getData() {
        setIsLoading(true);
        const resp = await client.query({
            query,
            variables: {
                parentDeploymentId,
                actingPawnId,
            },
            fetchPolicy: 'network-only',
        });

        let respData = resp.data.deployments.deploymentList || [];

        if (relatedDeploymentId) {
            const relatedResp = await client.query({
                query: relatedDeploymentsQuery,
                variables: {
                    deploymentIds: respData.map(d => d.relatedDeploymentId).filter(id => id),
                },
                fetchPolicy: 'network-only',
            });

            const relatedDeployments = _get(relatedResp, 'data.deployments.deploymentList', []) || [];
            const relatedLabelMap = relatedDeployments.reduce((total, curr) => {
                total[curr.deploymentId] = curr.label;
                return total;
            }, {});

            respData = respData.map(d => ({
                ...d,
                relatedLabel: d.relatedDeploymentId ? relatedLabelMap[d.relatedDeploymentId] : '',
            }));
        }

        setData(respData);
        setIsLoading(false);
    }

    return (
        <Modal
            title="Transcript"
            trigger={
                <button className={'btn btn-xs btn-link ' + style.transcript} type="button">
                    Transcript
                </button>
            }
            render={({ closeModal }) => {
                if (isLoading || !data) {
                    return <Loader loading type="inline" />;
                }

                const isRelatedDeployment = !!relatedDeploymentId;

                const isFinalTestScore =
                    _get(mediatorProps, 'finalTest.toggle', false) &&
                    _get(mediatorProps, 'finalTest.useRepeatingFinalTest', false);

                return (
                    <>
                        <table className="table table-condensed">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {isRelatedDeployment && <th>Related to</th>}
                                    <th>Completed</th>
                                    {isFinalTestScore && <th>Score</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(deployment => {
                                    const completedAt = _get(deployment, 'assignmentProgress.completedAt');
                                    if (!completedAt) {
                                        return null;
                                    }

                                    const variables = _get(deployment, 'assignmentProgress.variables', {}) || {};
                                    const scoreKey = Object.keys(variables).find(k => k.endsWith('_test_score'));
                                    let score = scoreKey ? variables[scoreKey] : '-';

                                    return (
                                        <tr key={deployment.timepoint + deployment.relatedDeploymentId}>
                                            <th>{deployment.timepoint}</th>
                                            {isRelatedDeployment && <td>{deployment.relatedLabel}</td>}
                                            <td>{moment(completedAt, 'YYYY-MM-DD HH:mm:ss').format(DATE_FORMAT)}</td>
                                            {isFinalTestScore && <td>{score}</td>}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                );
            }}
            afterShow={() => getData()}
        />
    );
};

export default Transcript;
