import React from 'react';
import classnames from 'classnames';
import IconCross from 'cccisd-icons/svg/cross3';
import style from './style.css';

export default ({ placeholder = 'Filter', value, onChange }) => {
    const handleChange = e => {
        onChange(e.target.value);
    };

    return (
        <span className={classnames({ [style.withValue]: value, 'has-warning': value })}>
            <input
                className="form-control"
                type="text"
                name="filter"
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                autoComplete="off"
            />
            <div className={style.icon} onClick={() => onChange('')}>
                <IconCross />
            </div>
        </span>
    );
};
