import React from 'react';
import Tooltip from 'cccisd-tooltip';
import { Link } from 'react-router-dom';

import IconCalendar from 'cccisd-icons/calendar';
import IconReport from 'cccisd-icons/stats-dots';

const EnterData = () => {
    return ({ row }) => {
        const groupId = row && row['pawn.pawnId'] ? row['pawn.pawnId'] : '';

        const productHandle = row['productHandle.assignment.group.fields.productHandle'];

        return (
            <div
                className="text-center"
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <div>
                    <Tooltip title="Enter Data">
                        <Link to={`/product/${productHandle}/sessionData/${groupId}`}>
                            <button type="button" className="btn btn-success btn-sm">
                                <IconCalendar />
                            </button>
                        </Link>
                    </Tooltip>
                </div>

                <Tooltip title="View Reports">
                    <Link to={`/product/${productHandle}/reportCenter`}>
                        <button type="button" className="btn btn-primary btn-sm">
                            <IconReport />
                        </button>
                    </Link>
                </Tooltip>
            </div>
        );
    };
};
export default EnterData;
