/*
 * WARNING: DO NOT MOVE THIS FILE
 *
 * Its location is referenced directly from within an app because the search
 * for navigations occurs BEFORE the export in Root.js.
 *
 * To use this mediator in your app, import as follows:
 *
 * import courseWrapper from 'cccisd-laravel-assignment/dist/components/navigations/courseWrapper';
 */
import thumbnail from './courseWrapper.png';
import Mediator from './Mediator';
import Settings from './Settings';

export default {
    handle: 'courseWrapper',
    label: 'CourseWrapper',
    thumbnail,
    mediatorComponent: Mediator,
    settingsComponent: Settings,
};
