import React, { useState, useEffect } from 'react';
import { client as apollo } from 'cccisd-apollo';
import { SessionDataEntry } from 'cccisd-laravel-assignment';
import { useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import groupListQuery from './groupList.graphql';
import questProjectQuery from './questprojectIds.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;

function SessionData() {
    const [groupList, setGroupList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [questId, setQuestId] = useState(null);

    const { productId } = useSelector(state => state.app.products);
    const { productHandle } = useParams();

    useEffect(() => {
        async function start() {
            await getQuestProject();
            await initializeData();
        }
        start();
    }, []);

    async function getQuestProject() {
        const res = await apollo.query({
            query: questProjectQuery,
            fetchPolicy: 'network-only',
            variables: { productHandle },
        });

        setQuestId(res.data.groups.questproject.group.groupId);
    }

    async function initializeData() {
        const data = await getGroupList();
        const formattedGroupList = formatGroups(data.roles.classList);
        setGroupList(formattedGroupList);
        setLoading(false);
    }

    async function getGroupList() {
        const pawnId = Fortress.user.acting.id;

        const res = await apollo.query({
            query: groupListQuery,
            fetchPolicy: 'network-only',
            variables: { productId, pawnId },
        });

        return res.data;
    }

    const formatGroups = groups => {
        return groups.map(
            ({
                fields: { groupLabel },
                pawn: { pawnId, pawnHash },
                childRoles: { learnerList },
            }) => {
                return {
                    groupId: pawnId,
                    label: groupLabel,
                    pawnId,
                    pawnHash,
                    flowProps: getFlowProps(learnerList),
                };
            }
        );
    };

    const formatParticipantList = learners => {
        return learners.map(learner => {
            return {
                name: learner.fields.participantId,
                pawnId: learner.pawn.pawnId,
                pawnHash: learner.pawn.pawnHash,
            };
        });
    };

    const getFlowProps = participantList => {
        return {
            variables: {},
            userLists: {
                grinParticipantList: formatParticipantList(participantList),
            },
        };
    };
    return (
        !loading && (
            <SessionDataEntry
                questprojectIds={[questId]}
                groups={groupList}
                title="Session Data"
                description="Enter log and fidelity data gathered during group sessions."
            />
        )
    );
}

export default SessionData;
