import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import questPermissionsByArea from '../../helpers/questPermissionsByArea.js';
import { getCurrentProject, getProjectLinks } from '../../selectors/admin.js';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const NoProjectsMessage = ({ isExported }) => {
    if (isExported) {
        return <h3 className={style.info}>No Data</h3>;
    }

    const projectLinks = useSelector(state => getProjectLinks(state.assignment.admin));
    const project = useSelector(state => getCurrentProject(state.assignment.admin));
    const hasDesignPermissions = Fortress.hasAnyAccess(questPermissionsByArea.design.permissions, project.groupId);

    return (
        <div className={style.info}>
            <h3>This project has no Quests.</h3>
            {hasDesignPermissions && (
                <p>
                    Create Quests from the &quot;<Link to={projectLinks.design}>Design</Link>
                    &quot; tab.
                </p>
            )}
        </div>
    );
};

export default NoProjectsMessage;
