import React from 'react';
import { Page, Report, BrowserOnly, PrintOnly } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';
import _format from 'date-fns/format';
import _addDays from 'date-fns/addDays';
import style from './style.css';

const RenderCertificate = props => {
    const { row, assignmentSettings, certificateLabel, backgroundUrls, fullName } = props;

    const renderContent = ({
        accreditations,
        certificate,
        showAccreditation,
        viewType = 'browser',
        certificateId,
        formattedDate,
        courseName,
        displayName,
    }) => {
        const containerStyle =
            viewType === 'browser' ? style.certificateContainerBrowser : style.certificateContainerPrint;

        return (
            <div className={containerStyle}>
                <div
                    className={style.certificate}
                    style={{ backgroundImage: `url(${backgroundUrls[certificate.backgroundId]})` }}
                >
                    <div className={style.passDate}>{formattedDate}</div>
                    <div className={style.certificateContent}>
                        <div className={style.name}>{fullName}</div>
                        <div className={style.courseName}>{displayName ? displayName : courseName}</div>
                        {certificate.freeText && <Html content={certificate.freeText} />}
                        <div className={style.certificateId}>
                            <b>Certificate ID:</b> {certificateId}
                        </div>
                    </div>
                    {showAccreditation && (
                        <div className={style.accreditationContainer}>
                            {accreditations.map((aText, i) => (
                                <div key={i} className={style.accreditation}>
                                    <Html content={aText} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getCertSettings = () => {
        const wasToggled = !row['assignmentProgress.completed'];

        const certificates = wasToggled
            ? assignmentSettings.settings.navigationSettings.certificate.certificates
            : row['assignmentProgress.assignment.settings.navigationSettings.certificate.certificates'];

        const certificateId = wasToggled
            ? row[`pawn.pawnId`] + 2000000
            : row['assignmentProgress.assignmentProgressId'] + 1000000;

        const completedDate = wasToggled
            ? new Date()
            : _addDays(new Date(row['assignmentProgress.lastVisitedAt'].substr(0, 10)), 1);

        const formattedDate = _format(completedDate, 'MMMM d, yyyy');
        const downloadFilename = `Certificate_${certificateId}`
            .replace(/[ -]/g, '_')
            .replace(/[^A-Za-z0-9_]/g, '')
            .replace(/_$/, '');

        const courseName = wasToggled
            ? assignmentSettings.settings.navigationSettings.certificate.certificates[0].courseName
            : row['assignmentProgress.assignment.settings.navigationSettings.certificate.courseName'];

        const displayName = certificateLabel;

        return {
            downloadFilename,
            certificateId,
            formattedDate,
            backgroundUrls,
            courseName,
            displayName,
            certificates,
        };
    };

    const content = getCertSettings();

    return (
        <>
            {content && (
                <Report downloadFilename={content.downloadFilename}>
                    {content.certificates.map((certificate, index) => {
                        const accreditations = [
                            certificate.accreditation1,
                            certificate.accreditation2,
                            certificate.accreditation3,
                        ].filter(a => a);
                        const showAccreditation = accreditations.length > 0;
                        return (
                            <Page key={index}>
                                <BrowserOnly>
                                    {renderContent({
                                        accreditations,
                                        certificate,
                                        showAccreditation,
                                        viewType: 'browser',
                                        ...content,
                                    })}
                                </BrowserOnly>
                                <PrintOnly>
                                    {renderContent({
                                        accreditations,
                                        certificate,
                                        showAccreditation,
                                        viewType: 'print',
                                        ...content,
                                    })}
                                </PrintOnly>
                            </Page>
                        );
                    })}
                </Report>
            )}
        </>
    );
};

export default RenderCertificate;
