import React from 'react';

export default () => {
    return ({ row }) => {
        const timpointsCompleted = row['assignmentProgressSummary.timepointsCompleted'];

        const getCurrentSession = () => {
            if (timpointsCompleted !== null) {
                const sessions = timpointsCompleted.map(timepoint => {
                    return +timepoint.value;
                });

                const ascendingSessions = sessions.sort((a, b) => a - b);

                return ascendingSessions[ascendingSessions.length - 1];
            }
            return null;
        };
        return <div>{getCurrentSession(row)}</div>;
    };
};
