import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import chartsQuery from '../../charts.graphql';
import { lineChartColors } from '../../../../common/helpers.js';
import { getSessionDataVariables } from '../../../../../../../reducers/reportFilters';
import DetailChart from '../../../../common/components/DetailChart';
import _flatten from 'lodash/flatten';

const Component = props => {
    const [metrics, setMetrics] = useState([]);
    const [loading, setLoading] = useState(true);

    const { show } = props.settings;
    const { filters } = props;

    const dispatch = useDispatch();
    const { productHandle } = useParams();

    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);
    const { productIds } = useSelector(state => state.app.reportFilters);

    const prodHandle = productHandle ? productHandle : productIds.find(prod => prod.value === filters.product).handle;

    useEffect(() => {
        dispatch(getSessionDataVariables(prodHandle));

        (async () => {
            try {
                await client
                    .query({
                        query: chartsQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +filters.participant,
                            productHandle: prodHandle,
                        },
                    })
                    .then(response => {
                        const learnerData = response.data.roles.learner.assignmentProgressList;

                        const valuesBySession = () => {
                            if (learnerData.length > 0) {
                                return learnerData.map(ap => {
                                    return {
                                        timepoint: +ap.deployment.timepoint,
                                        issueLabels: ap.issues.GroupSessionProgressIssues,
                                        issueScores: ap.issues.GroupSessionProgressRatings,
                                    };
                                });
                            }
                            return null;
                        };

                        const results = valuesBySession();

                        const allIssues = results.map(result => {
                            return result.issueLabels;
                        });

                        const issues = _flatten(allIssues).filter(issue => issue !== null);

                        const uniqueIssues = [...new Set(issues)];

                        const lookUpIssues = iss => {
                            const hasIssue = results.map(result => {
                                if (result.issueLabels && result.issueLabels.length > 0) {
                                    const index = result.issueLabels.indexOf(iss);

                                    if (result.issueLabels.includes(iss)) {
                                        return {
                                            id: iss,
                                            data: [
                                                {
                                                    x: result.timepoint,
                                                    y: result.issueScores[index],
                                                },
                                            ],
                                        };
                                    }
                                    return null;
                                }
                                return null;
                            });

                            return hasIssue.filter(res => res !== null);
                        };

                        // make an object containing each time an issue is mentioned, and record the values
                        const issuesAndValues = uniqueIssues.map(iss => lookUpIssues(iss));

                        const chartData = (iss, len) => {
                            return [...Array(len).keys()].map(i => {
                                const label = iss[i][0].id;

                                // make an array with just the timepoint and value per issue
                                const scores = iss[i].map(thing => Array.from(thing.data));

                                return {
                                    id: `issue ${[i + 1]}`,
                                    settings: i <= 2 ? { ...props.settings[`issueTopic${[i + 1]}`] } : {},
                                    convertedData: [
                                        {
                                            id: label !== '' ? label : 'No issue label saved',
                                            data: _flatten(scores),
                                            color: lineChartColors[i],
                                        },
                                    ],
                                };
                            });
                        };

                        setMetrics(chartData(issuesAndValues, issuesAndValues.length));

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters, props.settings]);

    return loading ? (
        <Loader type="inline" />
    ) : (
        <>
            {show && (
                <>
                    {metrics.map(metric => {
                        return (
                            <div key={metric.id}>
                                <DetailChart
                                    settings={metric.settings}
                                    data={metric.convertedData}
                                    sessionDataVariables={sessionDataVariables}
                                />
                            </div>
                        );
                    })}
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
