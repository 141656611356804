import React, { useRef } from 'react';
import Modal from 'cccisd-modal';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';

import OrgAdminForm from '../../../../components/Manage/OrgAdminForm';

import IconPlus from 'cccisd-icons/plus-circle2';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const AddLearnerForm = props => {
    const modal = useRef();

    const onSubmit = async (values, training) => {
        try {
            const userValues = {
                role: 'orgAdmin',
                username: values.username,
                user: values.existingUser ? values.username : null,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.username,
                group: values.org,
            };

            // if user already exists, we don't need to send the username in the POST
            values.existingUser && delete userValues.username;

            const previouslyTrainedValues = training;

            const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
                ...userValues,
                ...previouslyTrainedValues,
            });

            if (response.data.errors) {
                return Promise.reject(response.data.errors);
            }

            await props.loadData();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className="btn btn-primary">
                    <span>
                        <IconPlus spaceRight />
                        Add Org Admin
                    </span>
                </button>
            }
            title="Add New Org Admin"
        >
            <OrgAdminForm onSubmit={onSubmit} operation="create" />
        </Modal>
    );
};

AddLearnerForm.propTypes = {
    loadData: PropTypes.func,
};

export default AddLearnerForm;
