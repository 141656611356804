import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'cccisd-react-router';
import widgets from '../../common/widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';
import filterFields from '../../common/filterFields.js';
import style from '../../common/barReportStyle.css';
import query from './metrics.graphql';
import { getDates, getActualFields } from '../../common/helpers.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import ChartImage from './teacherALQ.png';
import InfoIcon from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';

import SimpleBarChart from '../../common/components/SimpleBarChart';
const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const { productHandle } = useParams();

    useEffect(() => {
        if (noProgress === true) {
            setNoProgress(false);
        }
        (async () => {
            try {
                const queryHandle = productHandle
                    ? productHandle
                    : props.productIds.find(item => item.value === props.filters.product).handle;
                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.filters.participant,
                            productHandle: queryHandle,
                            productId: props.productId || props.filters.product,
                        },
                    })
                    .then(response => {
                        const post =
                            response.data.roles.learner.postProgress.devTags.ALQTeacherScore;
                        const pre = response.data.roles.learner.preProgress.devTags.ALQTeacherScore;
                        const followUp =
                            response.data.roles.learner.followUpProgress.devTags.ALQTeacherScore;

                        const group = response.data.roles.learner.parentRoles.class;
                        const participant = response.data.roles.learner;

                        const allNull = post === null && pre === null && followUp === null;

                        const dateObj = getDates(group);

                        if (!dateObj || allNull) {
                            setNoProgress(true);
                        }

                        setMetrics({ pre, post, followUp });
                        setHeaderProps({
                            participant: participant.fields.participantId,
                            groupLabel: group.fields.groupLabel,
                            instructorList: group.parentRoles.instructorList,
                            dates: dateObj,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    const renderBarChart = () => {
        const colors = { Overall: '#DC3913' };

        const markers = [
            {
                axis: 'y',
                value: 3,
                lineStyle: {
                    stroke: '#3266CC',
                },
                textStyle: {
                    fill: '#3266CC',
                },
            },
        ];

        return (
            <SimpleBarChart
                metrics={metrics}
                colors={colors}
                tickValues={[0, 1, 2, 3, 4, 5]}
                markers={markers}
                maxValue={5}
            />
        );
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (noProgress) {
        return <NoGroupRole type="data" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={style.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={style.reportBody}>
                        <div className={style.titleRow}>
                            <h1>ALQ Teacher Report</h1>{' '}
                            <Tooltip
                                title={
                                    <div>
                                        <p>
                                            {`The ALQ is rated  using a 5-point Likert scale, with a fifth option of "Have not observed this behavior or skill." The following numerical values are assigned to each of the response options:`}
                                        </p>
                                        <img
                                            src={ChartImage}
                                            alt="Chart"
                                            style={{ width: '580px', marginBottom: '20px' }}
                                        />
                                        <p>
                                            All items with values of 0, indicating behaviors have
                                            not been observed, are excluded from the computation of
                                            the average scores for each respondent.
                                        </p>
                                        <p>
                                            All items values ranging from 1-5 are summed and divided
                                            by the total number of items (i.e., behaviors) observed.
                                            For example, if a teacher indicates non-observed
                                            behaviors on two items, the total sum of the items with
                                            values 1-5 would be divided by 20. Average scores range
                                            from 1 to 5, with higher scores indicating greater
                                            demonstration of prosocial behaviors.
                                        </p>
                                    </div>
                                }
                                type="popover1"
                                position="top"
                                maxWidth="600px"
                            >
                                <InfoIcon />
                            </Tooltip>
                        </div>
                        <p>
                            {`The Achieved Learning Questionnaire (ALQ) Teacher Report is a 22-item measure used to assess a teacher's observations of children's understanding of behaviors, social skills, and concepts taught in S.S.GRIN. This measure utilizes a rating scale for teachers to rate how well a student demonstrates certain skills and behaviors compared to other children their own age in different settings at school.`}
                        </p>
                        <p>
                            Mean scores range from 1-5, with higher scores indicating a greater
                            demonstration of prosocial behaviors as perceived by the teacher.
                        </p>
                        {renderBarChart()}
                    </div>
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    learner: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noLearners: PropTypes.bool,
    currentProduct: PropTypes.number,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
