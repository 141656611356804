import React from 'react';
import PropTypes from 'prop-types';
import { NavbarLink } from 'cccisd-header';
import style from './style.css';

import IconFacebook from 'cccisd-icons/facebook2';
import IconLinkedIn from 'cccisd-icons/linkedin';

const { wpUrl } = window.cccisd.appDefs.app;
export default class Footer extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        showPolicyLinks: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showPolicyLinks: true,
    };

    render() {
        const isSurveyBuilder =
            window.location.pathname &&
            window.location.pathname.includes('/quest') &&
            window.location.pathname.includes('/design/flow');

        if (isSurveyBuilder) {
            return <footer />;
        }

        return (
            <footer className={`${this.props.className} ${style.footer}`} id="layout_footer">
                <div className={style.footerLeft}>
                    <nav className={style.nav}>
                        <ul className={style.siteLinks}>
                            <NavbarLink to={`${wpUrl}/about`}>About Us</NavbarLink>
                            <NavbarLink to={`${wpUrl}/#ServicesPort`}>Services</NavbarLink>
                            <NavbarLink to={`${wpUrl}/research`}>Research</NavbarLink>
                            <NavbarLink to="/products">Products</NavbarLink>
                            <NavbarLink to={`${wpUrl}/case-studies`}>Case Studies</NavbarLink>
                            <NavbarLink to={`${wpUrl}/3c-media-kit`}>Media Kit</NavbarLink>
                        </ul>
                    </nav>
                    <div className={style.copyright}>
                        © Copyright 3C Institute, All Rights Reserved. 2645 Meridian Parkway, Suite 350, Durham, NC
                        27713 (984) 316-0406
                    </div>
                </div>
                <div className={style.footerRight}>
                    <ul className={style.socialMediaLinks}>
                        <a href="https://www.facebook.com/3Cinstitute">
                            <div className={style.socialMediaIcons}>
                                <IconFacebook />
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/3cinstitute">
                            <div className={style.socialMediaIcons}>
                                <IconLinkedIn />
                            </div>
                        </a>
                    </ul>
                    {this.props.showPolicyLinks && (
                        <ul className={style.policyLinks}>
                            <NavbarLink to={`${wpUrl}/terms-of-use`}>Terms of Use</NavbarLink>
                            <span> | </span>
                            <NavbarLink to={`${wpUrl}/privacy-policy`}>Privacy Policy</NavbarLink>
                            <span> | </span>
                            <NavbarLink to={`${wpUrl}/#ccc-lets-talk`}>Contact Us</NavbarLink>
                        </ul>
                    )}
                </div>
            </footer>
        );
    }
}
