import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import _format from 'date-fns/format';
import { setWidgetsLoaded } from '../../../../../../../reducers/reportFilters';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import commentsQuery from './commentsQuery.graphql';
import style from './style.css';

const Comments = props => {
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState(null);

    const { title, description } = props.settings;
    const { group } = props.filters;

    const dispatch = useDispatch();
    const { productHandle } = useParams();
    const { productIds } = useSelector(state => state.app.reportFilters);

    const prodHandle = productHandle
        ? productHandle
        : productIds.find(prod => prod.value === props.filters.product).handle;

    useEffect(() => {
        (async () => {
            await getCommentsData();
            await dispatch(setWidgetsLoaded('comments'));
        })();
    }, [+group]);

    const getCommentsData = async () => {
        try {
            const response = await client.query({
                query: commentsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId: +group,
                    productHandle: prodHandle,
                },
            });

            const sessionComments = response.data.roles.class.comments.session;

            setComments(sessionComments);
            dispatch(setWidgetsLoaded('comments'));
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <h1 className={style.title}>{title}</h1>
            <p>{description}</p>
            {!loading &&
                (comments !== null ? (
                    comments.map(sess => (
                        <div key={sess.value} className={style.commentBox}>
                            <div className={style.commentHeader}>
                                <div>{`Session ${sess.value} `}</div>
                                <div className={style.commentDate}>
                                    {_format(new Date(sess.date[0].value), 'MM/dd/yyyy')}
                                </div>
                            </div>
                            <hr />
                            {sess.comment[0].value}
                        </div>
                    ))
                ) : (
                    <div className={style.commentBox}>
                        <div className={style.noComments}>No comments entered</div>
                    </div>
                ))}
        </>
    );
};

Comments.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,

    // redux
    filters: PropTypes.object,
    setWidgetsLoaded: PropTypes.func,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
Comments.defaultProps = {
    filters: {},
};

export default Comments;
