import { UserCheck } from 'cccisd-laravel-nexus';

export const userCheck = async (values, role) => {
    const checkUserDelay = 1000;

    if (!values.username) {
        return;
    }

    const org = values.org;
    const groupingUnit = values.groupingUnit;
    const school = values.site;

    const getGroupType = () => {
        switch (role) {
            case 'orgAdmin':
                return org;
            case 'guAdmin':
                return groupingUnit;
            case 'supervisor':
                return groupingUnit;
            case 'siteAdmin':
                return school;
            case 'instructor':
                return school;
            default:
                return null;
        }
    };

    let checkUserData = {
        username: values.username,
        group: getGroupType(role),
        role,
    };

    let userCheckResponse = await UserCheck(checkUserData, checkUserDelay);

    if (userCheckResponse.available === true) {
        values.available = true;

        if (userCheckResponse.user) {
            values.available = false;
            values.existingUser = true;

            values.username = userCheckResponse.user.username;
            values.firstName = userCheckResponse.user.first_name;
            values.lastName = userCheckResponse.user.last_name;
        }
    } else {
        values.existingUser = false;
    }
};

// previously trained users need their training field in this shape
// const training = {
//     ael: true,
//     csby: true,
//     lifestories_k2: true,
//     lifestories_35: true,
//     ssgrin_ec: true,
//     ssgrin_35: true,
//     ssgrin_k2: true,
//     ssgrin_a: true,
//     ssgrin_hfa: true,
// };

export const getInitialValues = initialValues => {
    // ael_1, ael_2, ael_3, ael_4, and ael_5 all have the same training
    if (initialValues && initialValues.ael_k) {
        initialValues.ael = true;
    }

    // ssgrin_k2 and ssgrin_35 have the same training
    if (initialValues && initialValues.ssgrin_k2) {
        initialValues.ssgrin_k5 = true;
    }
    return initialValues;
};
