import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import ExportStatus from '../Status/ExportStatus.js';
import licenseQuery from './productLicenses.graphql';
import assignmentCourseQuery from './assignmentCourseQuery.graphql';
import CompletedDateRender from './CompletedDateRender';
import AssignmentStatus from './AssignmentStatus';
import ScoreRender from './ScoreRender';
import CertificateRender from 'js/components/CertificateRender';
const Fortress = window.cccisd.fortress;

const CourseProgress = props => {
    const [projectId, setProjectId] = useState(null);
    const [assignmentList, setAssignmentList] = useState([]);

    const [assignmentSettings, setAssignmentSettings] = useState([]);
    const [deploymentList, setDeploymentList] = useState([]);
    const [fullDeploymentList, setFullDeploymentList] = useState(null);
    const [deploymentKey, setDeploymentKey] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function start() {
            await getLicenseData();
        }
        start();
    }, []);

    async function getLicenseData() {
        const res = await apollo.query({
            query: licenseQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: Fortress.user.acting.id,
            },
        });

        const productList = res.data.products.productList;
        const deployments = res.data.deployments.deploymentList;
        const licensedProducts = [];
        const deploymentIds = deployments.map(item => item.deploymentId);

        setFullDeploymentList(deployments);
        setDeploymentList(deploymentIds);

        productList.forEach(item => {
            if (item.ownedLicense.licenseId) {
                licensedProducts.push(`${item.handle}_training`);
            }
        });

        await getCourseData(licensedProducts);
    }

    async function getCourseData(availableProducts) {
        const res = await apollo.query({
            query: assignmentCourseQuery,
            fetchPolicy: 'network-only',
            variables: {
                availableProducts,
            },
        });

        const settings = res.data.groups.questproject.assignmentList.map(item => {
            return { id: item.assignmentId, settings: item.settings };
        });

        const dKey = [];

        setAssignmentSettings(settings);
        setProjectId(res.data.groups.questproject.group.groupId);

        const key = {
            ssgrin_35_training: 'S.S.GRIN 3-5',
            ssgrin_k2_training: 'S.S.GRIN K-2',
            ssgrin_ec_training: 'S.S.GRIN EC',
        };
        res.data.groups.questproject.assignmentList.forEach(assignment => {
            assignment.deploymentList.forEach(deployment => {
                dKey.push({
                    id: deployment.deploymentId,
                    label: key[deployment.deploymentHandle],
                });
            });
        });

        setDeploymentKey(dKey);

        let dList = [];

        const aList = res.data.groups.questproject.assignmentList.map(assignment => {
            dList.push(
                ...assignment.deploymentList.map(deployment => {
                    return deployment.deploymentId;
                })
            );
            return assignment.assignmentId;
        });

        setAssignmentList(aList);
        setLoading(false);
    }

    const setColumnDefinitions = columns => {
        const currentCourse = deploymentKey.find(item => item.id === props.deploymentId);
        const currentSettings = assignmentSettings.find(item => item.id === props.assignmentId);

        const currentProductHandle = fullDeploymentList
            .find(item => item.deploymentId === props.deploymentId)
            .handle.replace('_training', '');

        columns[5].render = p => {
            return <AssignmentStatus {...p} currentCourse={currentCourse} productHandle={currentProductHandle} />;
        };
        columns[5].sort = false;
        columns[5].filter = false;

        columns[6].render = p => {
            return (
                <CompletedDateRender
                    {...p}
                    dataName={columns[6].otherName}
                    currentCourse={currentCourse}
                    productHandle={currentProductHandle}
                />
            );
        };

        columns[7].render = p => {
            return (
                <CertificateRender
                    {...p}
                    currentCourse={currentCourse}
                    currentSettings={currentSettings}
                    productHandle={currentProductHandle}
                />
            );
        };

        columns[8].render = p => {
            return <ScoreRender {...p} currentCourse={currentCourse} productHandle={currentProductHandle} />;
        };
        return columns;
    };
    return (
        !loading && (
            <div>
                <h1>Course Progress</h1>
                <ExportStatus
                    setColumnDefinitions={setColumnDefinitions}
                    projectId={projectId}
                    hideProjectDropdown
                    deploymentIdWhitelist={deploymentList}
                    assignmentId={assignmentList[0]}
                    assignmentIdWhitelist={assignmentList}
                    labelForQuest="Course"
                    labelForDeployment="Training"
                    labelForSurvey="Training"
                />
            </div>
        )
    );
};
const mapStateToProps = state => ({
    assignmentId: state.assignment.admin.currentAssignmentId,
    deploymentId: state.assignment.admin.currentDeploymentId,
});

CourseProgress.propTypes = {
    assignmentId: PropTypes.number,
    deploymentId: PropTypes.number,
};

export default connect(mapStateToProps)(CourseProgress);
