import React from 'react';
import { Field, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    title: 'Progress Report',
    description:
        "The Progress Report shows each participant's progress toward individualized targeted issues and behaviors as a result of their participation in the intervention. Group leaders can use this information to ensure that participants are progressing as expected.",
};

const Component = props => {
    return (
        <div>
            <Field name="title" component={CccisdWysiwyg} label="Title" />
            <Field name="description" component={CccisdWysiwyg} label="Description" />
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
