import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, CccisdInput, CccisdSelect } from 'cccisd-formik';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import CompletedTrainingsFields from '../../CompletedTrainingsFields/index.js';
import RequiredFieldLabel from '../RequiredFieldLabel/index.js';
import { userCheck, getInitialValues } from '../manageFormHelpers.js';
import style from '../style.css';

import organizationsQuery from './organizations.graphql';

const OrgAdminForm = props => {
    const [loading, setLoading] = useState(true);
    const [orgs, setOrgs] = useState([]);

    const { onSubmit, closeModal, operation, initialValues } = props;

    useEffect(() => {
        async function start() {
            await getOrgs();
        }
        start();
    }, []);

    async function getOrgs() {
        const res = await apollo.query({
            query: organizationsQuery,
            variables: {},
            fetchPolicy: 'network-only',
        });

        const arrOfOrgs = res.data.groups.organizationList;

        if (arrOfOrgs.length < 1) {
            return <Loader loading type="inline" />;
        }
        setOrgs(arrOfOrgs);
        setLoading(false);
    }

    const onHandleSubmit = async values => {
        const training = { ...values };

        delete training.username;
        delete training.firstName;
        delete training.lastName;
        delete training.org;
        delete training.available;
        delete training.existingUser;

        await onSubmit(values, training);
        closeModal();
    };

    const optionsBuilder = items => {
        return items.map(({ group: { groupId, label } }) => {
            return {
                value: groupId,
                label,
            };
        });
    };

    const validate = values => {
        try {
            const errors = {};

            const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!values.username) {
                errors.username = 'Username is required.';
            }
            if (!values.firstName) {
                errors.firstName = 'First Name is required.';
            }
            if (!values.lastName) {
                errors.lastName = 'Last Name is required.';
            }
            if (!values.org) {
                errors.org = 'Organization is required';
            }
            if (values.username && !regexEmail.test(values.username)) {
                errors.username = 'Please input a valid email.';
            }

            return errors;
        } catch (e) {
            console.error(e);
        }
    };

    return loading ? (
        <Loader loading />
    ) : (
        <Formik
            onSubmit={e => onHandleSubmit(e)}
            validate={e => validate(e)}
            initialValues={getInitialValues(initialValues)}
        >
            {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                    {values.available ? <div className={style.usernameValid}>Username available.</div> : null}
                    {values.existingUser && operation === 'create' ? (
                        <div className="alert alert-info" role="alert" style={{ textAlign: 'center' }}>
                            User already exists - filling in details.
                        </div>
                    ) : null}
                    <Field
                        name="username"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Username (Email):</RequiredFieldLabel>}
                        validateOnBlur={userCheck(values, 'orgAdmin')}
                        disabled={values.existingUser || operation === 'edit'}
                    />
                    <Field
                        name="firstName"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>First Name:</RequiredFieldLabel>}
                    />
                    <Field
                        name="lastName"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Last Name:</RequiredFieldLabel>}
                    />
                    <Field
                        name="org"
                        component={CccisdSelect}
                        label={<RequiredFieldLabel isRequired>Organization:</RequiredFieldLabel>}
                        options={[
                            {
                                value: '',
                                label: '-- Please select an Organization --',
                            },
                            ...optionsBuilder(orgs),
                        ]}
                        onChange={event => {
                            const value = event.target.value;
                            setFieldValue('org', +value);
                        }}
                    />
                    <hr />
                    <CompletedTrainingsFields labelText="Has this user ever completed the trainings for these interventions?" />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1em',
                        }}
                    >
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

OrgAdminForm.propTypes = {
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    operation: PropTypes.string,
    productId: PropTypes.number,
    productLabel: PropTypes.string,
    initialValues: PropTypes.object,
};

export default OrgAdminForm;
