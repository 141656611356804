import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import _xor from 'lodash/xor';
import Table from 'cccisd-graphql-table';
import Loader from 'cccisd-loader';
import getImportantRespondentFields from '../../../helpers/getImportantRespondentFields.js';
import languageColumn from '../../../helpers/languageColumn.js';
import {
    getCurrentAssignment,
    getCurrentAssignmentSurveys,
    getCurrentDeployments,
    getCurrentProject,
    getCurrentProjectDeployments,
} from '../../../selectors/admin.js';
import respondentsByDeploymentIdentifiable from './respondentsByDeployment_identifiable.graphql';
import respondentsByDeploymentNoIdentifiable from './respondentsByDeployment_noIdentifiable.graphql';
import respondentsByGroupIdentifiable from './respondentsByGroup_identifiable.graphql';
import respondentsByGroupNoIdentifiable from './respondentsByGroup_noIdentifiable.graphql';
import respondentsFromResponsesIdentifiable from './respondentsFromResponses_identifiable.graphql';
import respondentsFromResponsesNoIdentifiable from './respondentsFromResponses_noIdentifiable.graphql';
import CertificateRender from '../CertificateRender/index.js';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const STATUS_MAP = ['Not Started', 'In Progress', 'Completed'];

class AssignmentStatusTable extends React.Component {
    static propTypes = {
        // from redux state
        assignment: PropTypes.object,
        currentDeploymentIds: PropTypes.array,
        currentDeployments: PropTypes.array,
        pawnIdWhitelist: PropTypes.arrayOf(PropTypes.number),
        project: PropTypes.object,
        projectDeployments: PropTypes.array,
        surveys: PropTypes.array,

        modules: PropTypes.array,
        showBy: PropTypes.string.isRequired,
        hideSystemId: PropTypes.bool,

        // From ExportStatus
        renderCertificate: PropTypes.func,
        setColumnDefinitions: PropTypes.func,
    };

    static defaultProps = {
        // from redux state
        assignment: {},
        projectDeployments: [],
        currentDeploymentIds: [],
        hideSystemId: false,
        renderCertificate: null,
        setColumnDefinitions: null,
    };

    state = {
        isLoading: true,

        assignmentProgressPath: '',
        pawnIdPath: '',
        userPath: '',
        query: '',
        variables: {},
        columns: [],

        deploymentType: null,
        closedRoster: null,
        roleHandles: [],
    };

    /* /////////////////////////////////////////////////////////////////////////
    // LIFECYCLE METHODS //////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    componentDidMount() {
        this.setDeploymentParams();
    }

    componentDidUpdate(prevProps) {
        // if the list of deployment IDs, surveysWhiteList, or pawnIdWhitelist changed...
        if (
            !_isEmpty(_xor(prevProps.currentDeploymentIds, this.props.currentDeploymentIds)) ||
            !_isEmpty(_xor(prevProps.surveys, this.props.surveys)) ||
            !_isEmpty(_xor(prevProps.pawnIdWhitelist, this.props.pawnIdWhitelist))
        ) {
            this.setDeploymentParams();
        } else if (prevProps.showBy !== this.props.showBy) {
            this.setGraphQLColumns();
        }
    }

    /* /////////////////////////////////////////////////////////////////////////
    // GENERAL METHODS ////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    canSeeIdentifiable = () => {
        const { assignment } = this.props;
        return Fortress.hasAccess('quest.view_identifiable_data', assignment.groupId);
    };

    setDeploymentParams = async () => {
        await this.setState({ isLoading: true });

        const { projectDeployments, currentDeploymentIds } = this.props;

        if (!currentDeploymentIds.length) {
            this.setState({ isLoading: false });
            return;
        }

        const selectedDeployment = projectDeployments.find(
            item => !item.archived && currentDeploymentIds[0] === item.deploymentId
        );
        if (!selectedDeployment) {
            this.setState({ isLoading: false });
            return;
        }

        await this.setState({
            deploymentType: selectedDeployment.type,
            closedRoster: selectedDeployment.settings.closedRoster,
            roleHandles: [selectedDeployment.roleHandle],
        });

        await this.setGraphQLParams();
        await this.setGraphQLColumns();
    };

    setGraphQLParams = async () => {
        const { assignment, currentDeploymentIds, pawnIdWhitelist } = this.props;
        const { deploymentType, closedRoster, roleHandles } = this.state;

        const canSeeIdentifiable = this.canSeeIdentifiable();
        let assignmentProgressPath = 'assignmentProgress.';
        let pawnIdPath = 'pawn.';
        let userPath = '';
        let query = canSeeIdentifiable ? respondentsByGroupIdentifiable : respondentsByGroupNoIdentifiable;
        const deploymentId = currentDeploymentIds.length ? currentDeploymentIds[0] : 0;
        let variables = {
            deploymentId,
            roleHandle: roleHandles,
        };

        switch (deploymentType) {
            case 'anon':
                assignmentProgressPath = '';
                pawnIdPath = '';
                userPath = 'pawn.';
                query = canSeeIdentifiable
                    ? respondentsFromResponsesIdentifiable
                    : respondentsFromResponsesNoIdentifiable;
                variables = {
                    deploymentId,
                };
                break;
            case 'known':
                pawnIdPath = 'pawn.pawn.';
                userPath = 'pawn.';
                query = canSeeIdentifiable
                    ? respondentsByDeploymentIdentifiable
                    : respondentsByDeploymentNoIdentifiable;
                variables = {
                    deploymentId,
                };
                break;
            case 'group':
                if (closedRoster) {
                    pawnIdPath = 'pawn.pawn.';
                    userPath = 'pawn.';
                    query = canSeeIdentifiable
                        ? respondentsByDeploymentIdentifiable
                        : respondentsByDeploymentNoIdentifiable;
                    variables = {
                        deploymentId,
                    };
                }
                break;
            default:
                break;
        }

        variables.projectId = assignment.groupId;
        variables.pawnIds = pawnIdWhitelist;

        await this.setState({
            assignmentProgressPath,
            pawnIdPath,
            userPath,
            query,
            variables,
        });
    };

    setGraphQLColumns = async () => {
        const { assignment, project, showBy, hideSystemId, setColumnDefinitions } = this.props;
        const { assignmentProgressPath, deploymentType, pawnIdPath, userPath, roleHandles } = this.state;

        const canSeeIdentifiable = this.canSeeIdentifiable();

        const appdefRoles = AppDefs.pawn.roles || [];

        let columns = [];

        // System ID Column
        if (!hideSystemId) {
            columns.push({
                name: `${pawnIdPath}pawnId`,
                label: 'System ID',
                sort: true,
                filter: true,
            });
        }

        let roleDefs = appdefRoles.filter(item => roleHandles.includes(item.handle));

        // Important Fields columns (roles.json)
        for (const roleDef of roleDefs) {
            let importantFields = roleDef.importantFields || [];
            if (roleDef.handle === 'respondent') {
                importantFields = getImportantRespondentFields({ importantFields, project });
            }
            for (const f of importantFields) {
                if (!canSeeIdentifiable && f.identifiable) {
                    continue;
                }

                columns.push({
                    name: `${userPath}important.${f.path}`,
                    label: f.label,
                    sort: true,
                    filter: true,
                });
            }
        }
        // ----------------------------------------------

        // Anon deployment role column
        if (deploymentType === 'anon') {
            let roleFilterOptions = appdefRoles.map(p => {
                return {
                    label: p.label,
                    value: p.handle,
                };
            });
            columns.push({
                name: 'pawn.pawn.role',
                label: 'Role',
                sort: true,
                filter: true,
                filterSettings: {
                    type: 'selectbox',
                    options: roleFilterOptions,
                },
                render: ({ value }) => {
                    const roleDef = appdefRoles.find(r => r.handle === value);
                    return roleDef ? roleDef.label : value;
                },
            });
        }
        // ---------------------------------------------------

        columns = _uniqBy(columns, 'name');

        // Languages column
        if (assignment.settings.languages.length > 1) {
            columns.push(languageColumn({ name: `${assignmentProgressPath}language` }));
        }

        // Survey List
        if (assignment.surveylistList.length > 1) {
            columns.push({
                name: `${assignmentProgressPath}flowList`,
                label: 'Surveylist',
                sort: true,
                filter: true,
            });
        }
        const hasFinalTest = assignment.settings.navigationSettings.finalTest.toggle;

        const isCourseWrapper = assignment.settings.navigation.includes('courseWrapper');

        const certificateToggle =
            assignment.settings.navigationSettings.certificate &&
            assignment.settings.navigationSettings.certificate.toggle;

        switch (showBy) {
            case 'assignment':
                // Status / Certificate Columns
                this.addAssignmentStatusColumns(columns);
                if (isCourseWrapper && certificateToggle) {
                    this.addCertificateColumn(columns);
                }
                if (hasFinalTest) {
                    this.addScoreDuration(columns);
                }
                break;
            case 'module':
                this.addModuleStatusColumns(columns);
                break;
            case 'survey':
                this.addSurveyStatusColumns(columns);
                break;
            default:
                break;
        }
        const finalColumns = setColumnDefinitions ? setColumnDefinitions(columns) : columns;

        await this.setState({
            columns: finalColumns,
            isLoading: false,
        });
    };

    /* /////////////////////////////////////////////////////////////////////////
    // COLUMN HELPERS /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */
    addScoreDuration = columns => {
        const { assignmentProgressPath } = this.state;

        columns.push({
            name: `${assignmentProgressPath}variables`,
            label: 'Score',
            className: 'text-center',
            hideInCsv: true,
            render: ({ value, row }) => {
                const assignmentHandle = row['assignmentProgress.assignmentHandle'];
                const testScore = row[`assignmentProgress.variables.${assignmentHandle}_test_score`];

                if (!testScore) {
                    return null;
                }

                return <div>{testScore}</div>;
            },
        });

        columns.push({
            name: `${assignmentProgressPath}assignmentHandle`,
            label: 'Duration',
            className: 'text-center',
            hideInCsv: true,
            render: ({ value, row }) => {
                const assignmentHandle = row['assignmentProgress.assignmentHandle'];
                let duration = row[`assignmentProgress.variables.${assignmentHandle}_duration`];

                if (!duration) {
                    return null;
                }
                const hours = Math.floor(duration / 3600);
                const minusHours = duration - hours * 3600;
                const minutes = Math.floor(minusHours / 60);
                const seconds = minusHours - minutes * 60;

                const strPadLeft = (string, pad, length) => {
                    return (new Array(length + 1).join(pad) + string).slice(-length);
                };

                const finalTime =
                    strPadLeft(hours, '0', 2) + ':' + strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2);

                return <div>{finalTime}</div>;
            },
        });
    };

    addAssignmentStatusColumns = columns => {
        const { assignment } = this.props;
        const { userPath, assignmentProgressPath } = this.state;
        columns.push({
            name: `${assignmentProgressPath}lastVisitedAt`,
            label: 'Last Visited',
            sort: true,
            filter: true,
            filterSettings: { type: 'date' },
        });

        columns.push({
            name: `${userPath}assignmentProgressStatus.assignment${assignment.assignmentId}`,
            label: 'Status',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                placeholder: '-- Choose one --',
                options: [
                    { label: STATUS_MAP[0], value: 0 },
                    { label: STATUS_MAP[1], value: 1 },
                    { label: STATUS_MAP[2], value: 2 },
                ],
            },
            render: ({ value, row }) => {
                if (value > 0) {
                    // let key = `assignmentProgressStatus.assignment${assignment.assignmentId}_lastVisit`;
                    return <div>{STATUS_MAP[Number.parseInt(value, 10)]}</div>;
                }

                return STATUS_MAP[0];
            },
        });

        columns.push({
            name: `assignmentProgress.completedDate`,
            label: 'Completed At',
            otherName: `assignmentProgressStatus.assignment${assignment.assignmentId}_lastVisit`,
            // sort: true,
            filter: true,
            filterSettings: { type: 'date' },
        });
    };

    addCertificateColumn = columns => {
        const { userPath } = this.state;

        const deploymentId = this.state.variables.deploymentId;
        const deployment = this.props.currentDeployments.find(item => item.deploymentId === deploymentId);
        columns.push({
            name: `${userPath}pawn.pawnHash`,
            label: 'Certificate',
            className: 'text-center',
            hideInCsv: true,
            render: ({ value, row }) => {
                const certificateProps = {
                    actingPawnId: row['pawn.pawnId'] ? row['pawn.pawnId'] : row['pawn.pawn.pawnId'],
                    actingPawnHash: row['pawn.pawnHash'] ? row['pawn.pawnHash'] : row['pawn.pawn.pawnHash'],
                    deploymentId: this.state.variables.deploymentId,
                    deploymentSettings: deployment.settings,
                    language: row['assignmentProgress.language'] || row.language,
                };

                return (
                    <CertificateRender
                        {...certificateProps}
                        row={row}
                        renderCertificate={this.props.renderCertificate}
                    />
                );
            },
        });
    };

    addModuleStatusColumns = columns => {
        const { assignment, modules } = this.props;
        const { userPath } = this.state;

        for (const m of modules) {
            columns.push({
                name: `${userPath}moduleProgressStatus.assignment${assignment.assignmentId}_module${m.id}`,
                label: m.title,
                sort: true,
                filter: true,
                filterSettings: {
                    type: 'selectbox',
                    placeholder: '-- Choose one --',
                    options: [
                        { label: STATUS_MAP[0], value: 0 },
                        { label: STATUS_MAP[1], value: 1 },
                        { label: STATUS_MAP[2], value: 2 },
                    ],
                },
                render: ({ value, row }) => {
                    if (value > 0) {
                        let key = `moduleProgressStatus.assignment${assignment.assignmentId}_module${m.id}_lastVisit`;
                        return (
                            <div>
                                {STATUS_MAP[Number.parseInt(value, 10)]}
                                <br />
                                {row[key]}
                            </div>
                        );
                    }

                    return STATUS_MAP[0];
                },
            });
        }
    };

    addSurveyStatusColumns = columns => {
        const { surveys } = this.props;
        const { userPath } = this.state;

        let uniqueHandles = _sortBy(
            _uniqBy(
                surveys.filter(s => !s.archived),
                'surveyHandle'
            ),
            'position'
        );

        for (const s of uniqueHandles) {
            columns.push({
                name: `${userPath}surveyProgressStatus.${s.surveyHandle}`,
                label: s.label,
                sort: true,
                filter: true,
                filterSettings: {
                    type: 'selectbox',
                    placeholder: '-- Choose one --',
                    options: [
                        { label: STATUS_MAP[0], value: 0 },
                        { label: STATUS_MAP[1], value: 1 },
                        { label: STATUS_MAP[2], value: 2 },
                    ],
                },
                render: ({ value, row }) => {
                    if (value > 0) {
                        let key = `surveyProgressStatus.${s.surveyHandle}_lastVisit`;
                        return (
                            <div>
                                {STATUS_MAP[Number.parseInt(value, 10)]}
                                <br />
                                {row[key]}
                            </div>
                        );
                    }

                    return STATUS_MAP[0];
                },
            });
        }
    };

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    render() {
        const { columns, isLoading, pawnIdPath, query, variables } = this.state;

        if (isLoading) {
            return <Loader loading />;
        }

        return <Table columns={columns} query={query} rowKey={`${pawnIdPath}pawnId`} graphqlVariables={variables} />;
    }
}

const mapStateToProps = (state, props) => {
    const { surveyHandleWhitelist } = props;

    let surveys = getCurrentAssignmentSurveys(state.assignment.admin);
    if (Array.isArray(surveyHandleWhitelist)) {
        surveys = surveys.filter(survey => surveyHandleWhitelist.includes(survey.surveyHandle));
    }

    return {
        assignment: getCurrentAssignment(state.assignment.admin),
        currentDeploymentIds: state.assignment.admin.currentDeploymentIds,
        currentDeployments: getCurrentDeployments(state.assignment.admin),
        project: getCurrentProject(state.assignment.admin),
        projectDeployments: getCurrentProjectDeployments(state.assignment.admin),
        surveys,
    };
};

export default connect(mapStateToProps)(AssignmentStatusTable);
