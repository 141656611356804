import React from 'react';
import { Field, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    title: 'Group Log Report: Compliance',
    description:
        'The Log Report summarizes attendance and participation data across all sessions for each participant in the group. Ratios for each component are presented as the number of “Yes” responses out of the total number possible.',
};

const Component = props => {
    return (
        <div>
            <Field name="title" component={CccisdWysiwyg} label="Title" />
            <Field name="description" component={CccisdWysiwyg} label="Description" />
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
