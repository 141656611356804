import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import _xor from 'lodash/xor';
import SelectList from 'react-widgets/lib/SelectList.js';
import Button from 'cccisd-click-button';
import IconExpand from 'cccisd-icons/svg/enlarge7';
import IconCollapse from 'cccisd-icons/svg/shrink7';
import {
    getCurrentProject,
    getCurrentProjectAssignments,
    getCurrentAssignment,
    getCurrentAssignmentDeployments,
    getCurrentAssignmentSurveys,
} from '../../selectors/admin.js';
import {
    setCurrentAssignmentId,
    setCurrentDeploymentIds,
    setCurrentSurveyHandles,
    setSelectedDeploymentIds,
} from '../../reducers/admin.js';
import FilterBlock from '../FilterBlock';
import DetectClickLocation from 'cccisd-detect-click-location';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const Header = ({
    projectList,
    project,
    assignment,
    selectedDeploymentIds,
    surveyHandles,
    dispatch,
    leftSection,
    rightSection,
    setExpandedAll,
    match,
    history,
    filter,
    changeFilter,
    showQuestCount = true,
    isExported,

    customSwitchProject,
    labelForProject = 'Quest Project',
    labelForProjectPlural = 'Quest Projects',
    limitProjectsByPermissions = [],
    projectIdWhitelist = null,
    showProjectDropdown = false,

    allowAllQuests = false,
    assignmentIdWhitelist = null,
    customSwitchQuest,
    labelForQuest = 'Quest',
    showQuestDropdown = false,

    currentDeploymentId,
    customSwitchDeployment,
    deploymentDropdownNotMulti = false,
    deploymentDropdownSingleType = false,
    labelForDeployment = 'Data Collection Wave',
    showDeploymentDropdown = false,
    deploymentIdWhitelist = null,

    allowAllSurveys = true,
    customSwitchSurvey,
    labelForSurvey = 'Survey',
    showSurveyDropdown = false,
}) => {
    const [deploymentDropdownVisible, setDeploymentDropdownVisible] = useState(false);
    const [surveyDropdownVisible, setSurveyDropdownVisible] = useState(false);
    const questCount = _get(project, 'flows.assignmentList.length', 0);

    async function switchProject(event) {
        const newProjectId = Number(event.target.value);
        if (newProjectId === project.projectId) {
            return;
        }
        if (customSwitchProject) {
            await customSwitchProject(newProjectId);
            return;
        }

        // replace :projectId and remove all other params
        history.push(match.path.replace(/:projectId\??/, newProjectId).replace(/\/?:[\w\d]+\??/gi, ''));
    }

    const assignments = useSelector(state => getCurrentProjectAssignments(state.assignment.admin));
    const activeAssignments = assignments.filter(
        item =>
            !item.archived &&
            (!Array.isArray(assignmentIdWhitelist) ||
                assignmentIdWhitelist.map(i => Number.parseInt(i, 10)).includes(Number.parseInt(item.assignmentId, 10)))
    );

    async function switchAssignment(event) {
        if (customSwitchQuest) {
            await customSwitchQuest(event.target.value);
            return;
        }

        dispatch(setCurrentAssignmentId(Number(event.target.value)));
    }

    const deployments = useSelector(state => getCurrentAssignmentDeployments(state.assignment.admin));
    const activeDeployments = deployments.filter(
        item =>
            !item.archived &&
            (!Array.isArray(deploymentIdWhitelist) ||
                deploymentIdWhitelist.map(i => Number.parseInt(i, 10)).includes(Number.parseInt(item.deploymentId, 10)))
    );

    async function switchSurveyHandleMulti(value) {
        let handles = value.map(item => _get(item, 'surveyHandle', item));

        dispatch(setCurrentSurveyHandles(handles));
    }

    const selectedDeployments = activeDeployments.filter(item => selectedDeploymentIds.includes(item.deploymentId));
    const selectedDeploymentType = selectedDeployments.length > 0 ? selectedDeployments[0].type : null;
    const deploymentClosedRoster =
        selectedDeployments.length > 0 ? selectedDeployments[0].settings.closedRoster : false;

    // there could be a deployment set in state, but if no deployment dropdown
    // we want the survey dropdown to reflect all surveys in the assignment
    const assignmentSurveys = useSelector(state => getCurrentAssignmentSurveys(state.assignment.admin));
    const activeSurveys = _sortBy(
        _uniqBy(
            assignmentSurveys.filter(s => !s.archived),
            'surveyHandle'
        ),
        'position'
    );

    function setCheckedSurveysByDeployments(deploymentIds) {
        const currentCheckedSurveyHandles = surveyHandles;

        const currentCheckedDeployments = deployments.filter(item => selectedDeploymentIds.includes(item.deploymentId));
        const currentHandles = currentCheckedDeployments.map(item => _get(item, 'settings.options', []));
        const currentUniqueSurveyHandles = _uniq([].concat(...currentHandles));

        const targetCheckedDeployments = deployments.filter(item => deploymentIds.includes(item.deploymentId));
        const targetHandles = targetCheckedDeployments.map(item => _get(item, 'settings.options', []));
        const targetUniqueSurveyHandles = _uniq([].concat(...targetHandles));

        if (
            currentCheckedSurveyHandles.length === 0 ||
            _isEmpty(_xor(currentCheckedSurveyHandles, currentUniqueSurveyHandles))
        ) {
            switchSurveyHandleMulti(
                _intersection(
                    targetUniqueSurveyHandles,
                    activeSurveys.map(s => s.surveyHandle)
                )
            );
        }
    }

    const assignmentDisplayName =
        labelForQuest !== null ? labelForQuest : _get(AppDefs, 'app.vocabulary.assignment', 'Quest');
    const deploymentDisplayName =
        labelForDeployment !== null
            ? labelForDeployment
            : _get(AppDefs, 'app.vocabulary.deployment', 'Data Collection Wave');
    const surveyDisplayName =
        labelForSurvey !== null ? labelForSurvey : _get(AppDefs, 'app.vocabulary.survey', 'Survey');

    const disabledDeploymentIds =
        !deploymentDropdownSingleType || selectedDeploymentType === null
            ? []
            : activeDeployments
                  .filter(
                      item =>
                          item.type !== selectedDeploymentType || item.settings.closedRoster !== deploymentClosedRoster
                  )
                  .map(item => item.deploymentId);
    const currentDeploymentIds = selectedDeploymentIds.filter(item => !disabledDeploymentIds.includes(item));

    function switchDeploymentMulti(value) {
        let deploymentIds = value.map(item => item.deploymentId);

        let current = [...deploymentIds];

        // If dropdown is restricted to a single type AND there is at least one
        // deployment selected, then there might be deployments to disable
        if (deploymentDropdownSingleType && value.length) {
            let selected = activeDeployments.filter(item => deploymentIds.includes(item.deploymentId));

            if (selected.length) {
                const deploymentType = selected[0].type;
                const closedRoster = selected[0].settings.closedRoster;

                const disabled = activeDeployments
                    .filter(item => item.type !== deploymentType || item.settings.closedRoster !== closedRoster)
                    .map(item => item.deploymentId);

                current = deploymentIds.filter(item => !disabled.includes(item));
            }
        }

        if (showSurveyDropdown) {
            setCheckedSurveysByDeployments(deploymentIds);
        }

        dispatch(setSelectedDeploymentIds(deploymentIds));
        dispatch(setCurrentDeploymentIds(current));
    }

    function switchDeploymentSingle(event) {
        dispatch(setSelectedDeploymentIds([Number(event.target.value)]));
        dispatch(setCurrentDeploymentIds([Number(event.target.value)]));
    }

    function getDeploymentButtonLabel() {
        if (activeDeployments.length === 0) {
            return `No ${deploymentDisplayName} to select`;
        }
        if (currentDeploymentIds.length === 1) {
            const d = activeDeployments.find(item => item.deploymentId === currentDeploymentIds[0]);
            return d ? getDeploymentLabel(d) : '';
        }

        return `${currentDeploymentIds.length} selected`;
    }

    function getSurveyButtonLabel() {
        if (surveyHandles.length === 1) {
            const s = activeSurveys.find(item => item.surveyHandle === surveyHandles[0]);
            return s ? s.label : '';
        }

        return `${surveyHandles.length || 'All'} selected`;
    }

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER-RELATED /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    function renderProjectDropdown() {
        if (!showProjectDropdown) {
            return null;
        }
        let narrowedProjectList = !Array.isArray(projectIdWhitelist)
            ? projectList
            : projectList.filter(p =>
                  projectIdWhitelist.map(i => Number.parseInt(i, 10)).includes(Number.parseInt(p.groupId, 10))
              );
        narrowedProjectList = narrowedProjectList.filter(
            p =>
                !isExported ||
                (project && p.groupId === project.groupId) ||
                Fortress.hasAnyAccess(limitProjectsByPermissions, p.groupId)
        );

        return narrowedProjectList.length === 0 ? (
            <select className="form-control" style={{ width: 'auto', maxWidth: '200px' }} disabled>
                <option>There are no {labelForProjectPlural}</option>
            </select>
        ) : (
            <div>
                <label htmlFor="cccisd-assignment-project-dropdown">{labelForProject}</label>
                <select
                    id="cccisd-assignment-project-dropdown"
                    className="form-control"
                    style={{ width: 'auto', maxWidth: '200px' }}
                    value={(project && project.groupId) || 0}
                    onChange={switchProject}
                >
                    {narrowedProjectList.map(item => (
                        <option key={item.groupId} value={item.groupId}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

    const renderExpandCollapseButtons =
        questCount > 0 && setExpandedAll ? (
            <div>
                <button
                    type="button"
                    className={'btn btn-default ' + style.button}
                    id="cccisd-expand-all-button"
                    onClick={() => dispatch(setExpandedAll(true))}
                    title="Expand All"
                >
                    <IconExpand />
                </button>{' '}
                <button
                    type="button"
                    className={'btn btn-default ' + style.button}
                    id="cccisd-collapse-all-button"
                    onClick={() => dispatch(setExpandedAll(false))}
                    title="Collapse All"
                >
                    <IconCollapse />
                </button>
            </div>
        ) : null;

    const renderQuestDropdown = !showQuestDropdown ? null : (
        <div>
            <label htmlFor="cccisd-assignment-assignment-dropdown">{assignmentDisplayName}</label>
            <select
                className="form-control"
                disabled={!activeAssignments.length}
                id="cccisd-assignment-assignment-dropdown"
                onChange={switchAssignment}
                style={{ width: 'auto', maxWidth: '200px' }}
                value={assignment ? assignment.assignmentId : 0}
            >
                {allowAllQuests && (
                    <option key="all_assignments" value="0">
                        All {assignmentDisplayName}s
                    </option>
                )}
                {!!activeAssignments.length &&
                    activeAssignments.map(item => (
                        <option key={item.assignmentId} value={item.assignmentId}>
                            {item.label}
                        </option>
                    ))}
                {!activeAssignments.length && (
                    <option key="empty" value="">
                        No {assignmentDisplayName} to select
                    </option>
                )}
            </select>
        </div>
    );

    function getDeploymentLabel(item) {
        let label = item.label;

        if (!label) {
            if (item.parentDeploymentId) {
                const parent = activeDeployments.find(d => d.deploymentId === Number(item.parentDeploymentId));
                label = parent ? parent.label : '';
            }
            if (!label) {
                // some other type of programmatically created deployment
                label = `${item.assignment.label} (${item.type} ${item.hash})`;
            }
        }

        if (item.timepoint && !item.parentDeploymentId) {
            label += ' - Timepoint ' + item.timepoint;
        }

        return label;
    }

    function renderDeploymentDropdown() {
        if (!showDeploymentDropdown) {
            return null;
        }
        if (deploymentDropdownNotMulti) {
            return activeDeployments.length === 0 ? (
                <div>
                    <label>{deploymentDisplayName}</label>
                    <select className="form-control" style={{ width: 'auto', maxWidth: '200px' }} disabled>
                        <option>No {deploymentDisplayName} to select</option>
                    </select>
                </div>
            ) : (
                <div>
                    <label htmlFor="cccisd-assignment-deployment-dropdown">{deploymentDisplayName}</label>
                    <select
                        id="cccisd-assignment-deployment-dropdown"
                        className="form-control"
                        style={{ width: 'auto', maxWidth: '200px' }}
                        value={currentDeploymentId}
                        onChange={switchDeploymentSingle}
                    >
                        {activeDeployments.map(item => (
                            <option key={item.deploymentId} value={item.deploymentId}>
                                {getDeploymentLabel(item)}
                            </option>
                        ))}
                    </select>
                </div>
            );
        }
        const showAllNoneButtons = deploymentDropdownVisible && activeDeployments.length > 1;
        const allSelectableDeployments = activeDeployments.filter(a => !disabledDeploymentIds.includes(a.deploymentId));
        return (
            <DetectClickLocation clickedOutside={() => setDeploymentDropdownVisible(false)}>
                <label htmlFor="cccisd-assignment-deployment-dropdown">{deploymentDisplayName}</label>
                <Button
                    title={getDeploymentButtonLabel()}
                    className="btn btn-default"
                    onClick={() => setDeploymentDropdownVisible(!deploymentDropdownVisible)}
                    style={{ display: 'block', width: '100%', textAlign: 'left' }}
                />
                {showAllNoneButtons && (
                    <div style={{ position: 'absolute' }}>
                        <Button
                            className="btn btn-xs btn-default"
                            isDisabled={allSelectableDeployments.length === selectedDeploymentIds.length}
                            onClick={() => switchDeploymentMulti(allSelectableDeployments)}
                            title="Select All"
                        />
                        <Button
                            className="btn btn-xs btn-default"
                            isDisabled={selectedDeploymentIds.length === 0}
                            onClick={() => switchDeploymentMulti([])}
                            title="Deselect All"
                        />
                    </div>
                )}
                <SelectList
                    multiple
                    data={activeDeployments}
                    disabled={disabledDeploymentIds}
                    textField={item => getDeploymentLabel(item)}
                    valueField="deploymentId"
                    onChange={switchDeploymentMulti}
                    value={selectedDeploymentIds}
                    style={{
                        display: deploymentDropdownVisible ? 'block' : 'none',
                        height: 'auto',
                        marginTop: showAllNoneButtons ? '2.5rem' : 0,
                        position: 'absolute',
                        zIndex: 10,
                    }}
                />
            </DetectClickLocation>
        );
    }

    function renderSurveyDropdown() {
        if (!showSurveyDropdown) {
            return null;
        }
        const showAllNoneButtons = surveyDropdownVisible && activeSurveys.length > 1;
        const surveyDropdownData = activeSurveys.map(item => ({
            surveyHandle: item.surveyHandle,
            surveyLabel: item.label,
        }));
        return (
            <DetectClickLocation clickedOutside={() => setSurveyDropdownVisible(false)}>
                <label htmlFor="cccisd-assignment-survey-dropdown">{surveyDisplayName}</label>
                <Button
                    title={getSurveyButtonLabel()}
                    className="btn btn-default"
                    onClick={() => setSurveyDropdownVisible(!surveyDropdownVisible)}
                    style={{ display: 'block', maxWidth: '400px', whiteSpace: 'normal' }}
                />
                {showAllNoneButtons && (
                    <div style={{ position: 'absolute' }}>
                        <Button
                            className="btn btn-xs btn-default"
                            isDisabled={surveyDropdownData.length === surveyHandles.length}
                            onClick={() => switchSurveyHandleMulti(surveyDropdownData)}
                            title="Select All"
                        />
                        <Button
                            className="btn btn-xs btn-default"
                            isDisabled={surveyHandles.length === 0}
                            onClick={() => switchSurveyHandleMulti([])}
                            title="Deselect All"
                        />
                    </div>
                )}
                <SelectList
                    multiple
                    data={surveyDropdownData}
                    textField="surveyLabel"
                    valueField="surveyHandle"
                    onChange={switchSurveyHandleMulti}
                    value={surveyHandles}
                    style={{
                        display: surveyDropdownVisible ? 'block' : 'none',
                        height: 'auto',
                        marginTop: showAllNoneButtons ? '2.5rem' : 0,
                        position: 'absolute',
                        zIndex: 11,
                    }}
                />
            </DetectClickLocation>
        );
    }

    return (
        <div>
            <div className={style.wrapper}>
                {renderProjectDropdown()}

                {showQuestCount && <div className={style.count}>Quests ({questCount})</div>}

                {renderExpandCollapseButtons}

                {changeFilter && <FilterBlock value={filter} onChange={value => dispatch(changeFilter(value))} />}
                {renderQuestDropdown}
                {renderDeploymentDropdown()}
                {renderSurveyDropdown()}

                {leftSection && <div>{leftSection}</div>}
                {rightSection && <div className={style.rightSection}>{rightSection}</div>}
            </div>
        </div>
    );
};

Header.propTypes = {
    projectList: PropTypes.array,
    project: PropTypes.object,
    assignment: PropTypes.object,
    selectedDeploymentIds: PropTypes.array,
    surveyHandles: PropTypes.array,
    dispatch: PropTypes.func,
    leftSection: PropTypes.node,
    rightSection: PropTypes.node,
    setExpandedAll: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
    filter: PropTypes.string,
    changeFilter: PropTypes.func,
    showQuestCount: PropTypes.bool,
    isExported: PropTypes.bool,

    customSwitchProject: PropTypes.func,
    labelForProject: PropTypes.string,
    labelForProjectPlural: PropTypes.string,
    limitProjectsByPermissions: PropTypes.array,
    projectIdWhitelist: PropTypes.array,
    showProjectDropdown: PropTypes.bool,

    allowAllQuests: PropTypes.bool,
    assignmentIdWhitelist: PropTypes.array,
    customSwitchQuest: PropTypes.func,
    labelForQuest: PropTypes.string,
    showQuestDropdown: PropTypes.bool,

    currentDeploymentId: PropTypes.number,
    customSwitchDeployment: PropTypes.func,
    deploymentDropdownNotMulti: PropTypes.bool,
    deploymentDropdownSingleType: PropTypes.bool,
    labelForDeployment: PropTypes.string,
    showDeploymentDropdown: PropTypes.bool,
    deploymentIdWhitelist: PropTypes.array,

    allowAllSurveys: PropTypes.bool,
    customSwitchSurvey: PropTypes.func,
    labelForSurvey: PropTypes.string,
    showSurveyDropdown: PropTypes.bool,
};

export default withRouter(
    connect(state => ({
        assignment: getCurrentAssignment(state.assignment.admin),
        currentDeploymentId: state.assignment.admin.currentDeploymentIds.length
            ? state.assignment.admin.currentDeploymentIds[0]
            : 0,
        project: getCurrentProject(state.assignment.admin),
        projectList: state.assignment.admin.projectList,
        selectedDeploymentIds: state.assignment.admin.selectedDeploymentIds,
        surveyHandles: state.assignment.admin.currentSurveyHandles,
    }))(Header)
);
