import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import { NotFound } from 'cccisd-laravel-boilerplate';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import { useParams } from 'cccisd-react-router';

import courseQuery from './courseQuery.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;

const Training = props => {
    const { errorLayout } = props;
    const { productHandle } = useParams();
    // const isFullIntervention = ['ssgrin_k2', 'ssgrin_ec', 'ssgrin_35'].includes(productHandle);

    const [isLoading, setIsLoading] = useState(true);
    const [show404, setShow404] = useState(false);

    const [deploymentId, setDeploymentId] = useState();
    const [pawn, setPawn] = useState();

    useEffect(() => {
        evaluateDeployment();
    }, []);

    /**
     * Get the deployment id and pawn data, which is needed to open
     * the course deployment for this user.
     */

    const evaluateDeployment = async () => {
        if (!productHandle) {
            setShow404(true);
            setIsLoading(false);
            return;
        }

        try {
            const result = await apollo.query({
                query: courseQuery,
                fetchPolicy: 'network-only',
                variables: {
                    productHandle,
                    pawnId: Fortress.user.acting.id,
                },
            });

            if (!result.data.flows.deployment.hash) {
                setShow404(true);
                setIsLoading(false);
                return;
            }

            setDeploymentId(result.data.flows.deployment.deploymentId);
            setPawn(Fortress.user.acting);
            setIsLoading(false);
        } catch (error) {
            setShow404(true);
        }
    };

    if (isLoading) {
        return <Loader loading />;
    }

    if (show404) {
        if (errorLayout) {
            let ErrorLayout = errorLayout;
            return (
                <ErrorLayout>
                    <NotFound />
                </ErrorLayout>
            );
        }
        return <NotFound />;
    }

    return (
        <>
            <DeploymentPlayer deploymentId={deploymentId} pawnId={pawn.id} pawnHash={pawn.random_hash} />
        </>
    );
};

Training.propTypes = {
    /** The layout to use for the 404 page */
    errorLayout: PropTypes.func,
    /* From redux-router */
    match: PropTypes.object.isRequired,
    /* deployment hash */
    productHandle: PropTypes.string,
};
export default Training;
