import React from 'react';

const AssignmentStatus = props => {
    const { row, value, productHandle } = props;
    const noTraining = row['fields.trained'];
    const training = JSON.parse(row['fields.trained']);

    const currentSurvey = productHandle;

    const STATUS_MAP = ['Not Started', 'In Progress', 'Completed'];
    const productList = row.ownedLicenseList.filter(item => item.licenseId).map(item => item.product.handle);

    const ownsProduct = productList.includes(currentSurvey);

    const isAltComplete = handle => {
        const alt = row.assignmentProgressList.find(item => item.deployment.deploymentHandle === handle);

        if (alt && alt.completed) {
            return true;
        }
        return false;
    };

    const hasAlternateSurvey = () => {
        if (productHandle === 'ssgrin_35') {
            return 'ssgrin_k2_training';
        }
        if (productHandle === 'ssgrin_k2') {
            return 'ssgrin_35_training';
        }
        return null;
    };

    const altCompleted = isAltComplete(hasAlternateSurvey());
    const trained = training && training[currentSurvey];

    if (ownsProduct && (trained || altCompleted)) {
        return <div>{STATUS_MAP[2]}</div>;
    }

    if (noTraining && !altCompleted && value > 0) {
        // let key = `assignmentProgressStatus.assignment${assignment.assignmentId}_lastVisit`;

        return <div>{STATUS_MAP[Number.parseInt(value, 10)]}</div>;
    }

    return STATUS_MAP[0];
};

export default AssignmentStatus;
