import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';

const TitleAndDescription = props => {
    const { title, description } = props.settings;

    return (
        <div className={style.wrapper}>
            <h1 className={style.title}>
                <Html content={title} />
            </h1>
            <Html content={description} />
        </div>
    );
};

TitleAndDescription.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,

    // redux
    filters: PropTypes.object,
    setWidgetsLoaded: PropTypes.func,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
TitleAndDescription.defaultProps = {
    filters: {},
};

export default TitleAndDescription;
