import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

const Callout = props => {
    const { title, description } = props.settings;
    const { benchmark, data } = props;

    return (
        <div className={style.wrapper}>
            <div className={style.title}>{title}</div>
            <div className={style.description} style={description ? { padding: '0.5em' } : {}}>
                {description}
            </div>
            <div
                style={{
                    flex: '1',
                    textAlign: 'center',
                    fontSize: '3em',
                    fontWeight: 'bold',
                    color: data >= benchmark ? 'green' : '#d03916',
                }}
            >
                {data}%
            </div>
        </div>
    );
};

Callout.propTypes = {
    title: PropTypes.string,
    data: PropTypes.string,
    benchmark: PropTypes.number,
    settings: PropTypes.object,
};

export default Callout;
