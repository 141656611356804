import React, { useRef } from 'react';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';

import LearnerForm from '../../../../components/Manage/LearnerForm';

import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;
const isUberAdmin = userRole === 'uberadmin';
const isInstructor = userRole === 'instructor';
const pawnId = Fortress.user.acting.id;

const EditLearnerForm = props => {
    const modal = useRef();

    // rows are coming from each role's learner_tableMain.graphql
    const { row, settings, loadData } = props;

    const getPawnIds = list => {
        return list && list.length > 0
            ? list.map(l => {
                  return l.pawn.pawnId;
              })
            : [];
    };

    const onSubmit = async values => {
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', {
                pawn: row['pawn.pawnId'],
            }),
            {
                pawnHash: row['pawn.pawnHash'],
                productId: values.productId,
                participantName: values.participantName,
                participantId: values.participantId,
                gender: values.gender,
                grade: values.grade,
                teacherName: values.teacherName,
                teacherEmail: values.teacherEmail,
                teacherPhone: values.teacherPhone,
                parentEmail: values.parentEmail,
                parentPhone: values.parentPhone,
                group: values.school,
                parentPawns: values.allClassesAnyProduct,
            }
        );

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }
        await loadData();
        notification('Learner edited.');
    };

    /* Instructors can only edit participants if they are not in a group,
    or they are in a group that the instructor leads */
    const canEdit = instructorPawnId => {
        if (isInstructor) {
            const instructors =
                row['parentRoles.class.parentRoles.instructorList']?.map(instructor => instructor.pawn.pawnId) || [];
            return instructors.includes(instructorPawnId) || instructors.length === 0;
        }

        return true;
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <Tooltip
                    title={
                        canEdit(pawnId)
                            ? 'Edit'
                            : 'This participant is already in a group for this product led by another group leader. To move the participant to your group, contact an administrator.'
                    }
                >
                    <button
                        type="button"
                        className={canEdit(pawnId) ? 'btn btn-xs btn-success' : 'btn btn-xs btn-success disabled'}
                        disabled={!canEdit(pawnId)}
                    >
                        <IconPencil />
                    </button>
                </Tooltip>
            }
            title={`Edit ID Number ${row['fields.participantId']}`}
        >
            <LearnerForm
                operation="edit"
                onSubmit={onSubmit}
                initialValues={{
                    productId: isUberAdmin ? row['parentRoles.class.fields.productId'] : undefined,
                    otherProductsClasses: getPawnIds(row['otherProductsClasses.classList']),
                    group: row['parentRoles.class.pawn.pawnId'],
                    school: row[settings.school],
                    participantName: row['fields.participantName'],
                    participantId: row['fields.participantId'],
                    gender: row['fields.gender'],
                    grade: row['fields.grade'],
                    teacherName: row['fields.teacherName'],
                    teacherEmail: row['fields.teacherEmail'],
                    teacherPhone: row['fields.teacherPhone'],
                    parentEmail: row['fields.parentEmail'],
                    parentPhone: row['fields.parentPhone'],
                }}
                {...props}
            />
        </Modal>
    );
};

EditLearnerForm.propTypes = {
    loadData: PropTypes.func,
    row: PropTypes.object,
};

export default EditLearnerForm;
