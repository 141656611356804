import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    title: 'Comments',
    description: 'Some description',
};

const Component = props => {
    return (
        <div>
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="description" component={CccisdInput} label="Description" />
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
