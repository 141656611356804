import React, { useEffect, useState } from 'react';
import axios from 'cccisd-axios';
import Modal from 'cccisd-modal';
import CertificateRender from './CertificateRender';

const Boilerplate = window.cccisd.boilerplate;

const Component = props => {
    const { row, currentCourse, currentSettings, productHandle } = props;

    const [backgroundUrls, setBackgroundUrls] = useState({});
    const [certificateLabel, setCertificateLabel] = useState(null);
    const [assignmentSettings, setAssignmentSettings] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        async function getBackgroundUrl(bgId, returnObj) {
            const baseUrl = Boilerplate.route('api.resources.file.index');
            const response = await axios.get(`${baseUrl}?id=${bgId}`);
            if (response.status === 200 && response.data.status === 'success') {
                returnObj[bgId] = response.data.data[0].published_url;
            }
        }

        async function getLocalUrls(certificates) {
            const loadingPromises = [];
            const startingBackgroundIds = certificates.filter(c => c.backgroundId).map(c => c.backgroundId);
            const localBackgroundUrls = {};
            if (Object.keys(backgroundUrls).length === 0 && startingBackgroundIds.length > 0) {
                const backgroundIdsPromised = [];
                startingBackgroundIds.forEach(bgId => {
                    if (!backgroundIdsPromised.includes(bgId)) {
                        backgroundIdsPromised.push(bgId);
                        loadingPromises.push(getBackgroundUrl(bgId, localBackgroundUrls));
                    }
                });
            }

            await Promise.all(loadingPromises);

            return localBackgroundUrls;
        }

        async function loadData() {
            const assignmentCompleted = row['assignmentProgress.completed'];
            const alternateSurvey = hasAlternateSurvey();
            const altCompleted = isAltComplete(alternateSurvey);

            const productList = row.ownedLicenseList.filter(item => item.licenseId).map(item => item.product.handle);

            const training = JSON.parse(row['fields.trained']);
            const hasCurrentTraining = training[productHandle];
            const ownsCurrentProduct = productList.includes(productHandle);
            const alt = altCompleted || hasCurrentTraining;
            const label = getCertificateLabel(training, productList, assignmentCompleted, alt);

            const fName = row['user.fullName'];
            setFullName(fName);
            setCertificateLabel(label);
            try {
                if (assignmentCompleted) {
                    const certificates =
                        row['assignmentProgress.assignment.settings.navigationSettings.certificate.certificates'];

                    const localBackgroundUrls = await getLocalUrls(certificates);

                    setBackgroundUrls(localBackgroundUrls);
                } else if (altCompleted || hasCurrentTraining) {
                    const certificates = currentSettings.settings.navigationSettings.certificate.certificates;

                    const localBackgroundUrls = await getLocalUrls(certificates);
                    setAssignmentSettings(currentSettings);
                    setBackgroundUrls(localBackgroundUrls);
                }
            } catch (e) {
                console.error(e);
            }

            setShowModal(ownsCurrentProduct && (assignmentCompleted || altCompleted || hasCurrentTraining));
            setLoading(false);
        }

        loadData();
    }, []);

    const hasAlternateSurvey = () => {
        if (currentCourse.label === 'S.S.GRIN K-2') {
            return 'ssgrin_35_training';
        }
        if (currentCourse.label === 'S.S.GRIN 3-5') {
            return 'ssgrin_k2_training';
        }
        return null;
    };

    const isAltComplete = alternateSurvey => {
        if (alternateSurvey) {
            const alt = row.assignmentProgressList.find(item => item.deployment.deploymentHandle === alternateSurvey);

            if (alt && alt.completed) {
                return true;
            }
            return false;
        }
    };

    const getCertificateLabel = (train, ownedList, completed, altCompleted) => {
        const owns35 = ownedList.includes('ssgrin_35');
        const ownsK2 = ownedList.includes('ssgrin_k2');
        const trainedK5 = train.ssgrin_k2 || train.ssgrin_35 || completed || altCompleted;

        if (currentCourse.label === 'S.S.GRIN K-2') {
            if (trainedK5) {
                if (owns35) {
                    return 'S.S.GRIN K-5';
                }
                return 'S.S.GRIN K-2';
            }
        }
        if (currentCourse.label === 'S.S.GRIN 3-5') {
            if (trainedK5) {
                if (ownsK2) {
                    return 'S.S.GRIN K-5';
                }
                return 'S.S.GRIN 3-5';
            }
        }

        return currentCourse.label;
    };

    if (!loading && showModal) {
        return (
            <Modal
                trigger={
                    <button type="button" className="btn btn-primary">
                        View Certificate
                    </button>
                }
                title="Certificate"
            >
                <CertificateRender
                    {...props}
                    backgroundUrls={backgroundUrls}
                    row={row}
                    certificateLabel={certificateLabel}
                    assignmentSettings={assignmentSettings}
                    fullName={fullName}
                />
            </Modal>
        );
    }
    return null;
};

export default Component;

/* <Certificate
    {...props}
    deploymentId={flowList.length ? flowList[0].responseSet : ''}
    hide={
        !isCourseComplete ||
        !isTabMediatorComplete('finalTest') ||
        !isTabMediatorComplete('courseEval')
    }
    latestFinalTestData={latestFinalTestData}
    settings={certificate}
/> */

// Certificate Props:

// actingPawnHash -> from table row,
// actingPawnId -> from table row,
// basePath - used to reset course progress NOT SURE IF NEEDED,
// deploymentId - tableState variables.deploymentId,
// deploymentSettings -> tableProps currentDeployments[0].settings,
// hide,
// language tableProps -> language,
// latestFinalTestData - used to get dates,
// mediatorProps - used by reset and transcript,
// parentDeploymentId,
// relatedDeploymentId,
// settings -  backgroundId: settings.backgroundId,
// courseName: settings.courseName,
// freeText: settings.freeText,
