import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { stripTags } from 'cccisd-survey-common';

const Fortress = window.cccisd.fortress;

const getProjectList = state => state.projectList;
const getCurrentProjectId = state => state.currentProjectId;
export const getCurrentAssignmentId = state => state.currentAssignmentId;
export const getCurrentQueryAssignmentId = state => state.currentQuerySettings.assignmentId;
const getCurrentQuerySettings = state => state.currentQuerySettings;
const getCurrentDeploymentId = state => state.currentDeploymentId;
export const getCurrentDeploymentIds = state => state.currentDeploymentIds;
const getCurrentSurveyId = state => state.currentSurveyId;
export const getCurrentSurveyHandles = state => state.currentSurveyHandles;
const getCurrentSurveyListHandles = state => state.currentSurveyListHandles;
const getFlowId = (state, props) => Number(props.match.params.flowId);
const getDesignFilter = state => state.designFilter;
const getDeployFilter = state => state.deployFilter;
const getQueryList = state => state.queryList;

export const getCurrentProject = createSelector(
    getProjectList,
    getCurrentProjectId,
    getCurrentSurveyListHandles,
    (projectList, currentProjectId, currentSurveyListHandles = {}) => {
        const result = projectList.find(project => project.groupId === currentProjectId);

        if (!result) {
            return null;
        }

        if (!result.flows) {
            return result;
        }

        result.flows.assignmentList = result.flows.assignmentList.map(assignment => {
            if (!assignment.surveylistList) {
                return assignment;
            }

            const currentSurveylistList = currentSurveyListHandles[assignment.assignmentId]
                ? assignment.surveylistList.find(
                      item => item.surveylistHandle === currentSurveyListHandles[assignment.assignmentId]
                  )
                : assignment.surveylistList[0];

            return {
                ...assignment,
                currentSurveylistList,
            };
        });

        // we need shallow copy here just to update connected components
        return { ...result };
    }
);

export const getFilteredCurrentProjectForDesign = createSelector(
    getCurrentProject,
    getDesignFilter,
    (currentProject, designFilter) => {
        if (!designFilter) {
            return currentProject;
        }

        designFilter = designFilter.toUpperCase();

        return {
            ...currentProject,
            flows: {
                assignmentList: currentProject.flows.assignmentList.filter(assignment => {
                    if (assignment.archived) {
                        return false;
                    }

                    return (
                        (assignment.label || '').toUpperCase().includes(designFilter) ||
                        (assignment.description || '').toUpperCase().includes(designFilter) ||
                        assignment.currentSurveylistList.surveyList
                            .filter(survey => !survey.archived)
                            .some(
                                survey =>
                                    (survey.label || '').toUpperCase().includes(designFilter) ||
                                    (survey.description || '').toUpperCase().includes(designFilter)
                            )
                    );
                }),
            },
        };
    }
);

export const getFilteredCurrentProjectForDeploy = createSelector(
    getCurrentProject,
    getDeployFilter,
    (currentProject, deployFilter) => {
        if (!deployFilter) {
            return currentProject;
        }

        deployFilter = deployFilter.toUpperCase();

        return {
            ...currentProject,
            flows: {
                assignmentList: currentProject.flows.assignmentList.filter(assignment => {
                    if (assignment.archived) {
                        return false;
                    }

                    return (
                        (assignment.label || '').toUpperCase().includes(deployFilter) ||
                        (assignment.description || '').toUpperCase().includes(deployFilter) ||
                        assignment.deploymentList.some(survey =>
                            (survey.label || '').toUpperCase().includes(deployFilter)
                        )
                    );
                }),
            },
        };
    }
);

export const getCurrentProjectAssignments = createSelector(getCurrentProject, currentProject => {
    if (!currentProject || !currentProject.flows) {
        return [];
    }

    return currentProject.flows.assignmentList;
});

export const getCurrentProjectActiveAssignments = createSelector(getCurrentProjectAssignments, assignments => {
    return assignments.filter(item => !item.archived && item.deploymentList && item.deploymentList.length > 0);
});

export const getCurrentProjectDeployments = createSelector(getCurrentProjectAssignments, assignments => {
    let result = [];
    assignments.forEach(item => {
        result = [...result, ...item.deploymentList];
    });

    return result;
});

export const getCurrentAssignmentDeployments = createSelector(
    getCurrentProjectAssignments,
    getCurrentAssignmentId,
    (projectAssignments, assignmentId) => {
        let result = [];
        projectAssignments.forEach(item => {
            result = [...result, ...(item.assignmentId === assignmentId ? item.deploymentList : [])];
        });

        return result;
    }
);

export const getCurrentQueryAssignmentDeployments = createSelector(
    getCurrentProjectAssignments,
    getCurrentQueryAssignmentId,
    (projectAssignments, assignmentId) => {
        let result = [];
        projectAssignments.forEach(item => {
            result = [...result, ...(item.assignmentId === assignmentId ? item.deploymentList : [])];
        });

        return result;
    }
);

export const getCurrentQueryAssignmentActiveDeployments = createSelector(
    getCurrentQueryAssignmentDeployments,
    deployments => {
        return deployments.filter(item => !item.archived);
    }
);

export const getCurrentAssignmentSurveys = createSelector(
    getCurrentProjectAssignments,
    getCurrentAssignmentId,
    (assignments, assignmentId) => {
        let assignment = assignments.find(item => item.assignmentId === assignmentId);

        let result = [];
        assignment &&
            assignment.surveylistList.forEach(item => {
                result = [...result, ...item.surveyList];
            });

        return result;
    }
);

export const getCurrentQueryAssignmentSurveys = createSelector(
    getCurrentProjectAssignments,
    getCurrentQueryAssignmentId,
    (assignments, assignmentId) => {
        let assignment = assignments.find(item => item.assignmentId === assignmentId);

        let result = [];
        assignment &&
            assignment.surveylistList.forEach(item => {
                result = [...result, ...item.surveyList];
            });

        return result;
    }
);

export const getCurrentQueryAssignmentActiveSurveys = createSelector(getCurrentQueryAssignmentSurveys, surveys => {
    return surveys.filter(item => !item.archived);
});

export const getPopulatedCurrentQuerySettings = createSelector(
    getCurrentQuerySettings,
    getCurrentQueryAssignmentActiveDeployments,
    getCurrentQueryAssignmentActiveSurveys,
    (currentQuerySettings, deployments, surveys) => {
        const result = { ...currentQuerySettings };

        if (currentQuerySettings.deploymentIds === 'all') {
            result.deploymentIds = deployments.map(item => item.deploymentId);
        }
        if (currentQuerySettings.surveyHandles === 'all') {
            result.surveyHandles = surveys.map(item => item.surveyHandle);
        }

        return result;
    }
);

export const getDeploymentById = createSelector(
    getCurrentProjectDeployments,
    getCurrentDeploymentId,
    (deployments, deploymentId) => deployments.find(item => item.deploymentId === deploymentId)
);

export const getFlowBreadcrumbs = createSelector(getCurrentProject, getFlowId, (currentProject, flowId) => {
    try {
        for (let assignment of currentProject.flows.assignmentList) {
            for (let survey of assignment.currentSurveylistList.surveyList) {
                for (let flow of survey.flowList) {
                    if (flow.flowId === flowId) {
                        let flowName = _get(flow, 'settings.playerTemplateSettings.title') || flow.name;
                        flowName = stripTags(flowName);

                        return [currentProject.label, assignment.label, flowName];
                    }
                }
            }
        }
    } catch (error) {
        return [];
    }

    return [];
});

export const getDeploymentBreadcrumbs = createSelector(
    getCurrentProject,
    getCurrentDeploymentId,
    (currentProject, deploymentId) => {
        try {
            for (let assignment of currentProject.flows.assignmentList) {
                for (let deployment of assignment.deploymentList) {
                    if (deployment.deploymentId === deploymentId) {
                        return [currentProject.label, assignment.label, deployment.label];
                    }
                }
            }
        } catch (error) {
            return [];
        }

        return [];
    }
);

export const getProjectLinks = createSelector(getCurrentProject, currentProject => ({
    base: `/quest/${currentProject.groupId}`,
    design: `/quest/${currentProject.groupId}/design`,
    deploy: `/quest/${currentProject.groupId}/deploy`,
}));

export const getCurrentProjectExportData = createSelector(getCurrentProjectAssignments, assignments => {
    const result = [];
    for (const assignment of assignments) {
        result.push({
            id: result.length + 1,
            assignmentId: assignment.assignmentId,
            assignment: assignment.label,
            surveyId: 0,
            survey: '(all)',
        });

        for (const surveylistList of assignment.surveylistList) {
            for (const survey of surveylistList.surveyList) {
                result.push({
                    id: result.length + 1,
                    assignmentId: assignment.assignmentId,
                    assignment: assignment.label,
                    surveyId: survey.surveyId,
                    survey: survey.label,
                });
            }
        }
    }
    return result;
});

export const getCurrentAssignment = createSelector(
    getCurrentProjectAssignments,
    getCurrentAssignmentId,
    (assignments, currentAssignmentId) => {
        return assignments.find(item => item.assignmentId === currentAssignmentId);
    }
);

export const getCurrentDeployment = createSelector(
    getCurrentProjectDeployments,
    getCurrentDeploymentId,
    (deployments, currentDeploymentId) => {
        return deployments.find(item => item.deploymentId === currentDeploymentId);
    }
);

export const getCurrentDeployments = createSelector(
    getCurrentProjectDeployments,
    getCurrentDeploymentIds,
    (deployments, currentDeploymentIds) => {
        return deployments.filter(item => currentDeploymentIds.includes(item.deploymentId));
    }
);

export const getCurrentSurvey = createSelector(
    getCurrentAssignmentSurveys,
    getCurrentSurveyId,
    (surveys, currentSurveyId) => {
        return surveys.find(item => item.surveyId === currentSurveyId);
    }
);

export const getCurrentDeploymentSurveys = createSelector(
    getCurrentAssignmentSurveys,
    getCurrentDeployment,
    getCurrentProjectDeployments,
    (surveys, deployment, allDeployments) => {
        let allowedSurveyHandles = [];

        if (typeof deployment === 'undefined') {
            for (const index of allDeployments) {
                let d = allDeployments[index];
                let options = _get(d, 'settings.options', []);

                for (let i of options) {
                    if (!allowedSurveyHandles.includes(options[i])) {
                        allowedSurveyHandles.push(options[i]);
                    }
                }
            }
        } else {
            allowedSurveyHandles = _get(deployment, 'settings.options', []);
        }

        return surveys.filter(item => {
            return allowedSurveyHandles.includes(item.surveyHandle);
        });
    }
);

export const getCurrentProjectQueries = createSelector(getQueryList, getCurrentProjectId, (queryList, projectId) => {
    const hasIdentifiablePermission = Fortress && Fortress.hasAccess('quest.view_identifiable_data', projectId);

    return queryList
        .filter(query => {
            if (query.projectId !== projectId) {
                return false;
            }
            if (query.settings.showQueryTo === 'withIdentifiable' && !hasIdentifiablePermission) {
                return false;
            }

            return true;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
});

export const getAvailableTags = createSelector(getCurrentProjectQueries, projectQueries => {
    const list = new Set();
    projectQueries.forEach(project => {
        (project.settings?.tags || []).forEach(tag => {
            list.add(tag);
        });
    });

    return [...list].sort((a, b) => a.localeCompare(b));
});
