import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import _format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import NoGroupRole from '../../common/components/NoGroupRole';
import filterFields from '../../common/filterFields.js';
import { getActualFields } from '../../common/helpers.js';
import PrintView from '../../common/components/PrintView';

import reportStyle from '../../common/reportStyle.css';
import query from './metrics.graphql';

const Component = props => {
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const { productId } = useSelector(state => state.app.products);
    const { productHandle } = useParams();
    const { titleAndDescription, mainCharts, issueCharts, progressCharts } = props.widgets;
    const participantPawnId = props.filters.participant;

    useEffect(() => {
        // run metrics query
        if (noProgress === true) {
            setNoProgress(false);
        }
        (async () => {
            try {
                const queryHandle = productHandle
                    ? productHandle
                    : props.productIds.find(item => item.value === props.filters.product).handle;

                const prodId = productId ? productId : props.filters.product;

                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +participantPawnId,
                            productId: prodId,
                            productHandle: queryHandle,
                        },
                    })
                    .then(response => {
                        const learner = response.data.roles.learner.assignmentProgressList.length > 0;

                        const group =
                            learner && learner
                                ? response.data.roles.learner.assignmentProgressList[0].pawn.parentRoles.class
                                : '';

                        const startDate =
                            learner && learner ? response.data.roles.learner.summary.earliestCompleted : '';

                        const endDate = learner && learner ? response.data.roles.learner.summary.latestCompleted : '';

                        const dateObj = {
                            startDate: startDate !== '' ? _format(new Date(startDate), 'MM/dd/yyyy') : '',
                            endDate: endDate !== '' ? _format(new Date(endDate), 'MM/dd/yyyy') : '',
                            now: _format(Date.now(), 'MM/dd/yyyy'),
                        };

                        if (!learner || !startDate || !dateObj) {
                            setNoProgress(true);
                        }

                        setHeaderProps({
                            groupLabel: learner && learner ? group.fields.groupLabel : '',
                            instructorList: learner && learner ? group.parentRoles.instructorList : [],
                            dates: dateObj,
                            participant: learner && learner ? response.data.roles.learner.fields.participantId : '',
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    if (noProgress) {
        return <NoGroupRole type="data" />;
    }

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    return loading && loading ? (
        <Loader loading />
    ) : (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={reportStyle.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={reportStyle.reportBody}>{titleAndDescription}</div>
                    <div style={{ marginBottom: '2em' }}>{mainCharts}</div>
                </div>
            </Page>
            <Page>
                <h3 style={{ color: '#007298', textAlign: 'center' }}>Individual Issues</h3>
                {issueCharts}
            </Page>
            <Page>
                <h3 style={{ color: '#007298', textAlign: 'center' }}>Individual Progress Areas</h3>
                {progressCharts}
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
