import React from 'react';
import { NavbarBrand, NavbarLink, NavbarContent } from 'cccisd-header';
import PropTypes from 'prop-types';
import style from './style.css';
import logo from './3CI_symbol.svg';
import IconShoppingCart from 'cccisd-icons/cart';
import IconLeftArrow from 'cccisd-icons/arrow-left6';

const { wpUrl } = window.cccisd.appDefs.app;

function ProductHeader(props) {
    return (
        <header className={`container ${style.header}`}>
            <nav className="navbar navbar-default">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#header-navbar-collapse"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                    <NavbarBrand
                        noLogoLink
                        logo={
                            <strong
                                style={{
                                    fontSize: '1.25em',
                                    fontFamily: 'Cabin',
                                }}
                            >
                                {props.product.label}
                            </strong>
                        }
                    />
                </div>

                <div className="collapse navbar-collapse" id="header-navbar-collapse">
                    <NavbarContent showAlerts allowSwitchRoles={false}>
                        <NavbarLink to="/products">
                            <IconLeftArrow spaceRight />
                            Return To{' '}
                            <img
                                src={logo}
                                className={style.justCsLogo}
                                alt="3C Institue Logo: Just Orange C's"
                            />
                            Products
                        </NavbarLink>
                        <NavbarLink to={`${wpUrl}/cart`}>
                            <IconShoppingCart spaceRight /> Cart
                        </NavbarLink>
                    </NavbarContent>
                    {props.children}
                </div>
            </nav>
        </header>
    );
}

ProductHeader.propTypes = {
    product: PropTypes.object,
    children: PropTypes.node,
};

export default ProductHeader;
