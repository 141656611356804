import React from 'react';
import { calcAvg } from '../../vendor/reports/reportTemplates/common/helpers.js';

import IconSmile from 'cccisd-icons/smile';
import IconSad from 'cccisd-icons/wondering';

const OverallAvgFidelity = () => {
    return ({ row }) => {
        const scores = [
            row.avgComponentCoverageScore,
            row['assignmentProgressSummary.avgParticipantResponsivenessScore'],
            row['assignmentProgressSummary.avgContentSupplementedScore'],
            row['assignmentProgressSummary.avgProgramAdherenceScore'],
        ]
            .filter(sc => sc !== null)
            .map(sc => +sc);

        const overallAvgFidelity = scores.length > 0 ? calcAvg(scores).toFixed(0) : null;

        const renderScoreAndSmiley = score => {
            let icon = <IconSmile />;
            let color = '#3673b6';

            if (+score < 80) {
                icon = <IconSad />;
                color = '#d03916';
            }

            return score ? (
                <>
                    <span style={{ color }}>{icon} </span>
                    {`${score}%`}
                </>
            ) : null;
        };

        return (
            <div style={{ whiteSpace: 'nowrap' }}>{renderScoreAndSmiley(overallAvgFidelity)}</div>
        );
    };
};
export default OverallAvgFidelity;
