import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'cccisd-react-router';
import _get from 'lodash/get';

import calloutsQuery from './callouts.graphql';
import Callout from '../Callout';
import { convertScore, calcAvg } from '../../../../common/helpers.js';
import { getSessionDataVariables } from '../../../../../../../reducers/reportFilters';

const Fortress = window.cccisd && window.cccisd.fortress;
const instructorPawnId = Fortress.user.acting.id;

const Component = props => {
    const [metrics, setMetrics] = useState({});
    const [loading, setLoading] = useState(true);

    const { show } = props.settings;

    const dispatch = useDispatch();
    const { productHandle } = useParams();
    const { sessionDataVariables } = useSelector(state => state.app.reportFilters);
    const { productId } = useSelector(state => state.app.products);

    useEffect(() => {
        (async () => {
            dispatch(getSessionDataVariables(productHandle));

            try {
                await client
                    .query({
                        query: calloutsQuery,
                        fetchPolicy: 'network-only',
                        variables: {
                            instructorPawnId,
                            productId,
                        },
                    })
                    .then(response => {
                        const groupData = response.data.roles.classList;

                        // make an array the length of however many session data sessions there are
                        const sessDataArray = (length, startAt, devTag) => {
                            return [...Array(length).keys()].map(i => {
                                return getAllGroupsData(groupData, `sess${i + startAt}`, devTag);
                            });
                        };

                        // each topic's array of average scores
                        const compTotal = sessDataArray(
                            sessionDataVariables.totalSessions,
                            1,
                            'ComponentCoverageScore'
                        );

                        const progAdherenceTotal = sessDataArray(
                            sessionDataVariables.totalSessions,
                            1,
                            'ProgramAdherenceScore'
                        );

                        const contentSupplementedTotal = sessDataArray(
                            sessionDataVariables.totalSessions,
                            1,
                            'ContentSupplementedScore'
                        );

                        const partResponsivenessTotal = sessDataArray(
                            sessionDataVariables.totalSessions,
                            1,
                            'ParticipantResponsivenessScore'
                        );

                        setMetrics([
                            {
                                id: 'componentCoverage',
                                // remove nulls and find average of the array
                                score: averageScores(compTotal),
                                settings: { ...props.settings.componentCoverage },
                            },
                            {
                                id: 'progAdherence',
                                score: averageScores(progAdherenceTotal),
                                settings: { ...props.settings.progAdherence },
                            },
                            {
                                id: 'contentSupplemented',
                                score: averageScores(contentSupplementedTotal),
                                settings: { ...props.settings.contentSupplemented },
                            },
                            {
                                id: 'partResponsiveness',
                                score: averageScores(partResponsivenessTotal),
                                settings: { ...props.settings.partResponsiveness },
                            },
                        ]);

                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters, props.settings]);

    const getAllGroupsData = (res, sessNumber, chart) => {
        const bySession = res && res.length > 0 ? res.map(el => _get(el, sessNumber)) : [];

        const byDevTag = bySession
            .map(session => _get(session.devTags, chart))
            .filter(score => score !== null)
            .map(score => +score);

        let scores = [];

        if (chart === 'ComponentCoverageScore') {
            scores = byDevTag.map(sc => Number(convertScore(sc)));

            return scores.length > 0 ? calcAvg(scores).toFixed(0) : null;
        }

        scores = byDevTag;

        return scores.length > 0 ? calcAvg(scores).toFixed(0) : null;
    };

    const averageScores = scoresArray => {
        const nonNulls = scoresArray.filter(s => s !== null).map(s => +s);

        return calcAvg(nonNulls).toFixed(0);
    };

    return loading && loading ? (
        <Loader type="inline" />
    ) : (
        <>
            {show && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '2em',
                        marginBottom: '2em',
                        padding: '1em',
                    }}
                >
                    {metrics.map(metric => {
                        return (
                            <div className="col-sm-3" key={metric.id}>
                                <Callout
                                    handle={metric.id}
                                    data={metric.score}
                                    settings={metric.settings}
                                    benchmark={sessionDataVariables.fidelityBenchmark}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
