import React from 'react';
import { createAction, handleActions } from 'redux-actions';
import { client as apollo } from 'cccisd-apollo';
import projectListQuery from '../queries/projectListQuery.graphql';
import designTabQuery from '../queries/designTabQuery.graphql';
import projectSettingsQuery from './projectSettings.graphql';
import notification from 'cccisd-notification';
import axios from 'cccisd-axios';
import _difference from 'lodash/difference';
import _union from 'lodash/union';
import _xor from 'lodash/xor';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';
import _uniqBy from 'lodash/uniqBy';
import _uniq from 'lodash/uniq';
import {
    getCurrentProjectAssignments,
    getCurrentAssignmentDeployments,
    getCurrentProject,
    getCurrentProjectQueries,
    getCurrentQueryAssignmentActiveDeployments,
} from '../selectors/admin.js';

import { getFlatColumnTree } from '../helpers/getColumnTree.js';
import { nanoid } from 'nanoid';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const defaultQuerySettings = {
    assignmentId: 0,
    deploymentIds: 'all',
    surveyHandles: 'all',
    columns: {
        'pawn.pawnId': { id: 'pawn.pawnId', sort: 1, label: 'System ID', type: 'number' },
        'pawn.createdDate': {
            id: 'pawn.createdDate',
            sort: 2,
            label: 'Created Date',
            type: 'string',
        },
    },
    codedValues: true,
    missingValues: true,
    waveHandle: false,
    waveTimepoint: false,
    metrics: {
        types: ['qualitative', 'quantitative', 'additional_coding', 'formula'],
        list: [],
    },
    filter: null,
    visualizationSettings: {},
};

export const initialState = {
    projectList: [],
    currentProjectId: 0,
    currentAssignmentId: 0,
    currentDeploymentId: 0,
    selectedDeploymentIds: [],
    currentDeploymentIds: [],
    deploymentDropdownVisible: false,
    currentSurveyId: 0,
    currentSurveyHandle: '0',
    currentSurveyHandles: [],
    surveyDropdownVisible: false,
    expandedDesignAssignmentIds: [],
    expandedDeployAssignmentIds: [],
    showArchivedAssignments: false,
    showArchivedSurveys: [],
    currentSurveyListHandles: {},
    currentTableId: '',
    designFilter: '',
    deployFilter: '',
    devTags: [],
    queryList: [],
    currentQueryId: '0',
    currentQuerySettings: defaultQuerySettings,
    deploymentListLimit: 500,
    vizData: {},
};

// Actions
const SET_PROJECT_LIST = 'assignment/admin/SET_PROJECT_LIST';
const SET_PROJECT_FLOWS = 'assignment/admin/SET_PROJECT_FLOWS';
const SET_CURRENT_PROJECT_ID = 'assignment/admin/SET_CURRENT_PROJECT_ID';
const SET_CURRENT_ASSIGNMENT_ID = 'assignment/admin/SET_CURRENT_ASSIGNMENT_ID';
const SET_CURRENT_ASSIGNMENT_ID_ONLY = 'assignment/admin/SET_CURRENT_ASSIGNMENT_ID_ONLY';
const SET_CURRENT_DEPLOYMENT_ID = 'assignment/admin/SET_CURRENT_DEPLOYMENT_ID';
const SET_SELECTED_DEPLOYMENT_IDS = 'assignment/admin/SET_SELECTED_DEPLOYMENT_IDS';
const SET_CURRENT_DEPLOYMENT_IDS = 'assignment/admin/SET_CURRENT_DEPLOYMENT_IDS';
const SET_CURRENT_SURVEY_ID = 'assignment/admin/SET_CURRENT_SURVEY_ID';
const SET_CURRENT_SURVEY_HANDLE = 'assignment/admin/SET_CURRENT_SURVEY_HANDLE';
const SET_CURRENT_SURVEY_HANDLES = 'assignment/admin/SET_CURRENT_SURVEY_HANDLES';
const SET_CURRENT_TABLE_ID = 'assignment/admin/SET_CURRENT_TABLE_ID';
const SET_EXPANDED_ALL_DESIGN_ASSIGNMENTS = 'assignment/admin/SET_EXPANDED_ALL_DESIGN_ASSIGNMENTS';
const TOGGLE_COLLAPSED_DESIGN_ASSIGNMENT = 'assignment/admin/TOGGLE_COLLAPSED_DESIGN_ASSIGNMENT';
const SET_EXPANDED_ALL_DEPLOY_ASSIGNMENTS = 'assignment/admin/SET_EXPANDED_ALL_DEPLOY_ASSIGNMENTS';
const TOGGLE_COLLAPSED_DEPLOY_ASSIGNMENT = 'assignment/admin/TOGGLE_COLLAPSED_DEPLOY_ASSIGNMENT';
const TOGGLE_ARCHIVED_ASSIGNMENTS = 'assignment/admin/TOGGLE_ARCHIVED_ASSIGNMENTS';
const TOGGLE_ARCHIVED_SURVEYS = 'assignment/admin/TOGGLE_ARCHIVED_SURVEYS';
const MOVE_SURVEY = 'assignment/admin/MOVE_SURVEY';
const SET_CURRENT_SURVEY_LIST_HANDLE = 'assignment/admin/SET_CURRENT_SURVEY_LIST_HANDLE';
const SET_WAVE_OPEN_STATUS = 'assignment/admin/SET_WAVE_OPEN_STATUS';
const CHANGE_DESIGN_FILTER = 'assignment/admin/CHANGE_DESIGN_FILTER';
const CHANGE_DEPLOY_FILTER = 'assignment/admin/CHANGE_DEPLOY_FILTER';
const SET_DEV_TAGS = 'assignment/admin/SET_DEV_TAGS';
const SET_QUERY_LIST = 'assignment/admin/SET_QUERY_LIST';
const ADD_NEW_QUERY = 'assignment/admin/ADD_NEW_QUERY';
const UPDATE_QUERY = 'assignment/admin/UPDATE_QUERY';
const DELETE_CURRENT_QUERY = 'assignment/admin/DELETE_CURRENT_QUERY';
const SET_CURRENT_QUERY_ID = 'assignment/admin/SET_CURRENT_QUERY_ID';
const SET_CURRENT_QUERY_SETTINGS = 'assignment/admin/SET_CURRENT_QUERY_SETTINGS';
const SET_VIZ_DATA = 'assignment/admin/SET_VIZ_DATA';

// Action Creators
export const setProjectList = createAction(SET_PROJECT_LIST);
export const setCurrentProjectId = createAction(SET_CURRENT_PROJECT_ID);
export const setCurrentAssignmentId = createAction(SET_CURRENT_ASSIGNMENT_ID);
export const setCurrentAssignmentIdOnly = createAction(SET_CURRENT_ASSIGNMENT_ID_ONLY);
export const setCurrentDeploymentId = createAction(SET_CURRENT_DEPLOYMENT_ID);
export const setSelectedDeploymentIds = createAction(SET_SELECTED_DEPLOYMENT_IDS);
export const setCurrentDeploymentIds = createAction(SET_CURRENT_DEPLOYMENT_IDS);
export const setCurrentSurveyId = createAction(SET_CURRENT_SURVEY_ID);
export const setCurrentSurveyHandle = createAction(SET_CURRENT_SURVEY_HANDLE);
export const setCurrentSurveyHandles = createAction(SET_CURRENT_SURVEY_HANDLES);
export const setCurrentTableId = createAction(SET_CURRENT_TABLE_ID);
export const setProjectFlows = createAction(SET_PROJECT_FLOWS);
export const setExpandedAllDesignAssignments = createAction(SET_EXPANDED_ALL_DESIGN_ASSIGNMENTS);
export const toggleCollapsedDesignAssignment = createAction(TOGGLE_COLLAPSED_DESIGN_ASSIGNMENT);
export const setExpandedAllDeployAssignments = createAction(SET_EXPANDED_ALL_DEPLOY_ASSIGNMENTS);
export const toggleCollapsedDeployAssignment = createAction(TOGGLE_COLLAPSED_DEPLOY_ASSIGNMENT);
export const toggleArchivedAssignments = createAction(TOGGLE_ARCHIVED_ASSIGNMENTS);
export const toggleArchivedSurveys = createAction(TOGGLE_ARCHIVED_SURVEYS);
export const moveSurvey = createAction(MOVE_SURVEY);
export const setCurrentSurveyListHandle = createAction(SET_CURRENT_SURVEY_LIST_HANDLE);
export const setWaveOpenStatus = createAction(SET_WAVE_OPEN_STATUS);
export const changeDesignFilter = createAction(CHANGE_DESIGN_FILTER);
export const changeDeployFilter = createAction(CHANGE_DEPLOY_FILTER);
export const setDevTags = createAction(SET_DEV_TAGS);
export const setQueryList = createAction(SET_QUERY_LIST);
export const addNewQueryWithoutSave = createAction(ADD_NEW_QUERY);
export const updateQueryWithoutSave = createAction(UPDATE_QUERY);
export const deleteCurrentQueryWithoutSave = createAction(DELETE_CURRENT_QUERY);
export const setCurrentQueryId = createAction(SET_CURRENT_QUERY_ID);
export const setCurrentQuerySettings = createAction(SET_CURRENT_QUERY_SETTINGS);
export const setVizData = createAction(SET_VIZ_DATA);

const saveQueries = async (state, deletedIds = []) => {
    const { currentProjectId } = state;
    const currentQueries = getCurrentProjectQueries(state);

    const result = await apollo.query({
        query: projectSettingsQuery,
        variables: { groupId: currentProjectId },
        fetchPolicy: 'network-only',
    });

    const existingQueries = result.data.groups.questproject.group.settings?.queryList || [];

    await axios.put(Boilerplate.route('api.nexus.group.update', { group: currentProjectId }), {
        settings: {
            queryList: _uniqBy(
                [...currentQueries, ...existingQueries].filter(item => !deletedIds.includes(item.id)),
                'id'
            ),
        },
    });
};

export const addNewQuery = (...args) => {
    return async (dispatch, getState) => {
        dispatch(addNewQueryWithoutSave(...args));
        await saveQueries(getState().assignment.admin);
    };
};

export const updateQuery = (...args) => {
    return async (dispatch, getState) => {
        dispatch(updateQueryWithoutSave(...args));
        await saveQueries(getState().assignment.admin);
    };
};

export const deleteCurrentQuery = (...args) => {
    return async (dispatch, getState) => {
        const deletedQueryId = getState().assignment.admin.currentQueryId;

        dispatch(deleteCurrentQueryWithoutSave(...args));
        await saveQueries(getState().assignment.admin, [deletedQueryId]);
    };
};

export const loadProjectList = ({ forceReload } = {}) => {
    return async (dispatch, getState) => {
        // Don't load projects twice
        const state = getState().assignment.admin;
        if (state.projectList.length > 0 && !forceReload) {
            return;
        }

        if (Fortress.user.acting.data_type === 'guest') {
            window.location = Boilerplate.url('account/login');
            return;
        }

        let result = null;
        try {
            result = await apollo.query({
                query: projectListQuery,
                fetchPolicy: 'network-only',
            });
        } catch (e) {
            return;
        }

        const projectList = result.data.groups.questprojectList.map(project => ({
            ...project.group,
            fields: project.fields,
        }));
        dispatch(setProjectList(projectList));
    };
};

export const loadProject = (groupId, deploymentIds = []) => {
    return async (dispatch, getState) => {
        if (Fortress.user.acting.data_type === 'guest') {
            window.location = Boilerplate.url('account/login');
            return;
        }

        const { deploymentListLimit } = getState().assignment.admin;
        let result = null;
        try {
            result = await apollo.query({
                query: designTabQuery,
                variables: { groupId, deploymentIds, limit: deploymentListLimit },
                fetchPolicy: 'network-only',
            });
        } catch (e) {
            return;
        }

        const isLimited = result.data.flows.assignmentList.some(
            assignment => assignment.deploymentList.length >= deploymentListLimit
        );
        if (isLimited) {
            notification({
                type: 'warning',
                message: (
                    <div>
                        <p>
                            <b>Warning!</b>
                        </p>
                        Some data collection waves may not be shown.
                    </div>
                ),
                duration: 0,
            });
        }

        dispatch(setProjectFlows({ groupId, flows: result.data.flows }));
        dispatch(setCurrentProjectId(groupId));
    };
};

export const loadDevTags = () => {
    return async (dispatch, getState) => {
        const response = await axios.get(Boilerplate.route('poly.api.v1.tag.tagNames') + '?scope=developer');
        dispatch(setDevTags(response.data));
    };
};

export const reloadCurrentProject = () => {
    return async (dispatch, getState) => {
        const { currentProjectId, projectList } = getState().assignment.admin;
        let newCurrentProjectId = currentProjectId;
        if (!projectList.find(p => Number.parseInt(p.groupId, 10) === Number.parseInt(currentProjectId, 10))) {
            const questTabPermissions = [
                'quest.design',
                'quest.deploy',
                'quest.status',
                'quest.code',
                'quest.view_data',
                'quest.tables',
                'quest.permissions',
                'quest.admin',
            ];
            const projectWithPermission = projectList.find(p => Fortress.hasAnyAccess(questTabPermissions, p.groupId));
            newCurrentProjectId = !projectWithPermission ? 0 : Number.parseInt(projectWithPermission.groupId, 10);
        }
        await dispatch(loadProject(newCurrentProjectId));
    };
};

export const createQuest = values => {
    return async (dispatch, getState) => {
        const { currentProjectId } = getState().assignment.admin;

        const response = await axios.post(Boilerplate.route('api.assignment.store'), {
            group: currentProjectId,
            label: values.title,
            description: values.subtitle,
            product: values.productId || null,
            settings: _omit(values, ['title', 'subtitle', 'productId']),
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateQuest = (assignmentId, values) => {
    return async (dispatch, getState) => {
        const updateData = {
            assignmentId,
            label: values.title,
            description: values.subtitle,
            product: values.productId || null,
            settings: _omit(values, ['title', 'subtitle', 'handle', 'productId']),
        };
        if (values.handle) {
            updateData.handle = values.handle;
        }
        const response = await axios.post(Boilerplate.route('api.assignment.update'), updateData);

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateQuestSettings = (assignmentId, settings) => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route(`api.assignment.update`), {
            assignmentId,
            settings,
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const archiveQuest = assignmentId => {
    return async (dispatch, getState) => {
        const response = await axios.put(Boilerplate.route('api.assignment.archive', { assignmentId }));

        if (response.data.errors && response.data.errors.assignment) {
            notification({
                type: 'danger',
                message: response.data.errors.assignment[0],
            });
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const unarchiveQuest = assignmentId => {
    return async (dispatch, getState) => {
        const response = await axios.put(Boilerplate.route('api.assignment.unarchive', { assignmentId }));

        if (response.data.errors && response.data.errors.assignment) {
            notification({
                type: 'danger',
                message: response.data.errors.assignment[0],
            });
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const deleteQuest = assignmentId => {
    return async (dispatch, getState) => {
        const response = await axios.put(Boilerplate.route(`api.assignment.destroy`, { assignmentId }));

        if (response.data.errors && response.data.errors.assignment) {
            notification({
                type: 'danger',
                message: response.data.errors.assignment[0],
            });
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const createSurvey = (surveyListId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route('api.assignmentSurvey.store'), {
            label: values.title,
            description: values.subtitle,
            assignmentSurveylistId: surveyListId,
            settings: _omit(values, ['title', 'subtitle']),
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateSurvey = (surveyId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.put(Boilerplate.route('api.assignmentSurvey.update', { surveyId }), {
            label: values.title,
            description: values.subtitle,
            settings: _omit(values, ['title', 'subtitle']),
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateSurveySettings = (surveyId, settings) => {
    return async (dispatch, getState) => {
        const response = await axios.put(Boilerplate.route(`api.assignmentSurvey.update`, { surveyId }), { settings });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const archiveSurvey = surveyId => {
    return async (dispatch, getState) => {
        const response = await axios.put(Boilerplate.route('api.assignmentSurvey.archive', { surveyId }));

        if (response.data.errors && response.data.errors.survey) {
            notification({
                type: 'danger',
                message: response.data.errors.survey[0],
            });
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const unarchiveSurvey = surveyId => {
    return async (dispatch, getState) => {
        const response = await axios.put(Boilerplate.route(`api.assignmentSurvey.unarchive`, { surveyId }));

        if (response.data.errors && response.data.errors.survey) {
            notification({
                type: 'danger',
                message: response.data.errors.survey[0],
            });
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const deleteSurvey = surveyId => {
    return async (dispatch, getState) => {
        const response = await axios.put(Boilerplate.route(`api.assignmentSurvey.destroy`, { surveyId }));

        if (response.data.errors && response.data.errors.survey) {
            notification({
                type: 'danger',
                message: response.data.errors.survey[0],
            });
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const moveSurveyAndSave = ({ assignmentId, sourceIndex, destinationIndex }) => {
    return async (dispatch, getState) => {
        const getSurveylistList = () =>
            getCurrentProject(getState().assignment.admin).flows.assignmentList.find(
                item => item.assignmentId === assignmentId
            ).currentSurveylistList.surveyList;

        const prevPositions = getSurveylistList().reduce(
            (res, item) => ({ ...res, [item.surveyId]: item.position }),
            {}
        );

        dispatch(moveSurvey({ assignmentId, sourceIndex, destinationIndex }));

        const updatedSurveys = getSurveylistList().filter(item => prevPositions[item.surveyId] !== item.position);

        // TODO: send one request instead of many. For now, do one-at-a-time to
        // ensure we don't 500 with deadlocks
        for (const survey of updatedSurveys) {
            await axios.put(Boilerplate.route(`api.assignmentSurvey.update`, { surveyId: survey.surveyId }), {
                ordinalPosition: survey.position,
            });
        }

        return true;
    };
};

export const deleteMessage = (deploymentId, deploymentMessageId) => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route(`api.assignmentDeploymentMessage.destroy`, {
                deploymentId,
                deploymentMessageId,
            })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const unscheduleMessage = (deploymentId, deploymentMessageId) => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route(`api.assignmentDeploymentMessage.destroy`, {
                deploymentId,
                deploymentMessageId,
            })
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const createFlow = data => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route('flow.store'), data);

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const createWave = (assignmentId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.post(Boilerplate.route('api.assignmentDeployment.store'), {
            ...values,
            assignmentId,
        });

        if (response.data.errors) {
            throw response.data.errors;
        }

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return isSuccess;
    };
};

export const updateWave = (deploymentId, values) => {
    return async (dispatch, getState) => {
        const response = await axios.put(
            Boilerplate.route('api.assignmentDeployment.update', { deploymentId }),
            values
        );

        const isSuccess = response.data.status === 'success';
        if (isSuccess) {
            await dispatch(reloadCurrentProject());
        }

        return response;
    };
};

export const removeWave = deploymentId => {
    return async (dispatch, getState) => {
        await axios.put(Boilerplate.route('api.assignmentDeployment.destroy', { deploymentId }));
        await dispatch(reloadCurrentProject());
    };
};

export const setWaveOpenStatusAndSave = ({ deploymentId, isOpen }) => {
    return async (dispatch, getState) => {
        dispatch(setWaveOpenStatus({ deploymentId, isOpen }));

        await axios.put(Boilerplate.route('api.assignmentDeployment.update', { deploymentId }), {
            isOpen,
        });
    };
};

// reducer
export default handleActions(
    {
        [SET_PROJECT_LIST]: (state, action) => {
            const projectList = action.payload;
            const queryList = [];
            for (const project of projectList) {
                const projectQueries = project.settings?.queryList || [];
                queryList.push(...projectQueries.map(item => ({ ...item, projectId: project.groupId })));
            }

            return {
                ...state,
                projectList,
                queryList,
            };
        },
        [SET_CURRENT_PROJECT_ID]: (state, action) => {
            const nextState = {
                ...state,
                currentQueryId: '0',
                currentProjectId: action.payload,
                currentQuerySettings: {
                    ...defaultQuerySettings,
                },
            };

            const { currentAssignmentId } = state;
            const activeAssignmentsIds = getCurrentProjectAssignments(nextState)
                .filter(item => !item.archived && item.deploymentList.length > 0)
                .map(item => item.assignmentId);

            nextState.currentQuerySettings.assignmentId = currentAssignmentId;
            nextState.currentQuerySettings.deploymentIds = 'all';
            nextState.currentQuerySettings.surveyHandles = 'all';

            if (!activeAssignmentsIds.includes(currentAssignmentId)) {
                nextState.currentAssignmentId = activeAssignmentsIds[0] || 0;
                nextState.currentQuerySettings.assignmentId = activeAssignmentsIds[0] || 0;
            }

            const assignmentDeployments = getCurrentAssignmentDeployments(nextState).filter(item => !item.archived);
            const assignmentDeploymentsIds = assignmentDeployments.map(item => item.deploymentId);
            const currentDeploymentId = assignmentDeploymentsIds[0] || 0;

            nextState.currentDeploymentId = currentDeploymentId;
            nextState.currentDeploymentIds = currentDeploymentId ? [currentDeploymentId] : [];
            nextState.selectedDeploymentIds = currentDeploymentId ? [currentDeploymentId] : [];
            nextState.currentSurveyHandles = currentDeploymentId
                ? assignmentDeployments.find(d => d.deploymentId === currentDeploymentId).settings.options
                : [];

            // get default columns
            {
                const deploymentList = getCurrentQueryAssignmentActiveDeployments(nextState);
                const roleHandles = _uniq(deploymentList.map(item => item.roleHandle));

                const columns = {};
                for (const roleHandle of roleHandles) {
                    const levelInfo = window.cccisd.appDefs.pawn.roles.find(item => item.handle === roleHandle);
                    if (!levelInfo) {
                        continue;
                    }

                    const columnTree = getFlatColumnTree('roles', levelInfo.handle);
                    const importantFields = Array.isArray(levelInfo.importantFields) ? levelInfo.importantFields : [];
                    importantFields
                        .filter(field => field.path in columnTree)
                        .forEach(field => {
                            columns[field.path] = columnTree[field.path];
                        });
                }

                nextState.currentQuerySettings.columns = columns;
            }

            return nextState;
        },
        [SET_CURRENT_ASSIGNMENT_ID]: (state, action) => ({
            ...state,
            currentAssignmentId: parseInt(action.payload, 10),
            currentDeploymentId: initialState.currentDeploymentId,
            currentDeploymentIds: [],
            selectedDeploymentIds: [],
            currentSurveyHandles: [],
        }),
        [SET_CURRENT_ASSIGNMENT_ID_ONLY]: (state, action) => ({
            ...state,
            currentAssignmentId: parseInt(action.payload, 10),
        }),
        [SET_CURRENT_DEPLOYMENT_ID]: (state, action) => ({
            ...state,
            currentDeploymentId: parseInt(action.payload, 10),
            currentDeploymentIds: action.payload !== 0 ? [parseInt(action.payload, 10)] : [],
            selectedDeploymentIds: action.payload !== 0 ? [parseInt(action.payload, 10)] : [],
        }),
        [SET_SELECTED_DEPLOYMENT_IDS]: (state, action) => ({
            ...state,
            selectedDeploymentIds: action.payload,
        }),
        [SET_CURRENT_DEPLOYMENT_IDS]: (state, action) => ({
            ...state,
            currentDeploymentId: action.payload.length
                ? parseInt(action.payload[0], 10)
                : initialState.currentDeploymentId,
            currentDeploymentIds: action.payload,
        }),
        [SET_CURRENT_SURVEY_ID]: (state, action) => ({
            ...state,
            currentSurveyId: parseInt(action.payload, 10),
        }),
        [SET_CURRENT_SURVEY_HANDLE]: (state, action) => ({
            ...state,
            currentSurveyHandle: action.payload,
        }),
        [SET_CURRENT_SURVEY_HANDLES]: (state, action) => {
            return {
                ...state,
                currentSurveyHandles: action.payload,
            };
        },
        [SET_CURRENT_TABLE_ID]: (state, action) => ({
            ...state,
            currentTableId: action.payload,
        }),
        [SET_PROJECT_FLOWS]: (state, action) => ({
            ...state,
            projectList: state.projectList.map(project =>
                project.groupId === action.payload.groupId ? { ...project, flows: action.payload.flows } : project
            ),
        }),
        [SET_EXPANDED_ALL_DESIGN_ASSIGNMENTS]: (state, action) => {
            const expand = action.payload;
            const currentProjectAssignmentIds = getCurrentProjectAssignments(state).map(obj => obj.assignmentId);

            const expandedDesignAssignmentIds = expand
                ? _union(state.expandedDesignAssignmentIds, currentProjectAssignmentIds)
                : _difference(state.expandedDesignAssignmentIds, currentProjectAssignmentIds);

            return {
                ...state,
                expandedDesignAssignmentIds,
            };
        },
        [TOGGLE_COLLAPSED_DESIGN_ASSIGNMENT]: (state, action) => ({
            ...state,
            expandedDesignAssignmentIds: _xor(state.expandedDesignAssignmentIds, [action.payload]),
        }),
        [SET_EXPANDED_ALL_DEPLOY_ASSIGNMENTS]: (state, action) => {
            const expand = action.payload;
            const currentProjectAssignmentIds = getCurrentProjectAssignments(state).map(obj => obj.assignmentId);

            const expandedDeployAssignmentIds = expand
                ? _union(state.expandedDeployAssignmentIds, currentProjectAssignmentIds)
                : _difference(state.expandedDeployAssignmentIds, currentProjectAssignmentIds);

            return {
                ...state,
                expandedDeployAssignmentIds,
            };
        },
        [TOGGLE_COLLAPSED_DEPLOY_ASSIGNMENT]: (state, action) => ({
            ...state,
            expandedDeployAssignmentIds: _xor(state.expandedDeployAssignmentIds, [action.payload]),
        }),
        [TOGGLE_ARCHIVED_ASSIGNMENTS]: (state, action) => ({
            ...state,
            showArchivedAssignments: !state.showArchivedAssignments,
        }),
        [TOGGLE_ARCHIVED_SURVEYS]: (state, action) => ({
            ...state,
            showArchivedSurveys: _xor(state.showArchivedSurveys, [action.payload]),
        }),
        [SET_CURRENT_SURVEY_LIST_HANDLE]: (state, action) => ({
            ...state,
            currentSurveyListHandles: {
                ...state.currentSurveyListHandles,
                [action.payload.assignmentId]: action.payload.handle,
            },
        }),
        [MOVE_SURVEY]: (state, action) => {
            const { assignmentId, sourceIndex, destinationIndex } = action.payload;
            const { currentProjectId, currentSurveyListHandles } = state;

            try {
                const sortByPosition = (a, b) => a.position - b.position;
                const projectList = _cloneDeep(state.projectList);
                const currentProject = projectList.find(item => item.groupId === currentProjectId);
                const currentAssignment = currentProject.flows.assignmentList.find(
                    item => item.assignmentId === assignmentId
                );
                const currentSurveylistList = currentSurveyListHandles[assignmentId]
                    ? currentAssignment.surveylistList.find(
                          item => item.surveylistHandle === currentSurveyListHandles[assignmentId]
                      )
                    : currentAssignment.surveylistList[0];
                const surveyList = currentSurveylistList.surveyList.sort(sortByPosition);
                const activeSurveyList = surveyList.filter(item => !item.archived);
                const sourcePosition = activeSurveyList[sourceIndex].position;
                const destinationPosition = activeSurveyList[destinationIndex].position;

                currentSurveylistList.surveyList = surveyList
                    .map(item => {
                        let position = item.position;
                        if (item.position === sourcePosition) {
                            position = destinationPosition;
                        } else if (item.position > sourcePosition && item.position <= destinationPosition) {
                            position = item.position - 1;
                        } else if (item.position < sourcePosition && item.position >= destinationPosition) {
                            position = item.position + 1;
                        }

                        return {
                            ...item,
                            position,
                        };
                    })
                    .sort(sortByPosition);

                return {
                    ...state,
                    projectList,
                };
            } catch (e) {
                return state;
            }
        },
        [SET_WAVE_OPEN_STATUS]: (state, action) => {
            const { deploymentId, isOpen } = action.payload;
            const { currentProjectId } = state;

            try {
                const projectList = _cloneDeep(state.projectList);
                const currentProject = projectList.find(item => item.groupId === currentProjectId);
                const deployments = currentProject.flows.assignmentList.reduce(
                    (res, assignment) => [...res, ...assignment.deploymentList],
                    []
                );
                const currentDeployment = deployments.find(item => item.deploymentId === deploymentId);
                currentDeployment.isOpen = isOpen;

                return {
                    ...state,
                    projectList,
                };
            } catch (e) {
                return state;
            }
        },
        [CHANGE_DESIGN_FILTER]: (state, action) => ({
            ...state,
            designFilter: action.payload,
        }),
        [CHANGE_DEPLOY_FILTER]: (state, action) => ({
            ...state,
            deployFilter: action.payload,
        }),
        [SET_DEV_TAGS]: (state, action) => ({
            ...state,
            devTags: action.payload,
        }),
        [SET_QUERY_LIST]: (state, action) => ({
            ...state,
            queryList: action.payload,
        }),
        [ADD_NEW_QUERY]: (state, action) => {
            const newId = nanoid();
            const projectId = state.currentProjectId;

            if (!projectId) {
                return state;
            }

            return {
                ...state,
                queryList: [...state.queryList, { ...action.payload, id: newId, projectId }],
                currentQueryId: newId,
            };
        },
        [UPDATE_QUERY]: (state, action) => ({
            ...state,
            queryList: state.queryList.map(query => {
                if (query.id !== action.payload.id) {
                    return query;
                }

                return { ...query, ...action.payload };
            }),
        }),
        [DELETE_CURRENT_QUERY]: (state, action) => {
            if (!state.currentQueryId || state.currentQueryId === '0') {
                return state;
            }

            const queryList = state.queryList.filter(query => query.id !== state.currentQueryId);
            const currentQueryId = queryList.length > 0 ? queryList[0].id : '0';
            const currentQuerySettings = queryList.length > 0 ? queryList[0].settings : state.currentQuerySettings;

            return {
                ...state,
                queryList,
                currentQueryId,
                currentQuerySettings,
            };
        },
        [SET_CURRENT_QUERY_ID]: (state, action) => {
            const nextQueryId = action.payload;
            if (nextQueryId === state.currentQueryId) {
                return state;
            }

            const nextState = {
                ...state,
                currentQueryId: nextQueryId,
            };

            if (nextQueryId !== '0') {
                const foundQuery = state.queryList.find(query => query.id === nextQueryId);
                if (!foundQuery) {
                    // something wrong
                    return state;
                }

                nextState.currentQuerySettings = foundQuery.settings;
            } else {
                // revert everything to default
                nextState.currentQuerySettings = { ...defaultQuerySettings };
                const activeAssignmentsIds = getCurrentProjectAssignments(nextState)
                    .filter(item => !item.archived)
                    .map(item => item.assignmentId);

                if (activeAssignmentsIds.length === 0) {
                    return nextState;
                }

                nextState.currentQuerySettings.assignmentId = activeAssignmentsIds[0];

                // get default columns
                {
                    const deploymentList = getCurrentQueryAssignmentActiveDeployments(nextState);
                    const roleHandles = _uniq(deploymentList.map(item => item.roleHandle));

                    const columns = {};
                    for (const roleHandle of roleHandles) {
                        const levelInfo = window.cccisd.appDefs.pawn.roles.find(item => item.handle === roleHandle);
                        if (!levelInfo) {
                            continue;
                        }

                        const columnTree = getFlatColumnTree('roles', levelInfo.handle);
                        const importantFields = Array.isArray(levelInfo.importantFields)
                            ? levelInfo.importantFields
                            : [];
                        importantFields
                            .filter(field => field.path in columnTree)
                            .forEach(field => {
                                columns[field.path] = columnTree[field.path];
                            });
                    }

                    nextState.currentQuerySettings.columns = columns;
                }
            }

            return nextState;
        },
        [SET_CURRENT_QUERY_SETTINGS]: (state, action) => ({
            ...state,
            currentQuerySettings: action.payload || defaultQuerySettings,
        }),
        [SET_VIZ_DATA]: (state, action) => ({
            ...state,
            vizData: { ...state.vizData, ...action.payload },
        }),
    },
    initialState
);
