import React from 'react';
import { Field, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    title: 'Fidelity Report',
    description:
        'The fidelity report helps providers and administrators assess fidelity of implementation or the degree to which the program is delivered as intended. Fidelity reports can help providers self-monitor and identify implementation areas needing additional training or attention. High fidelity to best practices and procedures is associated with better outcomes for participants.',
};

const Component = props => {
    return (
        <div>
            <Field name="title" component={CccisdWysiwyg} label="Title" />
            <Field name="description" component={CccisdWysiwyg} label="Description" />
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
