export default ({ importantFields = [], project }) => {
    if (project) {
        for (let i = 1; i <= 5; i++) {
            const field = `field_${i}`;
            const path = `fields.${field}`;
            importantFields = importantFields.filter(f => f.path !== path);
            const label = project.fields[field];
            if (!label) {
                continue;
            }
            importantFields.push({
                label,
                path,
                identifiable: project.fields[`${field}_is_identifiable`],
            });
        }
    }
    return importantFields;
};
