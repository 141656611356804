import React from 'react';
import { ResponsiveLine } from 'cccisd-nivo/line';
import PropTypes from 'prop-types';

const MyResponsiveLine = props => {
    const { data, productHandle } = props;

    return (
        <ResponsiveLine
            data={data}
            legends={[]}
            xFormat={d => `Session ${d}`}
            theme={{ fontFamily: 'Cabin' }}
            colors={{ scheme: 'category10' }}
            yScale={{
                type: 'linear',
                min: '0',
                max: '100',
            }}
            enablePointLabel
            xScale={{
                type: 'linear',
                min: '1',
                max: productHandle === 'ssgrin_ec' ? '12' : '10',
            }}
            margin={{
                bottom: 50,
                left: 60,
                right: 20,
                top: 30,
            }}
            markers={[
                {
                    axis: 'y',
                    value: '80',
                    lineStyle: {
                        stroke: 'green',
                        strokeWidth: 1,
                    },
                },
            ]}
            axisLeft={{
                legend: 'Fidelity',
                legendPosition: 'middle',
                tickPadding: 10,
                legendOffset: -50,
                tickValues: ['20', '40', '60', '80', '100'],
            }}
            axisBottom={{
                legend: 'Sessions',
                legendPosition: 'middle',
                tickPadding: 10,
                legendOffset: 44,
            }}
        />
    );
};

MyResponsiveLine.propTypes = {
    data: PropTypes.array,
    productHandle: PropTypes.string,
};

export default MyResponsiveLine;
