export default {
    design: { path: 'design', permissions: ['quest.design', 'quest.admin'] },
    deploy: { path: 'deploy', permissions: ['quest.deploy', 'quest.admin'] },
    status: { path: 'status', permissions: ['quest.status', 'quest.admin'] },
    data: { path: 'data', permissions: ['quest.view_data', 'quest.admin'] },
    code: { path: 'code', permissions: ['quest.code', 'quest.admin'] },
    tables: { path: 'tables', permissions: ['quest.tables', 'quest.admin'] },
    permissions: { path: 'permissions', permissions: ['quest.permissions', 'quest.admin'] },
    manage: { path: 'projects', permissions: ['quest.admin'] },
};
