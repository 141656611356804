import _get from 'lodash/get';

const appDefs = window.cccisd.appDefs || {};

const levels = {
    groups: {
        rows: _get(appDefs, 'pawn.groups', []).map(item => ({
            value: item.handle,
            label: item.label,
        })),
        key: 'group.groupId',
        initialColumns: [
            {
                id: 1,
                name: 'group.groupId',
                query: 'group.groupId',
                label: 'Group ID',
                type: 'number',
                alignment: 'left',
                filter: true,
                sort: true,
            },
            {
                id: 2,
                name: 'group.label',
                query: 'group.label',
                label: 'Label',
                type: 'string',
                alignment: 'left',
                filter: true,
                sort: true,
            },
        ],
        tree: [
            {
                label: 'About group',
                children: ['group.groupId', 'group.label'],
            },
        ],
        ancestorKey: 'parentGroups',
    },
    roles: {
        rows: _get(appDefs, 'pawn.roles', []).map(item => ({
            value: item.handle,
            label: item.label,
        })),
        key: 'pawn.pawnId',
        initialColumns: [
            {
                id: 1,
                name: 'pawn.pawnId',
                query: 'pawn.pawnId',
                label: 'System ID',
                type: 'number',
                alignment: 'left',
                filter: true,
                sort: true,
            },
        ],
        tree: [
            {
                label: 'About pawn',
                children: ['pawn.pawnId', 'pawn.createdDate', 'pawn.roleName'],
            },
        ],
        ancestorKey: 'membersOf',
    },
};

const allColumns = (() => {
    if (process.env.NODE_ENV === 'test') {
        return {};
    }

    const columns = {
        'pawn.pawnId': { label: 'System ID', type: 'number' },
        'pawn.createdDate': { label: 'Created Date', type: 'string' },
        'pawn.roleName': { label: 'Role Name', type: 'string' },
        'group.groupId': { label: 'Group ID', type: 'number' },
        'group.label': { label: 'Label', type: 'string' },
        'user.username': { label: 'Username', type: 'string' },
        'user.firstName': { label: 'First Name', type: 'string' },
        'user.lastName': { label: 'Last Name', type: 'string' },
        'user.fullName': { label: 'Full Name', type: 'string' },
        'user.fullNameWithEmail': { label: 'Full Name with Email', type: 'string' },
        'user.fullNameWithUsername': { label: 'Full Name with Username', type: 'string' },
        'user.email': { label: 'Email', type: 'string' },
        'user.phone': { label: 'Phone', type: 'string' },
        'user.activated': { label: 'Activated', type: 'boolean' },
        'user.hasLoggedIn': { label: 'Has Logged In?', type: 'boolean' },
        'user.lastLoginDate': { label: 'Last Login Date', type: 'string' },
    };

    appDefs.pawn.fields.forEach(field => {
        columns[`fields.${field.handle}`] = { label: field.label, type: field.data_type };
    });

    appDefs.pawn.groups.forEach(group => {
        columns[`descendantGroups.${group.handle}Count`] = {
            label: `${group.label} count`,
            type: 'number',
        };
    });

    appDefs.pawn.roles.forEach(role => {
        columns[`childRoles.${role.handle}Count`] = {
            label: `${role.label} count`,
            type: 'number',
        };
        columns[`descendantRoles.${role.handle}Count`] = {
            label: `${role.label} count`,
            type: 'number',
        };
    });

    return columns;
})();

const getFields = (prefix = '', level, rows) => {
    const levelInfo = _get(appDefs, `pawn.${level}`, []).find(item => item.handle === rows);

    return levelInfo && levelInfo.fields
        ? levelInfo.fields.map(name => ({
              id: `${prefix}fields.${name}`,
              ...allColumns[`fields.${name}`],
          }))
        : [];
};

export const getFlatColumnTree = (level, rows) => {
    const result = {};
    const tree = getColumnTree(level, rows);

    function traverse(node) {
        node.forEach((item, index) => {
            if (item.children) {
                traverse(item.children);
            } else {
                result[item.id] = item;
            }
        });
    }
    traverse(tree);

    return result;
};

const getColumnTree = (level = 'roles', rows = 'wrong') => {
    const tree = [...levels[level].tree];

    // Get user information if it's a fortress user
    (() => {
        if (level !== 'roles') {
            return;
        }

        const info = _get(appDefs, `pawn.${level}`, []).find(item => item.handle === rows);
        if (info && info.isFortressUser) {
            tree.push({
                label: 'User info',
                children: [
                    'user.username',
                    'user.firstName',
                    'user.lastName',
                    'user.fullName',
                    'user.fullNameWithEmail',
                    'user.fullNameWithUsername',
                    'user.email',
                    'user.phone',
                    'user.activated',
                    'user.hasLoggedIn',
                    'user.lastLoginDate',
                ],
            });
        }
    })();

    // Get fields
    tree.push({
        label: 'Fields',
        children: getFields('', level, rows),
    });

    const addedGroups = new Set();

    // Get ancestors
    function getAncestors(prefix = '', level1, rows1) {
        const info = _get(appDefs, `pawn.${level1}`, []).find(item => item.handle === rows1);
        const key = levels[level1].ancestorKey;
        if (info && info[key] && info[key].length > 0) {
            for (const group of info[key]) {
                if (addedGroups.has(group)) {
                    continue;
                }

                const groupInfo = appDefs.pawn.groups.find(item => item.handle === group);
                if (groupInfo) {
                    addedGroups.add(group);
                    tree.push({
                        label: groupInfo.label,
                        children: [
                            ...['group.groupId', 'group.label'].map(name => ({
                                ...allColumns[name],
                                id: `${prefix}ancestorGroups.${group}.${name}`,
                                label: `${groupInfo.label} ${allColumns[name].label}`,
                            })),
                            {
                                label: 'Fields',
                                children: getFields(`${prefix}ancestorGroups.${group}.`, 'groups', group),
                            },
                        ],
                    });
                    getAncestors('', 'groups', group);
                }
            }
        }
    }
    getAncestors('', level, rows);

    // Get descendant
    if (level === 'groups') {
        const childRoles = [];
        const childGroups = [];

        const getDescendants = rows1 => {
            const info = _get(appDefs, `pawn.${level}`, []).find(item => item.handle === rows1);
            if (info && info.containsRoles) {
                childRoles.push(...info.containsRoles);
            }
            if (info && info.childGroups) {
                childGroups.push(...info.childGroups);
                info.childGroups.forEach(getDescendants);
            }
        };
        getDescendants(rows);

        if (childRoles.length > 0) {
            tree.push({
                label: 'Role Counts',
                children: childRoles.map(child => `descendantRoles.${child}Count`),
            });
        }
        if (childGroups.length > 0) {
            tree.push({
                label: 'Group Counts',
                children: childGroups.map(child => `descendantGroups.${child}Count`),
            });
        }
    }

    // Get child roles
    if (level === 'roles') {
        const info = _get(appDefs, `pawn.${level}`, []).find(item => item.handle === rows);

        if (info && info.childRoles && info.childRoles.length > 0) {
            tree.push({
                label: 'Role Counts',
                children: info.childRoles.map(child => `childRoles.${child}Count`),
            });
        }
    }

    // Add sort information
    (() => {
        let sort = 1;
        function traverse(node, prefix = '') {
            node.forEach((item, index) => {
                if (typeof item === 'string') {
                    node[index] = {
                        id: item,
                        sort: sort++,
                        ...allColumns[item],
                    };
                } else if (item.children) {
                    item.id = `${prefix}${item.label}`;
                    traverse(item.children, `${prefix}${item.label}.`);
                } else {
                    item.sort = sort++;
                }
            });
        }
        traverse(tree);
    })();

    return tree;
};

export default getColumnTree;
