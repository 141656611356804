import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { initialize } from 'js/reducers/reportFilters.js';
import Component from './component.js';

import Loader from 'cccisd-loader';
const Wrapper = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await dispatch(initialize());
        })();
    }, []);

    return (
        <>
            <Loader loading={props.loading} removeChildren>
                <Component {...props} />
            </Loader>
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    learner: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noLearners: PropTypes.bool,
    currentProduct: PropTypes.number,
    productIds: PropTypes.array,
    productId: PropTypes.number,
};

const mapStateToProps = state => ({
    loading: state.app.reportFilters.loading,
    learner: state.app.reportFilters.learner,
    group: state.app.reportFilters.group,
    noGroups: state.app.reportFilters.noGroups,
    noLearners: state.app.reportFilters.noLearners,
    currentProduct: state.app.reportFilters.currentProduct,
    productIds: state.app.reportFilters.productIds,
    productId: state.app.products.productId,
});

export default connect(mapStateToProps, {
    initialize,
})(Wrapper);
