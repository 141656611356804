import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const SimpleBarChart = ({ metrics, colors, tickValues, markers, maxValue, formula }) => {
    // const colors = { Overall: '#DC3913' };
    // metrics - raw data
    // colors - object
    // keys - array
    // tickValues - array
    // markers - array

    const getColor = bar => colors[bar.id];

    const chartData = ['pre', 'post', 'followUp'].map((item, i) => {
        if (metrics[item] || metrics[item] === 0) {
            if (i === 0) {
                return {
                    timepoint: 'Pre',
                    Overall: formula ? formula(metrics[item]) : metrics[item],
                };
            }
            if (i === 1) {
                return {
                    timepoint: 'Post',
                    Overall: formula ? formula(metrics[item]) : metrics[item],
                };
            }
            if (i === 2) {
                return {
                    timepoint: 'Follow Up',
                    Overall: formula ? formula(metrics[item]) : metrics[item],
                };
            }
        }
        return null;
    });

    return (
        <div style={{ width: '100%', height: '300px', paddingLeft: '4em', marginTop: '1em' }}>
            <ResponsiveBar
                data={chartData}
                keys={['Overall']}
                indexBy="timepoint"
                colors={getColor}
                maxValue={maxValue}
                axisLeft={{
                    legend: 'Score',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues,
                }}
                labelFormat={d => {
                    const isMax = maxValue === +d;

                    return (
                        <tspan style={isMax ? { fill: '#fff' } : {}} y={isMax ? 10 : -10}>
                            {Math.round(+d * 100) / 100}
                        </tspan>
                    );
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                markers={markers}
            />
        </div>
    );
};

export default SimpleBarChart;
