import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch, Redirect, useParams } from 'cccisd-react-router';
import { AdminRoutes, Manage as AppdefManage, Dashboard as AppdefsDashboard } from 'cccisd-laravel-appdefs';
import { AuthRedirect, RegisterLoginForm, ActivationForm } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter } from 'cccisd-laravel-resources';
import { LicensePrompt } from 'cccisd-laravel-licensing';
import ReportCenter from 'cccisd-report-center';
import CompletedTrainingsFields from './components/CompletedTrainingsFields';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';
import ProductLayout from './layouts/ProductLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import Products from './pages/Products';
import Training from './pages/Training';
import SessionData from './pages/SessionData';
import ProgressData from './pages/ProgressData';
import OutcomesData from './pages/OutcomesData';
import Reports from './pages/Reports';
import Dashboard from './pages/Dashboard';
import ProductResourceCenter from './pages/ProductResourceCenter';
import CourseProgress from './pages/CourseProgress';

import MarketplaceReportCenter from './pages/MarketplaceReportCenter';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';
import Licensing from 'bundle-loader?lazy!cccisd-laravel-licensing';

const Boilerplate = window.cccisd.boilerplate;

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

const Fortress = window.cccisd && window.cccisd.fortress;
const AppDefinitions = window.cccisd && window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    Welcome,
    LicensePrompt,
    Training,
    OutcomesData,
    SessionData,
    ProgressData,
    Dashboard,
    AppdefsDashboard,
    Reports,
    ReportCenter,
    ProductResourceCenter,
    MarketplaceReportCenter,
    CourseProgress,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
    ProductLayout,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact={'exact' in route ? route.exact : true}
                    layout={'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayout}
                    layoutProps={route.layoutProps}
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact />
        <AppRoute path="/welcome" component={Welcome} />
        <AppRoute
            path="/3c-resource-center/:tab?/:id?"
            component={ResourceCenter}
            componentProps={{ handle: '3CISDResourceCenter' }}
        />

        <AppRoute path="/products" component={Products} componentProps={{ handle: 'ProductCenter' }} />

        <AppRoute
            path="/product/:productHandle"
            exact
            component={({ match }) => {
                if (!Fortress.auth()) {
                    return <Redirect to="/authRedirect" />;
                }
                const productNav = AppDefinitions.navs.find(nav => nav.handle === match.params.productHandle) || {};

                const productHomepage = AppDefinitions.routes.find(route => route.handle === productNav.homepage) || {};
                const productHomepageUrl =
                    productHomepage.url.replace(':productHandle', match.params.productHandle) || '/';
                return <Redirect to={productHomepageUrl} />;
            }}
        />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayout)}

        <AppRoute
            path="/account"
            component={Nexus}
            componentProps={{
                enableSelfRoster: true,
                selfRosterProps: {
                    registerProps: {
                        renderAdditionalFields: CompletedTrainingsFields,
                    },
                },
            }}
        />

        <AppRoute
            exact
            path="/licensing/voucher/register&redeem/:activationToken/:code"
            component={() => {
                const { activationToken, code } = useParams();
                return (
                    <ActivationForm
                        activationToken={activationToken}
                        alsoLogin
                        redirectUrl={Boilerplate.url(`/licensing/voucher/redeem/${code}`)}
                    />
                );
            }}
        />
        <AppRoute
            path="/licensing"
            component={Licensing}
            componentProps={{
                renderAuthRedirect: ({ code }) => (
                    <RegisterLoginForm
                        loginProps={{
                            title: 'Login',
                            defaultRedirectUrl: Boilerplate.url(`/licensing/voucher/redeem/${code}`),
                        }}
                        registerProps={{
                            title: 'Register',
                            role: 'instructor',
                            renderAdditionalFields: CompletedTrainingsFields,
                            onRegisterSuccess: (formValues, responseData) =>
                                window.location.replace(
                                    Boilerplate.url(
                                        `/licensing/voucher/register&redeem/${responseData.user.activation_token}/${code}`
                                    )
                                ),
                        }}
                    />
                ),
            }}
        />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
