import React from 'react';
import { calcAvg } from '../../vendor/reports/reportTemplates/common/helpers';

import IconSmile from 'cccisd-icons/smile';
import IconSad from 'cccisd-icons/wondering';

const SessionAvgFidelity = settings => {
    return ({ row }) => {
        const sessionNumber = settings.sessionId;

        const renderScoreAndSmiley = scoresArray => {
            const nonNulls = scoresArray
                .filter(score => score !== null)
                .filter(score => score !== '')
                .map(score => +score);

            const overallAvgFidelity = nonNulls.length > 0 ? calcAvg(nonNulls).toFixed(0) : null;

            let icon = <IconSmile />;
            let color = '#3673b6';

            if (+overallAvgFidelity < 80) {
                icon = <IconSad />;
                color = '#d03916';
            }

            return overallAvgFidelity ? (
                <>
                    <span style={{ color }}>{icon} </span>
                    {`${overallAvgFidelity}%`}
                </>
            ) : null;
        };

        const averageScore = () => {
            switch (sessionNumber) {
                case '1':
                    return renderScoreAndSmiley([
                        row['sess1.avgFidComponent'],
                        row['sess1.devTags.ProgramAdherence'],
                        row['sess1.devTags.ContentSupplemented'],
                        row['sess1.devTags.ParticipantResponsiveness'],
                    ]);
                case '2':
                    return renderScoreAndSmiley([
                        row['sess2.avgFidComponent'],
                        row['sess2.devTags.ProgramAdherence'],
                        row['sess2.devTags.ContentSupplemented'],
                        row['sess2.devTags.ParticipantResponsiveness'],
                    ]);
                case '3':
                    return renderScoreAndSmiley([
                        row['sess3.avgFidComponent'],
                        row['sess3.devTags.ProgramAdherence'],
                        row['sess3.devTags.ContentSupplemented'],
                        row['sess3.devTags.ParticipantResponsiveness'],
                    ]);
                case '4':
                    return renderScoreAndSmiley([
                        row['sess4.avgFidComponent'],
                        row['sess4.devTags.ProgramAdherence'],
                        row['sess4.devTags.ContentSupplemented'],
                        row['sess4.devTags.ParticipantResponsiveness'],
                    ]);
                case '5':
                    return renderScoreAndSmiley([
                        row['sess5.avgFidComponent'],
                        row['sess5.devTags.ProgramAdherence'],
                        row['sess5.devTags.ContentSupplemented'],
                        row['sess5.devTags.ParticipantResponsiveness'],
                    ]);
                case '6':
                    return renderScoreAndSmiley([
                        row['sess6.avgFidComponent'],
                        row['sess6.devTags.ProgramAdherence'],
                        row['sess6.devTags.ContentSupplemented'],
                        row['sess6.devTags.ParticipantResponsiveness'],
                    ]);
                case '7':
                    return renderScoreAndSmiley([
                        row['sess7.avgFidComponent'],
                        row['sess7.devTags.ProgramAdherence'],
                        row['sess7.devTags.ContentSupplemented'],
                        row['sess7.devTags.ParticipantResponsiveness'],
                    ]);
                case '8':
                    return renderScoreAndSmiley([
                        row['sess8.avgFidComponent'],
                        row['sess8.devTags.ProgramAdherence'],
                        row['sess8.devTags.ContentSupplemented'],
                        row['sess8.devTags.ParticipantResponsiveness'],
                    ]);
                case '9':
                    return renderScoreAndSmiley([
                        row['sess9.avgFidComponent'],
                        row['sess9.devTags.ProgramAdherence'],
                        row['sess9.devTags.ContentSupplemented'],
                        row['sess9.devTags.ParticipantResponsiveness'],
                    ]);
                case '10':
                    return renderScoreAndSmiley([
                        row['sess10.avgFidComponent'],
                        row['sess10.devTags.ProgramAdherence'],
                        row['sess10.devTags.ContentSupplemented'],
                        row['sess10.devTags.ParticipantResponsiveness'],
                    ]);
                case '11':
                    return renderScoreAndSmiley([
                        row['sess11.avgFidComponent'],
                        row['sess11.devTags.ProgramAdherence'],
                        row['sess11.devTags.ContentSupplemented'],
                        row['sess11.devTags.ParticipantResponsiveness'],
                    ]);
                case '12':
                    return renderScoreAndSmiley([
                        row['sess12.avgFidComponent'],
                        row['sess12.devTags.ProgramAdherence'],
                        row['sess12.devTags.ContentSupplemented'],
                        row['sess12.devTags.ParticipantResponsiveness'],
                    ]);

                default:
                    return null;
            }
        };

        return <div style={{ whiteSpace: 'nowrap' }}>{averageScore()}</div>;
    };
};
export default SessionAvgFidelity;
