import React, { useState, useEffect, useContext, useRef } from 'react';
import { client as apollo } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ResourceCenter } from 'cccisd-laravel-resources';
import Loader from 'cccisd-loader';
import EnterIcon from 'cccisd-icons/enter';
import CartIcon from 'cccisd-icons/cart';
import LeftArrowIcon from 'cccisd-icons/arrow-left';
import RightArrowIcon from 'cccisd-icons/arrow-right';
import query from './products.graphql';
import style from './style.css';
import _uniq from 'lodash/uniq';
import Modal from 'cccisd-modal';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;
const userPawns = Fortress.user.pawns;

function Products(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [interventions, setInterventions] = useState([]);
    const modal = useRef();

    useEffect(() => {
        if (Fortress.auth()) {
            getMyInterventions();
        } else {
            setIsLoading(false);
        }
    }, []);

    async function getMyInterventions() {
        const pawns = userPawns.map(item => item.id);
        const res = await apollo.query({
            query,
            variables: {
                pawnId: pawns,
            },
        });

        if (res.data) {
            const formattedData = [];
            const productList = _uniq(res.data.products.ownedLicenseList.map(item => item.product.handle));

            productList.forEach(product => {
                const combinedProduct = res.data.products.ownedLicenseList.filter(
                    item => item.product.handle === product
                );
                formattedData.push(combinedProduct);
            });

            setInterventions(formattedData);
            setIsLoading(false);
        }
    }

    function LeftArrow() {
        const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators, initComplete } =
            useContext(VisibilityContext);

        const [disabled, setDisabled] = React.useState(!initComplete || (initComplete && isFirstItemVisible));
        React.useEffect(() => {
            if (visibleItemsWithoutSeparators.length) {
                setDisabled(isFirstItemVisible);
            }
        }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

        return (
            <button
                className={`btn ${style.scrollButton} ${style.scrollButtonLeft}`}
                style={{
                    opacity: disabled ? '0' : '1',
                }}
                type="button"
                disabled={disabled}
                onClick={() => scrollPrev()}
            >
                <LeftArrowIcon />
            </button>
        );
    }

    function RightArrow() {
        const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } = useContext(VisibilityContext);

        const [disabled, setDisabled] = React.useState(!visibleItemsWithoutSeparators.length && isLastItemVisible);
        React.useEffect(() => {
            if (visibleItemsWithoutSeparators.length) {
                setDisabled(isLastItemVisible);
            }
        }, [isLastItemVisible, visibleItemsWithoutSeparators]);

        return (
            <button
                className={`btn ${style.scrollButton} ${style.scrollButtonRight}`}
                style={{
                    opacity: disabled ? '0' : '1',
                }}
                type="button"
                disabled={disabled}
                onClick={() => scrollNext()}
            >
                <RightArrowIcon />
            </button>
        );
    }

    const handleClick = async (product, pawn) => {
        const redirectUrl = Boilerplate.url(`/product/${product.product.handle}`);

        if (pawn.id === Fortress.user.pawnId) {
            window.location.href = redirectUrl;
        } else {
            const response = await axios.post(Boilerplate.route('api.nexus.user.changeTo'), {
                pawn: pawn.id,
            });

            if (response.status !== 200 || !response.data.data) {
                console.error('Failed to switch roles.');
            }
            if (response.status === 200 && response.data.data) {
                window.location.href = redirectUrl;
            }
        }
    };

    function Card({ n, itemId }) {
        const product = n[0];
        const hasMultiplePawns = n.length > 1;
        const allExpired = n.every(item => item.isExpired);
        const expiredList = n.filter(item => item.isExpired);
        const notExpiredList = n.filter(item => !item.isExpired);
        const singleExpiryDate = n[0].expiresAt;
        const licensePawnMatch = n.pawnId === Fortress.user.acting.id;
        const isUber = Fortress.isSuperUser();

        if (hasMultiplePawns) {
            return (
                <div key={itemId} className={style.card}>
                    <div
                        className={style.thumbImage}
                        style={{
                            backgroundImage: `url(${
                                product.product.settings?.imageUrl || product.product.resourceList[0]?.thumbnail || ''
                            })`,
                        }}
                    />
                    <strong className={style.cardLabel}>{product.product.label}</strong>
                    <>
                        <p style={{ flexGrow: '1' }}>
                            {!allExpired && <strong>{notExpiredList.length} Active License(s)</strong>}
                            <div>
                                {expiredList.length > 0 && <strong>{expiredList.length} Expired License(s)</strong>}
                            </div>
                        </p>
                        <Modal
                            ref={modal}
                            size="medium"
                            title={`${product.product.label} Licenses`}
                            trigger={
                                <button type="button" className={`btn ${style.buttonEnter}`}>
                                    View Licenses
                                    <EnterIcon spaceLeft />
                                </button>
                            }
                        >
                            <div>
                                {n.map((item, i) => {
                                    const pawnMatch = userPawns.find(pawn => item.pawnId === pawn.id);
                                    const expiryDate = item.expiresAt;
                                    if (pawnMatch) {
                                        return (
                                            <div className={style.roleButton} key={i}>
                                                <h3>{pawnMatch.name}</h3>
                                                <p>
                                                    <strong>{item.isExpired ? `Expired On:` : `Expires:`}</strong>{' '}
                                                    {expiryDate}
                                                </p>
                                                <button
                                                    type="button"
                                                    className={
                                                        item.isExpired
                                                            ? `btn ${style.buttonRenew}`
                                                            : `btn ${style.buttonEnter}`
                                                    }
                                                    onClick={() => {
                                                        handleClick(product, pawnMatch);
                                                    }}
                                                >
                                                    {item.isExpired ? (
                                                        <>
                                                            Renew <CartIcon spaceLeft />
                                                        </>
                                                    ) : (
                                                        <>
                                                            Enter <EnterIcon spaceLeft />
                                                        </>
                                                    )}
                                                </button>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </Modal>
                    </>
                </div>
            );
        }

        if (isUber && !licensePawnMatch) {
            return null;
        }

        return (
            <div key={itemId} className={style.card}>
                <div
                    className={style.thumbImage}
                    style={{
                        backgroundImage: `url(${
                            product.product.settings?.imageUrl || product.product.resourceList[0]?.thumbnail || ''
                        })`,
                    }}
                />
                <strong className={style.cardLabel}>{product.product.label}</strong>
                <>
                    {n[0].isExpired ? (
                        <>
                            <p style={{ flexGrow: '1' }}>
                                <strong>Expired on: </strong>
                                {singleExpiryDate}
                            </p>
                            <a
                                href={Boilerplate.url(`/product/${n[0].product.handle}`)}
                                className={`btn ${style.buttonRenew}`}
                            >
                                Renew
                                <CartIcon spaceLeft />
                            </a>
                        </>
                    ) : (
                        <>
                            <p style={{ flexGrow: '1' }}>
                                <strong>Expires: </strong> {singleExpiryDate}
                            </p>
                            <a
                                href={Boilerplate.url(`/product/${n[0].product.handle}`)}
                                className={`btn ${style.buttonEnter}`}
                            >
                                Enter
                                <EnterIcon spaceLeft />
                            </a>
                        </>
                    )}
                </>
            </div>
        );
    }

    if (isLoading) {
        return <Loader loading />;
    }

    return (
        <>
            <h1 className={style.h1}>Products</h1>
            {interventions.length > 0 && (
                <section className={style.scrollMenu}>
                    <h2 className={style.h2}>My Interventions</h2>
                    <ScrollMenu
                        LeftArrow={<LeftArrow />}
                        RightArrow={<RightArrow />}
                        itemClassName={style.item}
                        scrollContainerClassName={style.wrapper}
                    >
                        {interventions.map((n, i) => {
                            return <Card key={i} n={n} itemId={i} />;
                        })}
                    </ScrollMenu>
                </section>
            )}
            <ResourceCenter {...props} />
        </>
    );
}

export default Products;
