import React, { useRef, useEffect } from 'react';
import Modal from 'cccisd-modal';
import { useParams } from 'cccisd-react-router';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';

import { getProducts } from '../../../../reducers/products';
import { useDispatch, useSelector } from 'react-redux';
import LearnerForm from '../../../../components/Manage/LearnerForm';

import IconPlus from 'cccisd-icons/plus-circle2';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;
const isUberAdmin = userRole === 'uberadmin';

const AddLearnerForm = props => {
    const { productHandle } = useParams();
    const modal = useRef();
    const dispatch = useDispatch();

    !isUberAdmin &&
        useEffect(() => {
            (async () => {
                dispatch(getProducts(productHandle));
            })();
        }, [dispatch]);

    const { productId } = useSelector(state => state.app.products);

    const onSubmit = async values => {
        const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
            role: 'learner',
            productId: isUberAdmin ? values.productId : productId,
            participantName: values.participantName,
            participantId: values.participantId,
            gender: values.gender,
            grade: values.grade,
            teacherName: values.teacherName,
            teacherEmail: values.teacherEmail,
            teacherPhone: values.teacherPhone,
            parentEmail: values.parentEmail,
            parentPhone: values.parentPhone,
            group: values.school,
            parentPawns: values.allClassesAnyProduct,
        });

        if (response.data.errors) {
            return Promise.reject(response.data.errors);
        }

        await props.loadData();
    };

    return (
        <Modal
            ref={modal}
            trigger={
                <button type="button" className="btn btn-primary">
                    <span>
                        <IconPlus spaceRight />
                        Add Participant
                    </span>
                </button>
            }
            title="Add New Participant"
        >
            <LearnerForm operation="create" productId={productId} onSubmit={onSubmit} />
        </Modal>
    );
};

AddLearnerForm.propTypes = {
    loadData: PropTypes.func,
};

export default AddLearnerForm;
