import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, CccisdInput, CccisdFieldWrapper, CccisdSelect } from 'cccisd-formik';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';
import _isEqual from 'lodash/isEqual';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import CompletedTrainingsFields from '../../CompletedTrainingsFields/index.js';
import RequiredFieldLabel from '../RequiredFieldLabel/index.js';
import { userCheck, getInitialValues } from '../manageFormHelpers.js';
import style from '../style.css';

import schoolsQuery from './schools.graphql';
import supervisorsQuery from './supervisors.graphql';

const SiteAdminGroupLeaderForm = props => {
    const [supervisors, setSupervisors] = useState([]);

    const { onSubmit, closeModal, operation, initialValues, role } = props;

    operation === 'edit' &&
        role === 'instructor' &&
        useEffect(() => {
            async function start() {
                await getSupervisors(initialValues.site);
            }
            start();
        }, []);

    async function getSupervisors(schoolId) {
        const res = await apollo.query({
            query: supervisorsQuery,
            variables: {
                schoolId: schoolId.length === 1 ? schoolId[0] : 0,
            },
            fetchPolicy: 'network-only',
        });

        const arrOfSupervisors = res.data.roles.supervisorList;

        if (arrOfSupervisors.length < 1) {
            return <Loader loading type="inline" />;
        }

        setSupervisors(arrOfSupervisors);
    }

    const roleToAdd = role;

    const onHandleSubmit = async values => {
        values.school = values.site.length === 1 ? values.site[0] : null;

        if (roleToAdd === 'instructor' && values.supervisor) {
            values.supervisorArray = JSON.stringify(values.supervisor);
        }

        const training = { ...values };

        delete training.username;
        delete training.firstName;
        delete training.lastName;
        delete training.school;
        delete training.available;
        delete training.existingUser;

        await onSubmit(values, training);
        closeModal();
    };

    const supervisorOptions = () => {
        return supervisors.map(sup => {
            return {
                key: sup.pawn.pawnId,
                value: +sup.pawn.pawnId,
                label: `${sup.user.fullNameWithUsername}`,
            };
        });
    };

    const validate = values => {
        const errors = {};

        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!values.username) {
            errors.username = 'Username is required.';
        }
        if (!values.firstName) {
            errors.firstName = 'First Name is required.';
        }
        if (!values.lastName) {
            errors.lastName = 'Last Name is required.';
        }
        if (values.site.length < 1) {
            errors.site = 'School is required.';
        }
        if (values.username && !regexEmail.test(values.username)) {
            errors.username = 'Please input a valid email.';
        }

        return errors;
    };

    return (
        <Formik
            onSubmit={e => onHandleSubmit(e)}
            validate={e => validate(e)}
            initialValues={getInitialValues(initialValues)}
        >
            {({ handleSubmit, values, setFieldValue }) => (
                <Form>
                    {values.available ? <div className={style.usernameValid}>Username available.</div> : null}
                    {values.existingUser && operation === 'create' ? (
                        <div className="alert alert-info" role="alert" style={{ textAlign: 'center' }}>
                            User already exists - filling in details.
                        </div>
                    ) : null}
                    <Field
                        name="username"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Username (Email):</RequiredFieldLabel>}
                        validateOnBlur={userCheck(values, roleToAdd)}
                        disabled={values.existingUser || operation === 'edit'}
                    />
                    <Field
                        name="firstName"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>First Name:</RequiredFieldLabel>}
                    />
                    <Field
                        name="lastName"
                        component={CccisdInput}
                        label={<RequiredFieldLabel isRequired>Last Name:</RequiredFieldLabel>}
                    />
                    <Field name="idNumber" component={CccisdInput} label="ID Number:" />
                    <Field name="site" enableReinitialize>
                        {({ field, form }) => {
                            return (
                                <CccisdFieldWrapper
                                    field={field}
                                    form={form}
                                    label={<RequiredFieldLabel isRequired>School:</RequiredFieldLabel>}
                                >
                                    <Table
                                        query={schoolsQuery}
                                        graphqlVariables={{}}
                                        rowKey="group.groupId"
                                        columns={[
                                            {
                                                name: 'group.label',
                                                label: 'School',
                                                sort: true,
                                                filter: true,
                                            },
                                            {
                                                name: 'ancestorGroups.groupingUnit.group.label',
                                                label: 'District',
                                                sort: true,
                                                filter: true,
                                            },
                                            {
                                                name: 'ancestorGroups.groupingUnit.ancestorGroups.organization.group.label',
                                                label: 'Organization',
                                                sort: true,
                                                filter: true,
                                            },
                                        ]}
                                        orderBy="group.label"
                                        csvFilename="Schools.csv"
                                        showRowActions={false}
                                        maxSelectableRows={1}
                                        selectedRows={operation === 'edit' ? initialValues.site : field.value}
                                        rowSelectedCallback={selectedRows => {
                                            if (!_isEqual(selectedRows, field.value)) {
                                                setFieldValue('site', selectedRows);

                                                if (roleToAdd === 'instructor') {
                                                    getSupervisors(selectedRows);
                                                }
                                            }
                                        }}
                                        rowActions={[
                                            {
                                                name: 'addToSchool',
                                                title: 'Add to School',
                                                action: () => {},
                                            },
                                        ]}
                                    />
                                </CccisdFieldWrapper>
                            );
                        }}
                    </Field>

                    {/* group leaders can have an instructor paired to them  */}
                    {roleToAdd === 'instructor' && values.site && values.site.length > 0 ? (
                        <Field
                            name="supervisor"
                            component={CccisdSelect}
                            label="Supervisor:"
                            options={[
                                {
                                    value: 0,
                                    label: '-- Please select a Supervisor --',
                                },
                                ...supervisorOptions(supervisors),
                            ]}
                            onChange={event => {
                                const supervisor = event.target.value;
                                setFieldValue('supervisor', [Number(supervisor)]);
                            }}
                        />
                    ) : null}
                    <hr />
                    <CompletedTrainingsFields labelText="Has this user ever completed the trainings for these interventions?" />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1em',
                        }}
                    >
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

SiteAdminGroupLeaderForm.propTypes = {
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    operation: PropTypes.string,
    productId: PropTypes.number,
    productLabel: PropTypes.string,
    initialValues: PropTypes.object,
    role: PropTypes.string,
};

export default SiteAdminGroupLeaderForm;
