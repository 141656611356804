import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'cccisd-react-router';
import widgets from '../../common/widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';
import filterFields from '../../common/filterFields.js';
import style from './style.css';
import query from './metrics.graphql';
import { getDates, getActualFields } from '../../common/helpers.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import InfoIcon from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import ConductTip from './ConductTip.png';
import EmoTip from './EmoTip.png';
import HyperTip from './HyperTip.png';
import PeerTip from './PeerTip.png';
import ProsocialTip from './ProsocialTip.png';
import TotalTip from './TotalTip.png';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);
    const [currentChart, setCurrentChart] = useState('totalDifficulties');

    const { productHandle } = useParams();

    useEffect(() => {
        if (noProgress === true) {
            setNoProgress(false);
        }

        (async () => {
            try {
                const queryHandle = productHandle
                    ? productHandle
                    : props.productIds.find(item => item.value === props.filters.product).handle;

                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.filters.participant,
                            productHandle: queryHandle,
                            productId: props.productId || props.filters.product,
                        },
                    })
                    .then(response => {
                        const post = response.data.roles.learner.postProgress.devTags;
                        const pre = response.data.roles.learner.preProgress.devTags;
                        const followUp = response.data.roles.learner.followUpProgress.devTags;

                        const totalDifficulties = {
                            pre: pre.SDQTotalDifficultiesScore,
                            post: post.SDQTotalDifficultiesScore,
                            followUp: followUp.SDQTotalDifficultiesScore,
                        };
                        const emotional = {
                            pre: pre.SDQEmotionalScore,
                            post: post.SDQEmotionalScore,
                            followUp: followUp.SDQEmotionalScore,
                        };
                        const conduct = {
                            pre: pre.SDQConductScore,
                            post: post.SDQConductScore,
                            followUp: followUp.SDQConductScore,
                        };
                        const hyperActivity = {
                            pre: pre.SDQHyperactivityScore,
                            post: post.SDQHyperactivityScore,
                            followUp: followUp.SDQHyperactivityScore,
                        };
                        const peerRelationships = {
                            pre: pre.SDQPeerRelationshipsScore,
                            post: post.SDQPeerRelationshipsScore,
                            followUp: followUp.SDQPeerRelationshipsScore,
                        };
                        const prosocial = {
                            pre: pre.SDQProsocialScore,
                            post: post.SDQProsocialScore,
                            followUp: followUp.SDQProsocialScore,
                        };

                        const group = response.data.roles.learner.parentRoles.class;
                        const participant = response.data.roles.learner;

                        const allNull =
                            Object.values(pre)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item) &&
                            Object.values(post)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item) &&
                            Object.values(followUp)
                                .filter(item => item !== 'MetricsDevTags')
                                .every(item => !item);

                        const dateObj = getDates(group);

                        if (!dateObj || allNull) {
                            setNoProgress(true);
                        }

                        setMetrics({
                            totalDifficulties,
                            emotional,
                            conduct,
                            hyperActivity,
                            peerRelationships,
                            prosocial,
                        });
                        setHeaderProps({
                            participant: participant.fields.participantId,
                            groupLabel: group.fields.groupLabel,
                            instructorList: group.parentRoles.instructorList,
                            dates: dateObj,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };
    const handleClick = chartType => {
        setCurrentChart(chartType);
    };

    const chartText = {
        totalDifficulties: {
            label: 'Total Difficulties',
            text: 'The 20 items from the emotional symptoms, conduct problems, hyperactivity-inattention, peer problems subscales are summed to produce a “Total Difficulties Score” ranging from 0-40 (low to high). Please see the tool tip to view scale/subscale cut-off scores.',
            tipImage: TotalTip,
            tipText:
                '"High” and “Very high” scores may suggest the student is struggling with high levels of psychological difficulties.',
        },
        emotional: {
            label: 'Emotional',
            text: 'The emotional symptoms subscale consists of 5 questions that may help determine if a child needs further depression assessment. Please see the tool tip to view and compare cut-off scores by reporter.',
            tipImage: EmoTip,
            tipText: `“High” and “Very High” scores may suggest the student is struggling with high levels of emotional problems.`,
        },
        conduct: {
            label: 'Conduct',
            text: 'The conduct problems subscale consists of 5 questions that may help determine if a child is showing behavior problems requiring further assessment. Please see the tool tip to view and compare cut-off scores by reporter.',
            tipImage: ConductTip,
            tipText:
                '“High” and “Very High” scores may suggest the student is struggling with high levels of conduct problems.',
        },
        hyperActivity: {
            label: 'Hyperactivity',
            text: 'The hyperactivity subscale consists of 5 questions that may help determine if a child displaying signs of hyperactivity and should see a doctor for proper diagnosis. Please see the tool tip to view and compare cut-off scores by reporter.',
            tipImage: HyperTip,
            tipText:
                '“High” and “Very High” scores may suggest the student is struggling with high levels of hyperactivity/impulsivity.',
        },
        peerRelationships: {
            label: 'Peer Problems',
            text: 'The peer problems subscale consists of 5 questions that may help determine if a child is struggling to establish positive peer relations and needs support. Please see the tool tip to view and compare cut-off scores by reporter.',
            tipImage: PeerTip,
            tipText:
                '“High” and “Very High” scores may suggest the student is struggling with high levels of peer-related problems.',
        },
        prosocial: {
            label: 'Prosocial',
            text: 'The prosocial behavior subscale consists of 5 questions that may help determine if a child is engaging in positive behaviors intended to benefit others. Please see the tool tip to view and compare cut-off scores by reporter.',
            tipImage: ProsocialTip,
            tipText:
                'Please note that Prosocial Behaviors are excluded from the Total Difficulties Score. The items comprising the prosocial score are reverse coded, therefore lower scores indicate higher levels of prosocial difficulties.',
        },
    };

    const renderBarChart = chart => {
        const colors = {
            'Total Difficulties': '#3266CC',
            Emotional: '#3266CC',
            Conduct: '#3266CC',
            Hyperactivity: '#3266CC',
            'Peer Problems': '#3266CC',
            Prosocial: '#3266CC',
        };

        const getColor = bar => colors[bar.id];

        const chartData = ['pre', 'post', 'followUp'].map((item, i) => {
            if (metrics[chart][item] || metrics[chart][item] === 0) {
                if (i === 0) {
                    return {
                        timepoint: 'Pre',
                        [chartText[chart].label]: +metrics[chart][item],
                    };
                }
                if (i === 1) {
                    return {
                        timepoint: 'Post',
                        [chartText[chart].label]: +metrics[chart][item],
                    };
                }
                if (i === 2) {
                    return {
                        timepoint: 'Follow Up',
                        [chartText[chart].label]: +metrics[chart][item],
                    };
                }
            }
            return null;
        });

        const getSettings = () => {
            const keys = {
                totalDifficulties: ['Total Difficulties'],
                emotional: ['Emotional'],
                conduct: ['Conduct'],
                hyperActivity: ['Hyperactivity'],
                peerRelationships: ['Peer Problems'],
                prosocial: ['Prosocial'],
            };

            if (chart === 'totalDifficulties') {
                return {
                    tickValues: [0, 5, 10, 15, 20, 25, 30, 35, 40],
                    keys: keys[chart],
                    maxValue: 40,
                };
            }
            return {
                tickValues: [0, 2, 4, 6, 8, 10],
                keys: keys[chart],
                maxValue: 10,
            };
        };

        const chartSettings = getSettings();

        return (
            <div style={{ width: '100%', height: '300px', paddingLeft: '4em', marginTop: '1em' }}>
                <ResponsiveBar
                    data={chartData}
                    keys={chartSettings.keys}
                    indexBy="timepoint"
                    colors={getColor}
                    maxValue={chartSettings.maxValue}
                    axisLeft={{
                        legend: 'Score',
                        legendPosition: 'middle',
                        legendOffset: -40,
                        tickValues: chartSettings.tickValues,
                    }}
                    labelFormat={d => <tspan y={-10}>{Math.round(+d * 100) / 100}</tspan>}
                    labelTextColor="#ffffff"
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        );
    };

    const renderChartButtons = () => {
        const buttons = [
            { label: 'Total Difficulties', chartType: 'totalDifficulties' },
            { label: 'Emotional', chartType: 'emotional' },
            { label: 'Conduct', chartType: 'conduct' },
            { label: 'Hyperactivity', chartType: 'hyperActivity' },
            { label: 'Peer Problems', chartType: 'peerRelationships' },
            { label: 'Prosocial', chartType: 'prosocial' },
        ];
        const buttonElements = (
            <>
                {buttons.map((item, i) => {
                    const isCurrentChart = item.chartType === currentChart;
                    const topRow = i < 3;
                    return (
                        <button
                            type="button"
                            className={isCurrentChart ? style.chartButtonActive : style.chartButton}
                            style={topRow ? { marginBottom: '20px' } : {}}
                            onClick={() => {
                                handleClick(item.chartType);
                            }}
                        >
                            {item.label}
                        </button>
                    );
                })}
            </>
        );
        return buttonElements;
    };

    const renderPrintCharts = chart => {
        return (
            <div className={style.additionalReportBox}>
                <div
                    style={{
                        fontSize: '14pt',
                        marginBottom: '0.5em',
                    }}
                >
                    {chartText[chart].label} Score
                </div>
                <p>{chartText[chart].text} </p>
                {renderBarChart(chart)}
            </div>
        );
    };
    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (noProgress) {
        return <NoGroupRole type="data" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div className={style.reportContainer}>
            <Report showPagination={showPrintMode}>
                <Page>
                    <BrowserOnly>
                        <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                    </BrowserOnly>
                    <div className={style.reportBox}>
                        <ReportHeader {...headerProps} />
                        <div className={style.reportBody}>
                            <div className={style.titleRow}>
                                <h1>Parent SDQ Report</h1>
                            </div>
                            <p>
                                {`The SDQ is a 25-item screener that assesses positive and negative attributes across five scales of five items each, generating scores for emotional symptoms, conduct problems, hyperactivity-inattention, peer problems, and prosocial behavior; all but the last are summed to generate a total difficulties score. The SDQ provides the most utility when comparisons are made across multiple reporters (teacher, parent, and student), as is shown here.`}
                            </p>
                            <div
                                style={{
                                    fontSize: '14pt',
                                    marginBottom: '0.5em',
                                    marginTop: '1em',
                                }}
                            >
                                {chartText[currentChart].label} Score
                            </div>
                            <p>
                                {chartText[currentChart].text}{' '}
                                <BrowserOnly>
                                    <Tooltip
                                        title={
                                            <div>
                                                <img
                                                    src={chartText[currentChart].tipImage}
                                                    alt="Chart"
                                                    style={{ width: '580px', marginBottom: '20px' }}
                                                />
                                                <p>
                                                    <i>{chartText[currentChart].tipText}</i>
                                                </p>
                                            </div>
                                        }
                                        type="popover1"
                                        position="top"
                                        maxWidth="600px"
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                </BrowserOnly>
                            </p>
                            {renderBarChart(currentChart)}
                            <BrowserOnly>
                                <div
                                    className={style.chartButtons}
                                    style={showPrintMode ? { visibility: 'hidden' } : {}}
                                >
                                    {renderChartButtons()}
                                </div>
                            </BrowserOnly>
                        </div>
                    </div>
                </Page>
                <div style={{ display: showPrintMode ? 'block' : 'none' }}>
                    <Page>
                        {['emotional', 'conduct']
                            .filter(item => item !== currentChart)
                            .map(chart => {
                                return <>{renderPrintCharts(chart)}</>;
                            })}
                    </Page>
                    <Page>
                        {['hyperActivity', 'peerRelationships']
                            .filter(item => item !== currentChart)
                            .map(chart => {
                                return <>{renderPrintCharts(chart)}</>;
                            })}
                    </Page>
                    <Page>
                        {['prosocial', 'totalDifficulties']
                            .filter(item => item !== currentChart)
                            .map(chart => {
                                return <>{renderPrintCharts(chart)}</>;
                            })}
                    </Page>
                </div>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    learner: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noLearners: PropTypes.bool,
    currentProduct: PropTypes.number,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
