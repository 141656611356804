import React from 'react';
import IconConstruction from 'cccisd-icons/construction';

function Dashboard() {
    return (
        <>
            <h1>Dashboards</h1>
            <div style={{ marginTop: '2rem' }} className="alert alert-info" role="alert">
                <IconConstruction spaceRight />
                Work in progress - coming soon.
                <IconConstruction spaceLeft />
            </div>
        </>
    );
}

export default Dashboard;
