import React from 'react';
import { Field, CccisdToggle } from 'cccisd-formik';
import PropTypes from 'prop-types';

const TRAININGS = {
    ael: 'Adventures in Emotional Literacy',
    csby: 'Celebrating the Strengths of Black Youth',
    lifestories_k2: 'LifeStories for Kids K-2',
    lifestories_35: 'LifeStories for Kids 3-5',
    ssgrin_ec: 'S.S.GRIN for Early Childhood',
    ssgrin_k5: 'S.S.GRIN K-2 or 3-5',
    ssgrin_a: 'S.S.GRIN for Adolescents',
    ssgrin_hfa: 'S.S.GRIN-HFA',
};

export default function CompletedTrainingsFields(props) {
    const labelText = props.labelText;
    return (
        <>
            <label>
                {labelText
                    ? labelText
                    : `Have you ever completed the trainings for these interventions?`}
            </label>
            {Object.entries(TRAININGS).map(t => (
                <Field key={t[0]} name={t[0]} label={t[1]} component={CccisdToggle} />
            ))}
        </>
    );
}

CompletedTrainingsFields.propTypes = {
    labelText: PropTypes.string,
};
