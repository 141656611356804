import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'cccisd-react-router';
import widgets from '../../common/widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';
import filterFields from '../../common/filterFields.js';
import style from '../../common/barReportStyle.css';
import query from './metrics.graphql';
import { getDates, getActualFields } from '../../common/helpers.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import ChartImage from './childALQ.png';
import InfoIcon from 'cccisd-icons/info2';
import Tooltip from 'cccisd-tooltip';
import SimpleBarChart from '../../common/components/SimpleBarChart';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const { productHandle } = useParams();

    useEffect(() => {
        if (noProgress === true) {
            setNoProgress(false);
        }

        (async () => {
            try {
                const queryHandle = productHandle
                    ? productHandle
                    : props.productIds.find(item => item.value === props.filters.product).handle;

                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.filters.participant,
                            productHandle: queryHandle,
                            productId: props.productId || props.filters.product,
                        },
                    })
                    .then(response => {
                        const post = response.data.roles.learner.postProgress.devTags.ALQChildScore;
                        const pre = response.data.roles.learner.preProgress.devTags.ALQChildScore;
                        const followUp =
                            response.data.roles.learner.followUpProgress.devTags.ALQChildScore;

                        const group = response.data.roles.learner.parentRoles.class;
                        const participant = response.data.roles.learner;

                        const allNull = post === null && pre === null && followUp === null;

                        const dateObj = getDates(group);

                        if (!dateObj || allNull) {
                            setNoProgress(true);
                        }

                        setMetrics({ pre, post, followUp });
                        setHeaderProps({
                            participant: participant.fields.participantId,
                            groupLabel: group.fields.groupLabel,
                            instructorList: group.parentRoles.instructorList,
                            dates: dateObj,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    const tipText =
        "To review the individual multiple choice questions and the participant's response to each, please revisit the completed ALQ in the Outcomes Data section. Use the scoring guide to determine which individual items the participant answered correctly and incorrectly, and where strengths and weakness may lie.";

    const renderBarChart = () => {
        const colors = { Overall: '#DC3913' };

        const markers = [
            {
                axis: 'y',
                value: 83,
                lineStyle: {
                    stroke: '#3266CC',
                },
                textStyle: {
                    fill: '#3266CC',
                },
            },
        ];
        const formula = score => {
            return score * 100;
        };

        return (
            <SimpleBarChart
                metrics={metrics}
                colors={colors}
                tickValues={[0, 20, 40, 60, 80, 100]}
                markers={markers}
                maxValue={100}
                formula={formula}
            />
        );
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (noProgress) {
        return <NoGroupRole type="data" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={style.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={style.reportBody}>
                        <div className={style.titleRow}>
                            <h1>ALQ Child Report</h1>{' '}
                            <Tooltip
                                title={
                                    <div>
                                        <img
                                            src={ChartImage}
                                            alt="Chart"
                                            style={{ width: '580px', marginBottom: '20px' }}
                                        />
                                        <p>
                                            <i>{tipText}</i>
                                        </p>
                                    </div>
                                }
                                type="popover1"
                                position="top"
                                maxWidth="600px"
                            >
                                <InfoIcon />
                            </Tooltip>
                        </div>
                        <p>
                            {`The Achieved Learning Questionnaire (ALQ) is a 30-item child-reported multiple-choice measure used to assess a child's understanding of behaviors, social skills, and concepts taught in S.S.GRIN. Children are asked to answer a set of questions assessing their knowledge of a number of social skills (emotion regulation, communication, impulse control, etc.)`}
                        </p>
                        <p>
                            The overall score is based on the number of correct responses the
                            participant selected, with higher scores indicating greater social
                            literacy (e.g., 25 answers out of 30 correct scores an 83%).
                        </p>
                        {renderBarChart()}
                    </div>
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    learner: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noLearners: PropTypes.bool,
    currentProduct: PropTypes.number,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
