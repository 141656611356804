import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import IconInfo from 'cccisd-icons/info2';
import style from './style.css';

export default class InfoLabel extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        tooltip: PropTypes.string,
        placement: PropTypes.string,
        optional: PropTypes.bool,
    };

    render() {
        const { label, tooltip, placement, optional } = this.props;
        return (
            <span>
                <div className={style.label}>{label}</div>{' '}
                {tooltip && (
                    <Tooltip title={tooltip} placement={placement || 'top'}>
                        <span className="text-primary">
                            <IconInfo />
                        </span>
                    </Tooltip>
                )}
                {optional && <div className={style.optional}>- Optional</div>}
            </span>
        );
    }
}
