import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import Loader from 'cccisd-loader';
import Status from './index.js';
import { loadProject, loadProjectList, setCurrentAssignmentId, setCurrentDeploymentId } from '../../reducers/admin.js';

const ExportStatus = props => {
    const [loading, setLoading] = useState(true);

    async function customSwitchProject(projectId) {
        const { history, match } = props;
        await props.loadProject(Number.parseInt(projectId, 10));
        if (_get(match, 'params.projectId')) {
            history.push(match.path.replace(':projectId?', projectId));
        }
    }

    useEffect(() => {
        async function loadData() {
            const { hideProjectDropdown, match } = props;
            let projectId = props.projectId;
            if (!hideProjectDropdown && match.params.projectId) {
                projectId = match.params.projectId;
            }
            await props.loadProjectList();
            await customSwitchProject(projectId);
            if (props.assignmentId) {
                await props.setCurrentAssignmentId(props.assignmentId);
            }
            if (props.deploymentId) {
                await props.setCurrentDeploymentId(props.deploymentId);
            }
            setLoading(false);
        }
        loadData();
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    return (
        <Status
            setColumnDefinitions={props.setColumnDefinitions}
            renderCertificate={props.renderCertificate}
            assignmentIdWhitelist={props.assignmentIdWhitelist}
            customSwitchProject={customSwitchProject}
            deploymentIdWhitelist={props.deploymentIdWhitelist}
            hideDeploymentDropdown={props.hideDeploymentDropdown}
            hideProjectDropdown={props.hideProjectDropdown}
            hideQuestDropdown={props.hideQuestDropdown}
            hideSystemId={props.hideSystemId}
            isExported
            labelForDeployment={props.labelForDeployment}
            labelForProject={props.labelForProject}
            labelForProjectPlural={props.labelForProjectPlural}
            labelForQuest={props.labelForQuest}
            labelForSurvey={props.labelForSurvey}
            pawnIdWhitelist={props.pawnIdWhitelist}
            projectIdWhitelist={props.projectIdWhitelist}
            showByWhitelist={props.showByWhitelist}
            surveyHandleWhitelist={props.surveyHandleWhitelist}
        />
    );
};

ExportStatus.propTypes = {
    assignmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    assignmentIdWhitelist: PropTypes.array,
    deploymentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deploymentIdWhitelist: PropTypes.array,
    hideDeploymentDropdown: PropTypes.bool,
    hideProjectDropdown: PropTypes.bool,
    hideQuestDropdown: PropTypes.bool,
    hideSystemId: PropTypes.bool,
    labelForDeployment: PropTypes.string,
    labelForProject: PropTypes.string,
    labelForProjectPlural: PropTypes.string,
    labelForQuest: PropTypes.string,
    labelForSurvey: PropTypes.string,
    pawnIdWhitelist: PropTypes.arrayOf(PropTypes.number),
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    projectIdWhitelist: PropTypes.array,
    showByWhitelist: PropTypes.arrayOf(PropTypes.string), // 'survey', 'module', or 'assignment'
    surveyHandleWhitelist: PropTypes.arrayOf(PropTypes.string),

    // From Redux
    loadProject: PropTypes.func,
    loadProjectList: PropTypes.func,
    setCurrentAssignmentId: PropTypes.func,
    setCurrentDeploymentId: PropTypes.func,

    // From React Router
    match: PropTypes.object,

    // Certificate Render
    renderCertificate: PropTypes.func,

    // Custom Columns
    setColumnDefinitions: PropTypes.func,
};

export default withRouter(
    connect(null, {
        loadProject,
        loadProjectList,
        setCurrentAssignmentId,
        setCurrentDeploymentId,
    })(ExportStatus)
);
