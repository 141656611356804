import { Field } from 'cccisd-formik';
import React from 'react';
import GroupSelect from '../common/filters/GroupSelect';
import ParticipantSelect from '../common/filters/ParticipantSelect';

export default [
    {
        name: 'group',
        label: 'Select Class',
        component: props => {
            return (
                <Field name="group">
                    {({ field, form }) => <GroupSelect {...props} field={field} form={form} />}
                </Field>
            );
        },
    },
    {
        name: 'participant',
        label: 'Select Participant',
        component: props => {
            return (
                <Field name="participant">
                    {({ field, form }) => (
                        <ParticipantSelect {...props} field={field} form={form} />
                    )}
                </Field>
            );
        },
    },
];
