import React from 'react';

const ScoreRender = props => {
    const { row, productHandle } = props;

    const noTraining = row['fields.trained'];
    const training = JSON.parse(row['fields.trained']);

    const currentSurvey = productHandle;

    const productList = row.ownedLicenseList
        .filter(item => item.licenseId && !item.isExpired)
        .map(item => item.product.handle);

    const ownsProduct = productList.includes(currentSurvey);

    const isAltComplete = handle => {
        const alt = row.assignmentProgressList.find(item => item.deployment.deploymentHandle === handle);

        if (alt && alt.completed) {
            return true;
        }
        return false;
    };

    const hasAlternateSurvey = () => {
        if (productHandle === 'ssgrin_35') {
            return 'ssgrin_k2_training';
        }
        if (productHandle === 'ssgrin_k2') {
            return 'ssgrin_35_training';
        }
        return null;
    };

    const altCompleted = isAltComplete(hasAlternateSurvey());
    const trained = training && training[currentSurvey];

    if (ownsProduct && altCompleted) {
        const alternate = hasAlternateSurvey();
        let message;
        switch (alternate) {
            case 'ssgrin_35_training':
                message = 'Completed 3-5';
                break;
            case 'ssgrin_k2_training':
                message = 'Completed K-2';
                break;
            default:
                message = 'Training Completed';
            // code block
        }

        return <div>{message}</div>;
    }
    const assignmentHandle = row['assignmentProgress.assignmentHandle'];
    const testScore = row[`assignmentProgress.variables.${assignmentHandle}_test_score`];

    if (noTraining && !altCompleted && testScore > 0) {
        // let key = `assignmentProgressStatus.assignment${assignment.assignmentId}_lastVisit`;

        return <div>{testScore}</div>;
    }

    if (ownsProduct && trained) {
        return <div>Training Toggled</div>;
    }

    return null;
};

export default ScoreRender;
