import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'cccisd-react-router';
import widgets from '../../common/widgets/widgets.js';
import ReportHeader from '../../common/components/ReportHeader';
import NoGroupRole from '../../common/components/NoGroupRole';
import PrintView from '../../common/components/PrintView';
import filterFields from '../../common/filterFields.js';
import style from './style.css';
import query from './metrics.graphql';
import { getDates, getActualFields } from '../../common/helpers.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

const questions = [
    {
        text: 'Absences from school',
        devTag: 'SSBICoA1',
    },
    { text: 'Physical altercations', devTag: 'SSBICoA2' },
    { text: 'Verbal altercations', devTag: 'SSBICoA3' },
    { text: 'Disciplinary office referrals', devTag: 'SSBICoA4' },
    { text: 'In-school suspensions', devTag: 'SSBICoA5' },
    { text: 'Out of school suspensions', devTag: 'SSBICoA6' },
];

const commentTag = 'SSBICoAComments';

const Component = props => {
    const [metrics, setMetrics] = useState(null);
    const [headerProps, setHeaderProps] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noProgress, setNoProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);
    const { productHandle } = useParams();
    useEffect(() => {
        // run metrics query
        if (noProgress === true) {
            setNoProgress(false);
        }

        (async () => {
            const queryHandle = productHandle
                ? productHandle
                : props.productIds.find(item => item.value === props.filters.product).handle;
            try {
                await client
                    .query({
                        query,
                        fetchPolicy: 'network-only',
                        variables: {
                            pawnId: +props.filters.participant,
                            productHandle: queryHandle,
                            productId: props.productId || props.filters.product,
                        },
                    })
                    .then(response => {
                        const group = response.data.roles.learner.parentRoles.class;
                        const participant = response.data.roles.learner;

                        const post = response.data.roles.learner.postProgress.devTags;

                        const pre = response.data.roles.learner.preProgress.devTags;

                        const followUp = response.data.roles.learner.followUpProgress.devTags;

                        delete pre.__typename;
                        delete post.__typename;
                        delete followUp.__typename;

                        const preNull = Object.values(pre).every(item => item === null);
                        const postNull = Object.values(post).every(item => item === null);
                        const followUpNull = Object.values(followUp).every(item => item === null);

                        const dateObj = getDates(group);

                        if (!dateObj || (preNull && postNull && followUpNull)) {
                            setNoProgress(true);
                        }

                        setMetrics({ pre, post, followUp });
                        setHeaderProps({
                            participant: participant.fields.participantId,
                            groupLabel: group.fields.groupLabel,
                            instructorList: group.parentRoles.instructorList,
                            dates: dateObj,
                        });
                        setLoading(false);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, [props.filters]);

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    const renderTableRows = () => {
        return (
            <>
                {questions.map((item, i) => {
                    const text = item.text;
                    const preValue = metrics.pre[item.devTag];
                    const postValue = metrics.post[item.devTag];
                    const followUpValue = metrics.followUp[item.devTag];
                    return (
                        <tr key={i}>
                            <td>{text}</td>
                            <td>{preValue}</td>
                            <td>{postValue}</td>
                            <td>{followUpValue}</td>
                        </tr>
                    );
                })}
            </>
        );
    };

    const renderCommentBox = () => {
        const comments = [metrics.pre[commentTag], metrics.post[commentTag], metrics.followUp[commentTag]].filter(
            Boolean
        );

        if (comments.length > 0) {
            return (
                <>
                    <h1 style={{ marginTop: '1em' }}>Comments</h1>
                    {comments.map(comment => {
                        return <div className={style.commentBox}>{comment}</div>;
                    })}
                </>
            );
        }
    };

    if (props.noGroups) {
        return <NoGroupRole type="group" />;
    }

    if (props.noLearners) {
        return <NoGroupRole type="learner" />;
    }

    if (noProgress) {
        return <NoGroupRole type="data" />;
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <Report showPagination={showPrintMode}>
            <Page>
                <BrowserOnly>
                    <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                </BrowserOnly>
                <div className={style.reportBox}>
                    <ReportHeader {...headerProps} />
                    <div className={style.reportBody}>
                        <h1>SSBI: Conduct and Absenteeism Report</h1>
                        <p>
                            {`The Conduct and Absenteeism Report identifies the frequency of teacher-reported conduct problems (e.g., absenteeism, discipline referrals, suspensions) the participant experienced in the past month. Scores range from 0-18, with higher scores indicating a higher number of disciplinary actions and absences in the past month.`}
                        </p>

                        <table className={style.reportTable}>
                            <thead>
                                <tr>
                                    <th rowSpan="2">Checklist Measure Items</th>
                                    <th colSpan="10" style={{ textAlign: 'center' }}>
                                        Checklist Item Responses
                                    </th>
                                </tr>
                                <tr>
                                    <th className={style.answerHead}>Pre</th>
                                    <th className={style.answerHead}>Post</th>
                                    <th className={style.answerHead}>Follow Up</th>
                                </tr>
                                <tr className={style.numHeadRow} />
                            </thead>
                            <tbody>{renderTableRows()}</tbody>
                        </table>
                        {renderCommentBox()}
                    </div>
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    // redux
    loading: PropTypes.bool,
    learner: PropTypes.number,
    group: PropTypes.number,
    noGroups: PropTypes.bool,
    noLearners: PropTypes.bool,
    currentProduct: PropTypes.number,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        return getActualFields(filterFields, props);
    },
})(Component);
