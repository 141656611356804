import React from 'react';
import StatsIcon from 'cccisd-icons/stats-bars2';
// gets the score from the last flow response taken
const Boilerplate = window.cccisd.boilerplate;

const ViewReports = () => {
    const params = window.location.pathname.split('/');
    return ({ row, settings }) => {
        return (
            <div className="text-center">
                <a
                    className="btn btn-primary"
                    href={Boilerplate.url(`${params[1]}/${params[2]}/reportCenter`)}
                >
                    <StatsIcon spaceRight /> View Reports
                </a>
            </div>
        );
    };
};
export default ViewReports;
